import { matchPath } from 'react-router';

const NO_OP = a => a;

class Route {
	constructor(route, parent = null, name, modifyParams = NO_OP) {
		if(typeof route === 'string') {
			this.route = { path: route };
		} else {
			this.route = route;
		}
		this.parent = parent;
		if(typeof name !== 'function') {
			this.name = () => name;
		} else {
			this.name = name;
		}
		this.modifyParams = modifyParams;
	}

	matches(path) {
		return matchPath(path, this.route);
	}

	getBreadCrumb(params) {
		let { path } = this.route;
		if(params) {
			for(const key in params) {
				path = path.replace(`:${key}`, params[key]);
			}
		}
		return {
			path,
			text: this.name(params)
		};
	}

	getBreadCrumbs(path) {
		const match = matchPath(path, this.route);
		const crumbs = [];
		let params = match && match.params;
		let current = this;
		do {
			crumbs.unshift(current.getBreadCrumb(params));
			params = current.modifyParams(params);
			current = current.parent;
		} while(current);
		return crumbs;
	}
}

function mapPartnerId(params) {
	params.id = params.partnerId;
	return params;
}

export const HOME = new Route({ path: '/', exact: true }, null, 'Home');
export const COUNTS = new Route({ path: '/(counts)?', exact: true }, HOME, 'Tellingen');
export const PARTNERS = new Route('/partners', HOME, 'Partners');
export const PARTNER_DATASETS = new Route('/partners/:id/datasets', PARTNERS, 'Bestanden');
export const DATASET_EDIT = new Route('/partners/:id/datasets/:datasetId/edit', PARTNER_DATASETS, 'Bewerk');
export const DATASET_RANGED_VALUES = new Route('/partners/:id/datasets/:datasetId/:type', DATASET_EDIT, params => {
	if(params) {
		if(params.type === 'commission') {
			return 'Commissies';
		} else if(params.type === 'participation') {
			return 'Extra inzet';
		}
	}
	return 'Waarden';
});
export const DATASET_NEW_RANGED_VALUE = new Route('/partners/:id/datasets/:datasetId/:type/new', DATASET_EDIT, params => {
	if(params) {
		if(params.type === 'commission') {
			return 'Nieuwe commissie';
		} else if(params.type === 'participation') {
			return 'Nieuwe inzet';
		}
	}
});
export const DATASET_EDIT_RANGED_VALUE = new Route('/partners/:id/datasets/:datasetId/:type/:extraId/edit', DATASET_RANGED_VALUES, 'Bewerk');
export const DATASET_EDIT_MAPPINGS = new Route('/partners/:partnerId/datasets/:datasetId/headers', PARTNER_DATASETS, 'Map', mapPartnerId);
export const DATASET_VALUE_MAPPINGS = new Route('/partners/:partnerId/datasets/:datasetId/:mapping/mappings', DATASET_EDIT_MAPPINGS, 'Kenmerken');
export const DATASET_NEW = new Route('/partners/:id/datasets/new', PARTNER_DATASETS, 'Nieuw');
export const PARTNER_BLACKLIST = new Route('/partners/:partnerId/blacklist', PARTNERS, 'Blacklist');
export const PARTNER_CONTACTS = new Route('/partners/:id/contacts', PARTNERS, 'Contacten');
export const PARTNER_NEW_CONTACT = new Route('/partners/:id/contacts/new', PARTNER_CONTACTS, 'Nieuw');
export const PARTNER_EDIT_CONTACT = new Route('/partners/:id/contacts/:contactId/edit', PARTNER_CONTACTS, 'Bewerk');
export const DATASET_TEST_PERSONS = new Route('/partners/:id/datasets/:datasetId/testpersons', DATASET_EDIT, 'Testpersonen');
export const DATASET_NEW_TEST_PERSON = new Route('/partners/:id/datasets/:datasetId/testpersons/new', DATASET_TEST_PERSONS, 'Nieuw');
export const DATASET_EDIT_TEST_PERSON = new Route('/partners/:id/datasets/:datasetId/testpersons/:personId/edit', DATASET_TEST_PERSONS, 'Bewerk');
export const PARTNER_NEW = new Route('/partners/new', PARTNERS, 'Nieuw');
export const PARTNER_EDIT = new Route('/partners/:id/edit', PARTNERS, 'Bewerk');
export const CAMPAIGNS = new Route('/campaigns', HOME, 'Campagnes');
export const CAMPAIGNS_NEW = new Route('/campaigns/new', CAMPAIGNS, 'Nieuw');
export const CAMPAIGNS_EDIT = new Route('/campaigns/:id/edit', CAMPAIGNS, 'Bewerk');
export const CAMPAIGNS_MAILINGS = new Route('/campaigns/:campaignId/mailings', CAMPAIGNS, 'Verzendingen');
export const CAMPAIGNS_MAILING = new Route('/campaigns/:campaignId/mailings/:mailingId?', CAMPAIGNS, 'Verzendingen');
export const CAMPAIGNS_MAILING_SELECTIONS = new Route('/campaigns/:campaignId/mailings/:mailingId/selections', CAMPAIGNS_MAILINGS, 'Selecties');
export const CAMPAIGNS_NEW_MAILING = new Route('/campaigns/:campaignId/mailings/new', CAMPAIGNS, 'Nieuw');
export const CAMPAIGNS_MAILING_EDIT = new Route('/campaigns/:campaignId/mailings/:mailingId/edit', CAMPAIGNS_MAILINGS, 'Bewerk');
export const CAMPAIGNS_MAILING_COPY = new Route('/campaigns/:campaignId/mailings/:mailingId/copy', CAMPAIGNS_MAILINGS, 'Kopieër');
export const UPLOAD = new Route('/upload/:partnerId?/:datasetId?', HOME, 'Upload');
export const TYPES = new Route('/types', HOME, 'Labels');
export const TYPES_NEW = new Route('/types/new', TYPES, 'Nieuw');
export const QUERY_BUILDER_BASE = new Route('/selection-builder', HOME, 'Doelgroep Selecteren');
export const QUERY_BUILDER = new Route('/selection-builder/:campaign?/:mailing?/:selection?', HOME, 'Doelgroep Selecteren');
export const QUERY_BUILDER_LINES = new Route('/selection-builder/add-line', QUERY_BUILDER_BASE, 'Regel Toevoegen');
export const QUERY_BUILDER_LINE = new Route('/selection-builder/add-line/:type?', QUERY_BUILDER_LINES, params => {
	if(params) {
		switch(params.type) {
			case 'attribute':
			case 'in':
			case 'dateRange':
			case 'match':
			case 'range':
				return 'Kenmerk';
			case 'dataset':
				return 'Label';
			case 'interests':
				return 'Interesse';
			case 'contains':
				return 'Extern kenmerk';
			case 'intersect':
				return 'Bestandsmatch';
			case 'complement':
				return 'Bestand uitsluiten';
			default:
				return undefined;
		}
	}
});
export const BLACKLIST = new Route('/blacklist', HOME, 'Blacklist');
export const BLACKLIST_UPLOAD = new Route('/blacklist/upload', BLACKLIST, 'Upload');
export const DOMAIN_BLACKLIST = new Route('/blacklist/domain', HOME, 'Domein blacklist');
export const USERS = new Route('/users', HOME, 'Gebruikers');
export const USERS_EDIT = new Route('/users/edit/:userId', USERS, 'Bewerk');
export const USERS_NEW = new Route('/users/new', USERS, 'Nieuw');
export const INTERESTS = new Route('/interest-types', HOME, 'Interesse types');
export const INTERESTS_NEW = new Route('/interest-types/new', INTERESTS, 'Nieuw');
export const TASKS = new Route('/tasks', HOME, 'Taken');
export const CSV_COLUMNS = new Route('/csv-columns', HOME, 'Export kolommen');
export const CSV_COLUMNS_NEW = new Route('/csv-columns/new', CSV_COLUMNS, 'Nieuw');
export const SETTINGS = new Route('/settings', HOME, 'Instellingen');
export const SETTINGS_TEST_PERSON = new Route('/settings/testperson', SETTINGS, 'Testpersoon');
export const PRESSURE = new Route('/pressure', HOME, 'Maildruk');
export const ENRICHMENT = new Route('/enrichment', HOME, 'Verrijkingsbestanden');
export const ENRICHMENT_UPLOAD = new Route('/enrichment/upload', ENRICHMENT, 'Upload');
export const VALUE_MAPPED = new Route('/valuemapped', HOME, 'Value mappings');
export const VALUE_MAPPED_FIELD = new Route('/valuemapped/:field', VALUE_MAPPED, params => params.field);
export const DELETE_CSV = new Route('/deleteall/:partnerId?/:datasetId?', HOME, 'Verwijder uit bestand');
export const DNS_CHECK = new Route('/dns-check', HOME, 'DNS Check');

const routes = [
	DNS_CHECK,
	DELETE_CSV,
	VALUE_MAPPED_FIELD,
	VALUE_MAPPED,
	ENRICHMENT_UPLOAD,
	ENRICHMENT,
	DATASET_EDIT_TEST_PERSON,
	DATASET_NEW_TEST_PERSON,
	DATASET_TEST_PERSONS,
	DATASET_VALUE_MAPPINGS,
	DATASET_EDIT_MAPPINGS,
	DATASET_EDIT_RANGED_VALUE,
	DATASET_EDIT,
	DATASET_NEW_RANGED_VALUE,
	DATASET_RANGED_VALUES,
	DATASET_NEW,
	PARTNER_DATASETS,
	PARTNER_BLACKLIST,
	PARTNER_EDIT_CONTACT,
	PARTNER_NEW_CONTACT,
	PARTNER_CONTACTS,
	PARTNER_EDIT,
	PARTNER_NEW,
	PARTNERS,
	CAMPAIGNS_MAILING_COPY,
	CAMPAIGNS_MAILING_EDIT,
	CAMPAIGNS_NEW_MAILING,
	CAMPAIGNS_MAILING_SELECTIONS,
	CAMPAIGNS_MAILINGS,
	CAMPAIGNS_EDIT,
	CAMPAIGNS_NEW,
	CAMPAIGNS,
	UPLOAD,
	TYPES_NEW,
	TYPES,
	QUERY_BUILDER_LINE,
	QUERY_BUILDER_LINES,
	QUERY_BUILDER,
	BLACKLIST_UPLOAD,
	DOMAIN_BLACKLIST,
	BLACKLIST,
	USERS_EDIT,
	USERS_NEW,
	USERS,
	INTERESTS_NEW,
	INTERESTS,
	TASKS,
	CSV_COLUMNS_NEW,
	CSV_COLUMNS,
	SETTINGS_TEST_PERSON,
	SETTINGS,
	COUNTS,
	HOME
];

export function getBreadCrumbs(path) {
	const route = routes.find(r => r.matches(path)) || HOME;
	return route.getBreadCrumbs(path);
}
