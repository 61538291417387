import React from 'react';
import {Button, Form, FormGroup, Input, Label, FormText} from 'reactstrap';
import { withFormUtils } from '../../hoc/with-form-utils';
import TwoFaForm from './TwoFaForm';

function UsersForm({getdata, onInputChange, onFormSubmit, reload, twoFA=false}) {
	return <>
		<Form onSubmit={onFormSubmit}>
			<FormGroup>
				<Label>Naam</Label>
				<Input type="text" name="name" value={getdata('name')} onChange={onInputChange} />
			</FormGroup>

			<FormGroup>
				<Label>Gebruikersnaam</Label>
				<Input type="text" name="username" value={getdata('username')} onChange={onInputChange} />
			</FormGroup>

			<FormGroup>
				<Label>Wachtwoord</Label>
				<Input type="password" name="password" value={getdata('password')} onChange={onInputChange} />
				<FormText>Wachtwoord moet minimaal 8 tekens lang zijn, waarvan 1 hoofdletter en 1 cijfer</FormText>
			</FormGroup>

			<FormGroup>
				<Button type="submit" color="primary">Opslaan</Button>
			</FormGroup>
		</Form>

		{twoFA && <TwoFaForm enabled={getdata('2fa')} username={getdata('username')} reload={reload}/>}
	</>;
}

export default withFormUtils(UsersForm);
