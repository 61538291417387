import React from 'react';
import {connect} from 'react-redux';
import {Switch, Route} from 'react-router-dom';

import * as api from '../api';

import NewPartner from '../components/crud-partner/NewPartner';
import UpdatePartner from '../components/crud-partner/UpdatePartner';
import ListPartners from '../components/crud-partner/ListPartners';
import ListPartnerContacts from '../components/crud-partner/ListPartnerContacts';
import NewPartnerContact from '../components/crud-partner/NewPartnerContact';
import UpdatePartnerContact from '../components/crud-partner/UpdatePartnerContact';
import ListPartnerDataset from '../components/crud-partner/ListPartnerDataset';
import NewPartnerDataset from '../components/crud-partner/NewPartnerDataset';
import UpdatePartnerDataset from '../components/crud-partner/UpdatePartnerDataset';
import DatasetRangedValueContainer from './DatasetRangedValueContainer';
import ListPartnerTestPersons from '../components/crud-partner/ListPartnerTestPersons';
import NewPartnerTestPerson from '../components/crud-partner/NewPartnerTestPerson';
import UpdatePartnerTestPerson from '../components/crud-partner/UpdatePartnerTestPerson';

class PartnerCRUDContainer extends React.Component {
	createNewPartner = partner => {
		if (!partner.id && partner.id !== 0) {
			return Promise.reject(new Error('Partner id must be present'));
		}

		return api.createNewPartner(partner);
	};

	updatePartner = (id, partner) => {
		if (!partner.id && partner.id !== 0) {
			return Promise.reject(new Error('Partner id must be present'));
		} else if (partner.id.toString() !== id.toString()) {
			return Promise.reject(new Error('Partner id may not be changed'));
		}

		return api.updatePartner(id, partner);
	};

	render() {
		return <Switch>
			<Route path="/partners/new" exact render={() => {
				return <NewPartner
					onSubmit={this.createNewPartner}
				/>;
			}} />

			<Route path="/partners/:id/edit" exact render={props => {
				return <UpdatePartner
					partnerId={props.match.params.id}
					fetchPartner={api.fetchPartner}
					onSubmit={this.updatePartner}
				/>;
			}} />

			<Route path="/partners" exact render={() => {
				return <ListPartners
					fetchPartners={api.fetchPartners}
					onDelete={api.deletePartner}
				/>;
			}} />

			<Route path="/partners/:id/contacts" exact render={props => {
				return <ListPartnerContacts
					partnerId={props.match.params.id}
					fetchPartner={api.fetchPartner}
					onDelete={api.deletePartnerContact}
				/>;
			}} />

			<Route path="/partners/:id/contacts/new" exact render={props => {
				return <NewPartnerContact
					onSubmit={api.createNewPartnerContact}
					partnerId={props.match.params.id}
				/>;
			}} />

			<Route path="/partners/:id/contacts/:contactId/edit" exact render={props => {
				return <UpdatePartnerContact
					partnerId={props.match.params.id}
					contactId={props.match.params.contactId}
					fetchPartnerContact={api.fetchPartnerContact}
					onSubmit={api.updatePartnerContact}
				/>;
			}} />

			<Route path="/partners/:id/datasets" exact render={props => {
				return <ListPartnerDataset
					fetchPartner={api.fetchPartner}
					fetchDatasets={api.fetchDatasets}
					partnerId={props.match.params.id}
					deleteDataset={api.deleteDataset}
				/>;
			}} />

			<Route path="/partners/:id/datasets/new" exact render={props => {
				return <NewPartnerDataset
					partnerId={props.match.params.id}
					onSubmit={api.createDataset}
					fetchTypes={api.fetchTypes}
				/>;
			}} />

			<Route path="/partners/:id/datasets/:datasetId/edit" exact render={props => {
				return <UpdatePartnerDataset
					partnerId={props.match.params.id}
					datasetId={props.match.params.datasetId}
					onSubmit={api.updateDataset}
					fetchDataset={api.fetchDataset}
					fetchTypes={api.fetchTypes}
				/>;
			}} />

			<Route path="/partners/:id/datasets/:datasetId/testpersons" exact render={props => {
				return <ListPartnerTestPersons
					partnerId={props.match.params.id}
					datasetId={props.match.params.datasetId}
					fetchDataset={api.fetchDataset}
					onDelete={api.deleteTestPerson}
				/>;
			}} />

			<Route path="/partners/:id/datasets/:datasetId/testpersons/new" exact render={props => {
				return <NewPartnerTestPerson
					onSubmit={api.createTestPerson}
					partnerId={props.match.params.id}
					datasetId={props.match.params.datasetId}
				/>;
			}} />

			<Route path="/partners/:id/datasets/:datasetId/testpersons/:personId/edit" exact render={props => {
				return <UpdatePartnerTestPerson
					partnerId={props.match.params.id}
					personId={props.match.params.personId}
					datasetId={props.match.params.datasetId}
					fetchPartnerTestPerson={api.getTestPerson}
					onSubmit={api.updateTestPerson}
				/>;
			}} />

			<Route path="/partners/:id/datasets/:datasetId/:type" component={DatasetRangedValueContainer} />
		</Switch>;
	}
}

const mapStateToProps = state => {
	const {filter} = state;

	return {
		filter,
	};
};

export default connect(mapStateToProps)(PartnerCRUDContainer);
