import * as Yup from 'yup';

const minPasswordLength = 8;

const passwordRules = Yup
	.string()
	.required('Het wachtwoord is een verplicht veld')
	.min(minPasswordLength, 'Het wachtwoord moet minimaal 8 tekens bevatten')
	.matches(/[A-Z]{1,}/, 'Het wachtwoord moet minimaal 1 hoofdletter bevatten')
	.matches(/\d{1,}/, 'Het wachtwoord moet minimaal 1 cijfer bevatten');

export const validatePasswordIfUsername = Yup.object().shape({
	username: Yup.string().default(''),
	password: Yup.string().when('username', {
		is: val => val.length > 0,
		then: passwordRules,
	}),
});

export const validateUsername = Yup.object().shape({
	username: Yup.string().required('De gebruikersnaam is een verplicht veld'),
});

export const validatePassword = Yup.object().shape({
	password: passwordRules,
});

export const validateCredentials = Yup.object().shape({
	password: passwordRules,
	username: Yup.string().required('De gebruikersnaam is een verplicht veld'),
});
