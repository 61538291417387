import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import DataView from './DataView';
import Paginator from './Paginator';
import {setPaginatorPage} from '../redux/actions/paginator';

const DEFAULT_PAGE = 1;

class PaginatorContainer extends React.Component {
	static propTypes = {
		name: PropTypes.string,
		items: PropTypes.array.isRequired,
		renderItem: PropTypes.func.isRequired,
		maxDisplayItems: PropTypes.number,
		seed: PropTypes.any, // When a paginator needs to be interactive, you can use the seed to apply changes
		maxPaginatorButtons: PropTypes.number, // This must be an odd number, will add 1 if it's even
		centered: PropTypes.bool,
	};

	static defaultProps = {
		name: `paginator_${Date.now().toString()}_${Math.random().toString()}`,
		maxDisplayItems: 10,
		seed: null,
		maxPaginatorButtons: 5,
		centered: false,
	};

	onPaginatorPageClick = (e, n) => {
		e.preventDefault();

		this.props.dispatch(setPaginatorPage(this.props.name, n));

		return false;
	};

	getItems = (currentPage) => {
		const {items, maxDisplayItems} = this.props;

		const start = (currentPage - 1) * maxDisplayItems;
		const end = currentPage * maxDisplayItems;

		return items.slice(start, end);
	};

	getMaxPages = () => {
		const {items, maxDisplayItems} = this.props;

		return Math.ceil(items.length / maxDisplayItems);
	};

	getCurrentPage = () => {
		try {
			const currentPage = this.props.paginator.pages[this.props.name];

			if (typeof currentPage === 'number') {
				const maxPages = this.getMaxPages();

				return Math.min(currentPage, maxPages);
			}

			return DEFAULT_PAGE;
		} catch (e) {
			return DEFAULT_PAGE;
		}
	}

	// eslint-disable-next-line no-magic-numbers
	getMaxPaginatorButtons = () => this.props.maxPaginatorButtons + Number(this.props.maxPaginatorButtons % 2 === 0);

	render() {
		const currentPage = this.getCurrentPage();
		const items = this.getItems(currentPage);
		const {maxDisplayItems} = this.props;
		const itemCount = this.props.items.length;

		return <React.Fragment>
			<DataView items={items} renderItem={this.props.renderItem} />
			<Paginator itemCount={itemCount} maxDisplayItems={maxDisplayItems} currentPage={currentPage} onPaginatorPageClick={this.onPaginatorPageClick} maxPaginatorButtons={this.getMaxPaginatorButtons()} centered={this.props.centered} />
		</React.Fragment>;
	}
}

const mapStateToProps = store => {
	const {paginator} = store;
	return {
		paginator,
	};
};

export default connect(mapStateToProps)(PaginatorContainer);
