import React, { Fragment } from 'react';
import moment from 'moment';
import {fetchPartners, fetchDatasets, deleteFromDataset, pollTask} from '../api';

import UploadFileForm from '../components/UploadFileForm';
import Message from '../messages/Message';
import { getResult } from '../util/Poller';

const pollTime = 2000;

export default class CSVDeleteContainer extends React.Component {
	state = {
		lastMessage: null,
		partners: [],
		datasets: null,
	};

	componentDidMount() {
		fetchPartners().then(partners => {
			this.setState({ partners });
		}).catch(err => {
			this.setState({
				lastMessage: new Message('Unable to fetch partners', err.message, {
					color: 'danger',
				}),
			});
		});
	}

	fetchDatasets = partnerId => {
		fetchDatasets(partnerId).then(datasets => {
			this.setState({
				datasets,
			});
		}).catch(err => {
			this.setState({
				lastMessage: new Message('Unable to fetch datasets', err.message, {
					color: 'danger',
				}),
			});
		});
	};

	uploadFile = async (partnerId, datasetName, file) => {
		try {
			this.setState({
				lastMessage: new Message('Aan het uploaden', `Bestand ${file.name} wordt geüpload, een moment geduld`, {
					color: 'info',
				}),
			});
			const start = moment();
			const { task: taskId } = await deleteFromDataset(partnerId, datasetName, file, 'email');
			const data = await getResult(() => pollTask(taskId), pollTime);
			const { skipped, read, deleted } = data;
			const delta = moment.duration(moment().diff(start)).asMilliseconds();
			const lastMessage = new Message('Upload complete', <Fragment>
				<p>The upload of {file.name} was successful.</p>
				<dl className="row text-left">
					<dt className="col-6 col-lg-3">Rows</dt>
					<dd className="col-6 col-lg-9">{read}</dd>
					<dt className="col-6 col-lg-3">Skipped</dt>
					<dd className="col-6 col-lg-9">{skipped}</dd>
					<dt className="col-6 col-lg-3">Deleted</dt>
					<dd className="col-6 col-lg-9">{deleted}</dd>
					<dt className="col-6 col-lg-3">Took</dt>
					<dd className="col-6 col-lg-9">{delta} ms</dd>
				</dl>
			</Fragment>, {
				color: 'success',
			});

			this.setState({
				lastMessage,
			});
		} catch(err) {
			let message = err.message;
			if(err.response) {
				message = err.response.data;
				try {
					if(typeof message === 'string') {
						message = JSON.parse(message);
					}
					if ('message' in message) {
						message = message.message;
					} else if ('errorMessage' in message) {
						message = message.errorMessage;
					}
				} catch(ignored) {
					//do nothing
				}
			}
			this.setState({
				lastMessage: new Message(`Error while uploading ${file.name}`, message, {
					color: 'danger',
				}),
			});
		}
	}

	render() {
		return <UploadFileForm
			helpMessage="Het CSV bestand moet een kolom genaamt 'email' bevatten."
			lastMessage={this.state.lastMessage}
			uploadFile={this.uploadFile}
			partners={this.state.partners}
			datasets={this.state.datasets}
			fetchDatasets={this.fetchDatasets}
			partnerId={this.props.match.params.partnerId || ''}
			datasetId={this.props.match.params.datasetId || ''}
		/>;
	}
}
