import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'reactstrap';

import Message from '../../messages/Message';
import ListItems from '../crud/ListItems';
import { withLoader } from '../../hoc/with-loader';

import './ListPartnerDataset.css';
import {withPrompts} from '../../hoc/with-prompts';

class ListPartnerDataset extends React.Component {
	static propTypes = {
		fetchPartner: PropTypes.func.isRequired,
		fetchDatasets: PropTypes.func.isRequired,
		partnerId: PropTypes.string.isRequired,
		deleteDataset: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			datasets: null,
			partner: null,
			lastMessage: null,
		};
	}

	componentDidMount() {
		this.getDatasets();
	}

	getDatasets = () => {
		this.setState({
			lastMessage: null,
			onlyShowError: false,
		});

		Promise.all([
			this.props.fetchDatasets(this.props.partnerId),
			this.props.fetchPartner(this.props.partnerId),
		]).then(([ datasets, partner ]) => {
			this.setState({
				datasets,
				partner,
			});
		}).catch(err => {
			this.setState({
				lastMessage: new Message('An error occured while fetching datasets', err.message, {
					color: 'danger',
				}),
			});
		});
	}

	deleteDataset = dataset => {
		this.props.deleteDataset(this.props.partnerId, dataset._id).then(() => {
			this.getDatasets();
			this.setState({
				lastMessage: new Message('Bestand verwijderd', `Het bestand '${dataset.description}' is verwijderd`, {
					color: 'success',
				}),
			});
		}).catch(err => {
			this.setState({
				lastMessage: new Message('Kon bestand niet verwijderen', err.response.data.message, {
					color: 'danger',
				}),
			});
		});
	};

	renderList = () => <ListItems
		containerId="listDatasets"
		headerText={`Bestanden van ${this.state.partner.name}`}
		items={this.state.datasets}
		newLink={{
			text: 'Nieuw bestand',
			url: `/partners/${this.props.partnerId}/datasets/new`,
		}}
		preListText={<Row className="datasetRow preListRow">
			<Col xs="12" lg={{offset: 9}}>
				Totaal: <strong>{this.state.datasets.reduce((total, dataset) => total + dataset.recordCount, 0)}</strong>
			</Col>
		</Row>}
		getItemDisplayText={dataset => <Row className="datasetRow">
			<Col xs="12" lg="9">{dataset.description !== '' ? dataset.description : dataset.name}</Col>
			<Col><strong>{dataset.recordCount}</strong> e-mails</Col>
		</Row>}
		getPrependActions={dataset => {
			return [
				{
					icon: 'cloud-upload-alt',
					link: `/upload/${this.props.partnerId}/${dataset._id}`,
					tooltip: 'Upload een bestand',
				},
				{
					icon: 'file-csv',
					link: `/api/datasets/${this.props.partnerId}/${dataset._id}/csv`,
					linkIsRegular: true,
					tooltip: 'Download CSV van dit bestand',
				},
				{
					icon: 'columns',
					link: `/partners/${this.props.partnerId}/datasets/${dataset._id}/headers`,
					tooltip: 'Dit bestand mappen',
				},
			];
		}}
		getEditAction={dataset => {
			return {
				icon: 'edit',
				link: `/partners/${this.props.partnerId}/datasets/${dataset._id}/edit`,
				tooltip: `${dataset.name} bewerken`,
			};
		}}
		getDeleteAction={dataset => {
			return {
				icon: 'times',
				iconClass: 'text-danger',
				tooltip: `${dataset.name} verwijderen`,
				onClick: () => this.props.prompt({
					title: 'Bestand verwijderen?',
					text: `Weet je zeker dat je '${dataset.description}' wilt verwijderen?`,
					onConfirm: () => this.deleteDataset(dataset),
				}),
			};
		}}
		lastMessage={this.state.lastMessage}
	/>;

	render = () => this.props.renderWithLoader(this.state.datasets, this.renderList);
}

export default withLoader(withPrompts(ListPartnerDataset));
