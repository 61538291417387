import React, { Component } from 'react';
import MappingsByDataset from '../components/valuemappings/MappingsByDataset';
import { getValueMappingDatasets } from '../api';

export default class ValueMappingContainer extends Component {
	state = {
		mappings: null,
		error: null
	};

	componentDidMount() {
		this.componentDidUpdate({ match: { params: {} } });
	}

	componentDidUpdate(props) {
		const { field } = this.props.match.params;
		if(field !== props.match.params.field) {
			this.setState({ error: null });
			getValueMappingDatasets(field).then(mappings => {
				this.setState({ mappings });
			}).catch(err => {
				this.setState({ error: err.message });
			});
		}
	}

	render() {
		const { mappings, error } = this.state;
		return <MappingsByDataset mappings={mappings} error={error} />;
	}
}
