import React, { Component } from 'react';
import { Button, Input, ButtonGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parseQuery } from './QueryBuilder';

function Prefix({ value = '', onChange }) {
	return <Input type="select" value={value} onChange={onChange} className="w-auto mr-1 mb-1 d-inline-block">
		<option value=""></option>
		<option value="(">(</option>
		<option value="!">!</option>
	</Input>;
}

function Infix({ value = '', onChange }) {
	return <ButtonGroup className="w-auto mr-1 mb-1 d-inline-block">
		<Button color={value === '&' ? 'success' : 'light'} onClick={() => onChange({ target: { value: '&' } })}>en</Button>
		<Button color={value === '|' ? 'primary' : 'light'} onClick={() => onChange({ target: { value: '|' } })}>of</Button>
	</ButtonGroup>;
}

function Postfix({ value = '', onChange }) {
	return <Input type="select" value={value} onChange={onChange} className="w-auto mr-1 mb-1 d-inline-block">
		<option value=""></option>
		<option value=")">)</option>
	</Input>;
}

const TYPE = {
	'(': Prefix,
	'!': Prefix,
	'&': Infix,
	'|': Infix,
	')': Postfix
};

function getType(token) {
	if(TYPE[token]) {
		return TYPE[token];
	}
	return token;
}

export function flatten(traverseQuery, query, flat = []) {
	traverseQuery(query, (array, key) => {
		if(array.length) {
			if(array.length > 1) {
				flat.push('(');
			}
			const op = key === 'and' ? '&' : '|';
			array.forEach((elem, i) => {
				if(i) {
					flat.push(op);
				}
				flatten(traverseQuery, elem, flat);
			});
			if(array.length > 1) {
				flat.push(')');
			}
		}
	}, not => {
		flat.push('!');
		flatten(traverseQuery, not, flat);
	}, token => {
		if(token) {
			flat.push(query);
		}
	});
	return flat;
}

function resolve(scope) {
	let stack = [];
	for(let i = 0; i < scope.length; i++) {
		const token = scope[i];
		if(token === '!') {
			const next = scope[i + 1];
			if(next) {
				if(typeof next === 'string') {
					i++;
				} else {
					stack.push({ not: scope[++i] });
				}
			}
		} else {
			stack.push(token);
		}
	}
	scope = stack;
	stack = [];
	for(let i = 0; i < scope.length; i++) {
		const token = scope[i];
		if(token === '&' || token === '|') {
			if(stack.length && scope[i + 1]) {
				const type = token === '&' ? 'and' : 'or';
				const tokens = [];
				const a = stack.pop();
				if(a[type]) {
					tokens.push(...a[type]);
				} else {
					tokens.push(a);
				}
				const b = scope[++i];
				if(b[type]) {
					tokens.push(...b[type]);
				} else {
					tokens.push(b);
				}
				stack.push({ [type]: tokens });
			}
		} else {
			stack.push(token);
		}
	}
	return stack[0] || null;
}

export function compile(flat) {
	const scope = [[]];
	let current = scope[0];
	flat.forEach(token => {
		if(token === '(') {
			current = [];
			scope.push(current);
		} else if(token === ')') {
			if(scope.length > 1) {
				const t = scope.pop();
				current = scope[scope.length - 1];
				if(t.length) {
					current.push(resolve(t));
				}
			} else {
				const t = resolve(scope[0]);
				current = [];
				if(t) {
					current.push(t);
				}
				scope.splice(0, scope.length, current);
			}
		} else {
			current.push(token);
		}
	});
	while(scope.length > 1) {
		const t = scope.pop();
		current = scope[scope.length - 1];
		if(t.length) {
			current.push(resolve(t));
		}
	}
	const query = resolve(current);
	if(query) {
		query._flat = flat;
	}
	return query;
}

function remove(flat, i) {
	const fix = typeof flat[i] === 'object' || flat[i] === '(';
	flat.splice(i, 1);
	while(fix && flat.length) { //eslint-disable-line
		const prev = i > 0 && getType(flat[i - 1]);
		const next = i < flat.length && getType(flat[i]);
		if( (!next || next === Postfix || next === Infix) && (prev === Infix || prev === Prefix)
			|| next === Prefix && prev === Postfix ) {
			flat.splice(--i, 1);
		} else if( (!prev || prev === Prefix || prev === Infix) && (next === Infix || next === Postfix) ) {
			flat.splice(i, 1);
		} else {
			break;
		}
	}
}

function BracketBox(props) {
	return <div {...props} className="query-bracket-box" />;
}

export default class FlatQueryEditor extends Component {
	editFlat(i, edit = false) {
		return e => {
			const flat = this.props.flat.slice();
			const { value } = e.target;
			if(edit) {
				if(value) {
					flat[i] = value;
				} else {
					remove(flat, i);
				}
			} else if(value) {
				flat.splice(i, 0, value);
			}
			this.props.edit(flat);
		};
	}

	editFlatLine(i) {
		return query => {
			const flat = this.props.flat.slice();
			if(!query) {
				remove(flat, i);
			} else {
				flat[i] = query;
			}
			this.props.edit(flat);
		};
	}

	insertFlat(i) {
		return () => this.props.editLine(query => {
			const flat = this.props.flat.slice();
			flat.splice(i, 0, query);
			if(i && getType(flat[i - 1]) !== Infix) {
				flat.splice(i, 0, '&');
			}
			this.props.edit(flat);
		});
	}

	render() {
		const { flat } = this.props;
		const root = [[]];
		let out = root[0];
		flat.forEach((element, i) => {
			if(element === '(') {
				const children = [];
				out.push(React.createElement(BracketBox, { children, key: out.length }));
				root.push(children);
				out = children;
			}
			const Op = TYPE[element];
			const prev = i && getType(flat[i - 1]);
			if(prev === Infix || prev === Prefix || !prev) {
				out.push(<Prefix key={out.length} onChange={this.editFlat(i)} />);
			} else if(Op !== Postfix && Op !== Infix) {
				out.push(<Infix key={out.length} onChange={this.editFlat(i)} />);
			}
			if(Op) {
				if(Op === Infix) {
					out.push(<br key={out.length} />);
					out.push(<Button key={out.length} color="info" className="mr-5" onClick={this.insertFlat(i)}><FontAwesomeIcon icon="plus" /></Button>);
				}
				out.push(<Op key={out.length} value={element} onChange={this.editFlat(i, true)} />);
				if(Op === Infix) {
					out.push(<br key={out.length} />);
				} else if(Op === Postfix) {
					out.push(<Postfix key={out.length} onChange={this.editFlat(i)} />);
				}
			} else {
				out.push(<div key={out.length} className="d-inline-block m-1">
					{parseQuery(element, this.editFlatLine(i), this.props.editLine)}
				</div>);
			}
			const next = i + 1 < flat.length && getType(flat[i + 1]);
			if(!Op || !next && Op !== Postfix) {
				out.push(<Postfix key={out.length} onChange={this.editFlat(i + 1)} />);
			}
			if(element === ')') {
				if(root.length > 1) {
					root.pop();
					out = root[root.length - 1];
				} else {
					const children = out;
					out = [];
					out.push(React.createElement(BracketBox, { children, key: out.length }));
					root[0] = out;
				}
			}
		});
		out.push(<br key={out.length} />);
		out.push(<Button key={out.length} color="info" onClick={this.insertFlat(flat.length)}><FontAwesomeIcon icon="plus" /></Button>);
		return root[0];
	}
}
