import React from 'react';

export default function DataView({items, renderItem}) {
	return <React.Fragment>
		{items.map((item, i) => {
			const content = renderItem(item, i);

			return React.cloneElement(content, { key: i });
		})}
	</React.Fragment>;
}
