import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Container} from 'reactstrap';

const defaultLoader = <Container className="text-center" style={{
	fontSize: '2rem',
}}>
	<FontAwesomeIcon icon="cog" spin /> Aan het laden...
</Container>;

/*
Add to PropTypes:

renderWithLoader: PropTypes.func
 */

export const withLoader = BaseComponent => class ComponentWithLoader extends React.Component {
	renderWithLoader = (items, renderComponent, loader = defaultLoader) => {
		if ([null, undefined].includes(items)) {
			return loader;
		}

		return renderComponent();
	};

	render = () => {
		return <BaseComponent renderWithLoader={this.renderWithLoader} {...this.props} />;
	}
};
