import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import SettingsForm from '../components/mailings/SettingsForm';
import PartnerTestPersonForm from '../components/crud-partner/PartnerTestPersonForm';
import Alert from '../components/alert/Alert';
import { getMailingSettings, setMailingSettings, getGlobalTestPerson, setGlobalTestPerson } from '../api';
import { withMessages } from '../hoc/with-messages';

const MESSAGE = 'settings-message';

class TestPersonContainer extends Component {
	state = {
		testPerson: null
	};

	componentDidMount() {
		getGlobalTestPerson().then(testPerson => {
			this.setState({ testPerson });
		}).catch(err => {
			console.error('Failed to get test person', err);
		});
	}

	submit = testPerson => {
		setGlobalTestPerson(testPerson).then(() => {
			this.props.setMessage(MESSAGE, 'Opgeslagen', 'De instellingen zijn opgeslagen.', {
				color: 'info',
			});
		}).catch(err => {
			this.props.setMessage(MESSAGE, 'Opslaan mislukt', err.response.data.message, {
				color: 'danger',
			});
		});
	};

	render() {
		const { testPerson } = this.state;
		if(!testPerson) {
			return <p>Loading...</p>;
		}
		const { message } = this.props;
		return <Container>
			{message && <Alert message={message} />}
			<PartnerTestPersonForm data={testPerson} onSubmit={this.submit} />
		</Container>;
	}
}

class SettingsContainer extends Component {
	state = {
		settings: null
	};

	componentDidMount() {
		getMailingSettings().then(settings => {
			this.setState({ settings });
		}).catch(err => {
			console.log('Failed to get settings', err);
		});
	}

	set(key) {
		return value => {
			const settings = Object.assign({}, this.state.settings);
			settings[key] = value;
			this.setState({ settings });
		};
	}

	save = e => {
		e.preventDefault();
		setMailingSettings(this.state.settings).then(() => {
			this.props.setMessage(MESSAGE, 'Opgeslagen', 'De instellingen zijn opgeslagen.', {
				color: 'info',
			});
		}).catch(err => {
			this.props.setMessage(MESSAGE, 'Opslaan mislukt', err.response.data.message, {
				color: 'danger',
			});
		});
	};

	render() {
		const { settings } = this.state;
		if(!settings) {
			return <h2>Loading...</h2>;
		}
		return <Switch>
			<Route path="/settings/testperson" render={() => <TestPersonContainer
				setMessage={this.props.setMessage}
				message={this.props.messages[MESSAGE]} />} />
			<Route render={() => <SettingsForm
				{...settings}
				message={this.props.messages[MESSAGE]}
				save={this.save}
				setMinMailings={this.set('minMailings')}
				setSolarId={this.set('solarId')} />} />
		</Switch>;
	}
}

export default withMessages(SettingsContainer);
