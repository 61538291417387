import React from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import {Container} from 'reactstrap';
import PartnerDatasetForm from './PartnerDatasetForm';
import Alert from '../alert/Alert';
import Message from '../../messages/Message';

export default class UpdatePartnerDataset extends React.Component {
	static propTypes = {
		partnerId: PropTypes.string.isRequired,
		datasetId: PropTypes.string.isRequired,
		onSubmit: PropTypes.func.isRequired,
		fetchDataset: PropTypes.func.isRequired,
		fetchTypes: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			lastMessage: null,
			doRedirect: false,
			dataset: null,
			types: [],
		};

		this.onSubmit = this.onSubmit.bind(this);
		this.getDataset = this.getDataset.bind(this);
	}

	componentDidMount() {
		this.getDataset();

		this.props.fetchTypes().then(types => {
			this.setState({
				types,
			});
		}).catch(err => {
			this.setState({
				lastMessage: new Message('Labels konden niet worden opgehaald', err.response.data.message, {
					color: 'warn',
				}),
			});
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.partnerId !== this.props.partnerId || prevProps.datasetId !== this.props.datasetId) {
			this.getDataset();
		}
	}

	getDataset() {
		this.setState({
			lastMessage: null,
		});

		this.props.fetchDataset(this.props.partnerId, this.props.datasetId).then(dataset => {
			dataset.emailColumnName = dataset.config.emailColumnName;

			this.setState({
				dataset,
			});
		}).catch(err => {
			this.setState({
				lastMessage: new Message('Er is een fout opgetreden bij het ophalen van het bestand', err.message, {
					color: 'danger',
				}),
			});
		});
	}

	onSubmit(dataset) {
		this.setState({
			lastMessage: null,
		});

		dataset.types = dataset.types.map(t => {
			if (t.value) {
				return t.value;
			}

			return t;
		});

		this.props.onSubmit(this.props.partnerId, this.props.datasetId, dataset).then(() => {
			this.setState({
				doRedirect: true,
			});
		}).catch(err => {
			this.setState({
				lastMessage: new Message('Bestand kon niet worden opgeslagen', err.message, {
					color: 'danger',
				}),
			});
		});
	}

	render() {
		if (this.state.doRedirect) {
			return <Redirect to={`/partners/${this.props.partnerId}/datasets`} />;
		}

		if (!this.state.dataset) {
			return <p>Loading...</p>;
		}

		const alert = this.state.lastMessage && <Alert message={this.state.lastMessage} />;

		return <div id="updateDataset">
			<Container>
				<h1>Update bestand {this.state.dataset.name}</h1>

				{alert}

				<PartnerDatasetForm
					onSubmit={this.onSubmit}
					data={this.state.dataset}
					types={this.state.types}
					commissionsLink={`/partners/${this.props.partnerId}/datasets/${this.props.datasetId}/commission`}
					participationLink={`/partners/${this.props.partnerId}/datasets/${this.props.datasetId}/participation`}
					testPersonsLink={`/partners/${this.props.partnerId}/datasets/${this.props.datasetId}/testpersons`}
				/>
			</Container>
		</div>;
	}
}
