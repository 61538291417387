import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import {Col, Row, Input } from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Alert from '../components/alert/Alert';
import Message from '../messages/Message';

export default class UploadFileForm extends React.Component {
	static propTypes = {
		lastMessage: PropTypes.instanceOf(Message),
		uploadFile: PropTypes.func.isRequired,
		partners: PropTypes.array.isRequired,
		datasets: PropTypes.array,
		fetchDatasets: PropTypes.func.isRequired,
		partnerId: PropTypes.string,
		datasetId: PropTypes.string,
	};

	static defaultProps = {
		lastMessage: null,
		datasets: null,
		partnerId: '',
		datasetId: '',
		helpMessage: null
	};

	constructor(props) {
		super(props);

		this.state = {
			partnerId: props.partnerId,
			datasetId: props.datasetId,
		};
	}

	componentDidMount() {
		if (this.props.partnerId) {
			this.props.fetchDatasets(this.props.partnerId);
		}
	}

	onPartnerChange = e => {
		const partnerId = e.target.value;

		this.setState({
			partnerId,
			datasetId: '',
		}, () => {
			this.props.fetchDatasets(partnerId);
		});
	};

	onDatasetChange = e => {
		this.setState({
			datasetId: e.target.value,
		});
	};

	onDrop = files => {
		if (files && files[0] && this.isFormValid()) {
			const dataset = this.getDataset(this.state.datasetId);

			if (dataset) {
				this.props.uploadFile(this.state.partnerId, dataset.name, files[0]);
			}
		}
	};

	getPartner = partnerId => this.props.partners.find(p => p.id === partnerId);
	getDataset = datasetId => this.props.datasets && this.props.datasets.find(d => d._id === datasetId);

	isFormValid = () => this.state.partnerId && this.state.datasetId && !!this.getPartner(this.state.partnerId) && !!this.getDataset(this.state.datasetId);

	render() {
		const alert = this.props.lastMessage && <Alert message={this.props.lastMessage} />;

		return (
			<div className={'main-container'}>
				<div className={'container'}>
					<h1 className="text-center">Partner</h1>
					{this.props.helpMessage && <p>{this.props.helpMessage}</p>}
					<Row>
						<Col lg={6} className={'offset-lg-3 mb-2'}>
							<Input type="select" value={this.state.partnerId} onChange={this.onPartnerChange}>
								<option value="" disabled>Selecteer een partner</option>
								{this.props.partners.map(partner => {
									return <option key={partner.id} value={partner.id}>{partner.name}</option>;
								})}
							</Input>
						</Col>
					</Row>
					{this.props.datasets && Array.isArray(this.props.datasets) &&
					<Row>
						<Col lg={6} className={'offset-lg-3 mb-2'}>
							<Input type="select" value={this.state.datasetId} onChange={this.onDatasetChange}>
								<option value="" disabled>Selecteer een bestand</option>
								{this.props.datasets.map(dataset => {
									return <option key={dataset._id} value={dataset._id}>{dataset.name}</option>;
								})}
							</Input>
						</Col>
					</Row>
					}
					<Row>
						<Col lg={12}>
							{alert}
						</Col>
					</Row>

					<Row>
						<Col lg={8} className={'offset-lg-2'}>
							<section>
								<Dropzone
									onDrop={this.onDrop}
									disabled={!this.isFormValid()}
									accept=".csv"
									multiple={false}
									className={this.isFormValid() ? 'dropzone2' : 'dropzone2-disabled'}>

									<h1>Drag & Drop</h1>
									<p>Een CSV bestand</p>
									<p>(of klik om een bestand te uploaden)</p>

									<FontAwesomeIcon
										className={'icon-larger'}
										icon="cloud-upload-alt"
										size={'10x'}
										color={this.isFormValid() ? 'grey' : 'lightskyblue'}
									/>
								</Dropzone>
							</section>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}
