import React from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import { history } from '../history';

import UsersForm from '../components/users/UsersForm';
import UpdateOrCreateItem from '../components/crud/UpdateOrCreateItem';

import * as api from '../api';
import { withMessages } from '../hoc/with-messages';
import {setUsers} from '../redux/actions/user';
import {withLoader} from '../hoc/with-loader';
import ListItems from '../components/crud/ListItems';
import {validateCredentials, validatePassword, validateUsername} from '../validation/auth';
import {withPrompts} from '../hoc/with-prompts';

const LIST_USER_MESSAGE = 'user-list-message';
const NEW_USER_MESSAGE = 'new-user-message';
const EDIT_USER_MESSAGE = 'edit-user-message';
class UserContainer extends React.Component {
	componentDidMount() {
		this.fetchUsers();
	}

	fetchUsers = () => {
		api.fetchUsers().then(users => this.props.dispatch(setUsers(users)))
			.catch(err => {
				this.props.setMessage(LIST_USER_MESSAGE, 'Gebruikers konden niet worden opgehaald', err.response.data.message, {
					color: 'danger',
				});
			});
	};

	onNewUserSubmit = data => {
		validateCredentials.validate(data).then(() => {
			api.createUser(data).then(() => {
				this.props.setMessage(LIST_USER_MESSAGE, 'Success', 'Gebruiker is aangemaakt', {
					color: 'success',
				});
				this.fetchUsers();
				history.push('/users');
			});
		}).catch(err => {
			this.props.setMessage(NEW_USER_MESSAGE, 'Foute invoer', err.message, {
				color: 'danger',
			});
		});
	};

	onEditUserSubmit = (userId, data) => {
		const promises = [ validateUsername.validate(data) ];

		if ((data.password || '').length > 0) {
			promises.push(validatePassword.validate(data));
		}

		this.props.clearMessage(EDIT_USER_MESSAGE);

		Promise.all(promises).then(() => {
			api.updateUser(userId, data).then(() => {
				this.props.setMessage(LIST_USER_MESSAGE, 'Gebruiker succesvol opgeslagen', 'De gebruiker is succesvol opgeslagen', {
					color: 'success',
				});
				this.fetchUsers();
				history.push('/users');
			});
		}).catch(err => {
			this.props.setMessage(EDIT_USER_MESSAGE, 'Foute invoer', err.message, {
				color: 'danger',
			});
		});
	};

	deleteUser = user => {
		api.deleteUser(user._id).then(() => {
			this.props.setMessage(LIST_USER_MESSAGE, 'Gebruiker succesvol verwijderd', `Gebruiker ${user.name} is succesvol verwijderd`, {
				color: 'success',
			});
			this.fetchUsers();
		}).catch(err => {
			this.props.setMessage(LIST_USER_MESSAGE, 'Kon gebruiker niet verwijderen', err.response.data.message, {
				color: 'danger',
			});
		});
	};

	renderList = () => <ListItems
		containerId="listUsers"
		headerText="Gebruikers"
		items={this.props.users}
		newLink={{
			text: 'Nieuwe gebruiker',
			url: '/users/new',
		}}
		itemIdProperty="_id"
		itemDisplayTextProperty="name"
		lastMessage={this.props.messages[LIST_USER_MESSAGE]}
		getEditAction={user => {
			return {
				icon: 'edit',
				link: `/users/edit/${user._id}`,
				tooltip: `Bewerk ${user.name}`,
			};
		}}
		getDeleteAction={user => {
			return {
				icon: 'times',
				iconClass: 'text-danger',
				tooltip: `Verwijder ${user.name}`,
				onClick: () => this.props.prompt({
					title: `Verwijder gebruiker ${user.name}?`,
					text: `Weet je zeker dat je gebruiker ${user.name} wilt verwijderen?`,
					onConfirm: () => this.deleteUser(user),
				}),
			};
		}}
	/>;

	renderEdit = userId => {
		const user = this.props.users.find(u => u._id === userId);

		if (!user) {
			this.props.setMessage(LIST_USER_MESSAGE, 'Gebruiker niet gevonden', 'Deze gebruiker kan niet gevonden worden', {
				color: 'danger',
			});
			history.push('/users');
			return null;
		}

		const usersForm = <UsersForm
			onSubmit={data => this.onEditUserSubmit(userId, data)}
			data={user}
			reload={this.fetchUsers}
			twoFA
		/>;

		return <UpdateOrCreateItem
			containerId="Edit user"
			headerText={`Bewerk gebruiker ${user.username}`}
			loaded
			form={usersForm}
			lastMessage={this.props.messages[EDIT_USER_MESSAGE]}
		/>;
	}

	render = () => <Switch>
		<Route path="/users/edit/:userId" render={props => this.props.renderWithLoader(
			this.props.users,
			() => this.renderEdit(props.match.params.userId)
		)} />
		<Route path="/users/new" render={() => {
			const usersForm = <UsersForm
				onSubmit={data => this.onNewUserSubmit(data)}
			/>;

			return <UpdateOrCreateItem
				containerId="newUsers"
				headerText="Nieuwe gebruiker"
				loaded
				form={usersForm}
				lastMessage={this.props.messages[NEW_USER_MESSAGE]}
			/>;
		}} />
		<Route render={() => this.props.renderWithLoader(this.props.users, this.renderList)} />
	</Switch>;
}

const mapStateToProps = store => {
	return {
		users: store.user.users,
	};
};

export default connect(mapStateToProps)(withMessages(withLoader(withPrompts(UserContainer))));
