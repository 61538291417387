import React, { Component } from 'react';
import Token from './Token';

export default class Dataset extends Component {
	remove = () => {
		this.props.edit(null);
	};

	edit = () => {
		this.props.editLine(this.props.edit, 'dataset', this.props.token);
	};

	render() {
		if(this.props.token.id) {
			return <Token
				edit={this.edit}
				remove={this.remove}
				value={`dataset = ${this.props.token.id}`} />;
		}
		return <Token
			edit={this.edit}
			remove={this.remove}
			value={`dataset type = ${this.props.token.type}`} />;
	}
}
