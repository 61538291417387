import React from 'react';
import PropTypes from 'prop-types';
import {Input, FormGroup, Label, FormText} from 'reactstrap';

export default class PartnerField extends React.Component {
	static propTypes = {
		label: PropTypes.string,
		name: PropTypes.string.isRequired,
		onChange: PropTypes.func.isRequired,
		type: PropTypes.string,
		getdata: PropTypes.func.isRequired,
		readOnly: PropTypes.bool,
		pattern: PropTypes.string,
		title: PropTypes.string,
		description: PropTypes.string,
	};

	static defaultProps = {
		label: null,
		type: 'text',
		readOnly: false,
		pattern: null,
		title: null,
	};

	constructor(props) {
		super(props);

		this.renderInput = this.renderInput.bind(this);
	}

	renderInput() {

		const {name, type, onChange, readOnly, pattern, title, min, step} = this.props;
		const inputProps = {
			name,
			type,
			onChange,
			readOnly,
			pattern,
			title,
		};

		const valueOrChecked = this.props.getdata(this.props.name);

		if (min !== undefined) {
			inputProps.min = min;
		}

		if (step !== undefined) {
			inputProps.step = step;
		}

		if ([ 'checkbox', 'radio' ].includes(type)) {
			inputProps.checked = valueOrChecked;
		} else {
			inputProps.value = valueOrChecked;
		}

		if (type === 'select') {
			return <Input {...inputProps}>{this.props.children}</Input>;
		}

		return <Input {...inputProps} />;
	}

	render() {
		if ([ 'checkbox', 'radio' ].includes(this.props.type)) {
			return <FormGroup check>
				<Label check>
					{this.renderInput()} {this.props.label}
				</Label>
			</FormGroup>;
		}

		const label = this.props.label ? <Label>{this.props.label}</Label> : null;
		const description = this.props.description ? <FormText>{this.props.description}</FormText> : null;

		return <FormGroup>
			{label}
			{this.renderInput()}
			{description}
		</FormGroup>;
	}
}
