import Task from './Task';
import React, { Component } from 'react';
import {Container, Input} from 'reactstrap';

export default class TaskList extends Component {

	displayMessage = (tasks, searchTerm) => {
		if(tasks.length === 0) {
			if(searchTerm) {
				return 'Geen taken gevonden voor zoekopdracht ' + searchTerm + '.';
			}
			return 'Geen recente taken.';
		}

		return '';
	};

	render() {
		const { tasks } = this.props;
		return <Container>
			<Input
				type="text"
				className="col-5 float-right"
				placeholder="Zoeken"
				maxLength={50}
				onChange={this.props.onSearch}
			/>
			<h1 className="mb-3">Taken</h1>

			{
				tasks.map(task => {
					return <Task
						key={task._id}
						id={task._id}
						dataset={task.dataset}
						date={task.date}
						file={task.file}
						error={task.error}
						partner={task.partner}
						percentage={task.percentage}
						state={task.state}
						type={task.type}
						username={task.username}
						result={task.result}
					/>;
				})
			}
			{this.displayMessage(tasks, this.props.searchTerm)}
		</Container>;
	}
}
