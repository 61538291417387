import React from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import MappingEditor from '../components/crud-partner/editor/MappingEditor';
import ValueMappingEditor from '../components/crud-partner/editor/ValueMappingEditor';
import { getData } from '../util/Poller';

import {
	setDatasetHeaders,
	setDatasetId, setDatasetMappings,
	setGlobalMappings, setGlobalValueMappings,
	updateMapping, importValueMappings,
	setPartnerId, setSelectedMapping, setSelectedMappingHeaders, setSelectedMappingHeaderValue,
} from '../redux/actions/mapping-editor';
import * as api from '../api';
import { sortStrings } from '../util/array';

import Message from '../messages/Message';

const DEFAULT_REMAP_BUTTON_TEXT = 'Mapping doorvoeren';
const pollTime = 2000;

class MappingEditorContainer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			message: null,
			remapButtonText: DEFAULT_REMAP_BUTTON_TEXT,
			remapButtonEnabled: true,
		};

		this.onSubmit = this.onSubmit.bind(this);
		this.onRemapClick = this.onRemapClick.bind(this);
	}

	componentDidMount() {
		this.updateFromURLParams();
		this.updateGlobalMappings();
	}

	componentDidUpdate(prevProps) {
		this.updateFromURLParams();

		const {partnerId, datasetId, selectedMapping, mappings, globalValueMappings, selectedMappingHeaders} = this.props;

		if (!partnerId || !datasetId) {
			return;
		}

		if (partnerId !== prevProps.partnerId || datasetId !== prevProps.datasetId) {
			this.updateMappingData();
		}

		// eslint-disable-next-line no-mixed-operators
		if (Number.isInteger(selectedMapping) && mappings.length && (selectedMapping !== prevProps.selectedMapping || !globalValueMappings.length && !selectedMappingHeaders.length)) {
			this.updateValueMappingData();
		}
	}

	componentWillUnmount() {
		this.props.dispatch(setPartnerId(null));
		this.props.dispatch(setDatasetId(null));
		this.props.dispatch(setSelectedMapping(null));
		this.props.dispatch(setDatasetHeaders(null));
		this.props.dispatch(setDatasetMappings([]));
		this.props.dispatch(setSelectedMappingHeaders([]));
		this.props.dispatch(setGlobalValueMappings([]));
		this.props.dispatch(setGlobalMappings([]));
	}

	updateGlobalMappings() {
		api.fetchGlobalDatasetHeaderMappings().then(data => {
			this.props.dispatch(setGlobalMappings(data.sort(sortStrings)));
		});
	}

	updateMappingData() {
		const {partnerId, datasetId} = this.props;

		Promise.all([
			api.fetchDatasetHeaders(partnerId, datasetId),
			api.fetchDatasetHeaderMappings(partnerId, datasetId),
		]).then(([ datasetHeaders, datasetMappings ]) => {
			let id = 0;
			const fields = {};
			const mappings = datasetMappings.sort((lhs, rhs) => {
				if(lhs.type < rhs.type) {
					return -1;
				} else if(lhs.type > rhs.type) {
					return 1;
				} else if(lhs.type === 'extra') {
					if(lhs.mapped < rhs.mapped) {
						return -1;
					} else if(lhs.mapped > rhs.mapped) {
						return 1;
					}
					return 0;
				} else if(lhs.field < rhs.field) {
					return -1;
				} else if(lhs.field > rhs.field) {
					return 1;
				}
				return 0;
			}).map(m => {
				m.id = id++;
				if(m.field) {
					fields[m.field] = true;
				}
				return m;
			});
			datasetHeaders.sort(sortStrings).forEach(field => {
				if(!fields[field]) {
					mappings.push({
						type: 'column',
						field,
						id: id++,
					});
				}
			});

			this.props.dispatch(setDatasetHeaders(datasetHeaders));
			this.props.dispatch(setDatasetMappings(mappings, id));
		}).catch(e => {
			console.error(e);
		});
	}

	updateValueMappingData() {
		const {partnerId, datasetId, selectedMapping, mappings} = this.props;
		const mapping = mappings.find(m => m.id === selectedMapping);

		if(mapping) {
			Promise.all([
				api.fetchUniqueDatasetValues(partnerId, datasetId, mapping.field),
				api.fetchGlobalValueMappings(mapping.field),
			]).then(([ uniqueValues, globalValueMappings ]) => {
				const map = {};
				uniqueValues.forEach(v => {
					map[v.value] = true;
				});
				(mapping.values || []).forEach(valueMapping => {
					if(!map[valueMapping.original]) {
						uniqueValues.push({
							value: valueMapping.original,
							amount: 0
						});
					}
				});
				this.props.dispatch(setSelectedMappingHeaders(uniqueValues));
				this.props.dispatch(setGlobalValueMappings(globalValueMappings.sort(sortStrings)));
			}).catch(e => {
				console.error(e);
			});
		}
	}

	updateFromURLParams() {
		const {partnerId, datasetId, mapping} = this.props.match.params;

		if (partnerId) {
			this.props.dispatch(setPartnerId(partnerId));
		}

		if (datasetId) {
			this.props.dispatch(setDatasetId(datasetId));
		}

		this.props.dispatch(setSelectedMapping(+mapping));
	}

	onSubmit(e) {
		e.preventDefault();

		const {partnerId, datasetId, mappings, selectedMapping} = this.props;

		api.upsertDatasetHeaderMappings(partnerId, datasetId, {
			mappings: mappings.filter(m => {
				if(m.type === 'column') {
					return m.mapped;
				}
				return m.mapped && m.value;
			}).map(m => {
				return Object.assign({}, m, { id: undefined });
			}),
		}).then(() => {
			this.setState({
				message: new Message('Saved', 'Mappings saved successfully', {
					color: 'success',
				}),
			});

			this.updateMappingData();
			this.updateGlobalMappings();

			if (Number.isInteger(selectedMapping) && mappings.length) {
				this.updateValueMappingData();
			}
		}).catch(err => {
			console.error(err.response);
			this.setState({
				message: new Message('Failed to save mappings', 'Mappings could not be saved', {
					color: 'danger',
				}),
			});
		});

		return false;
	}

	onRemapClick(e) {
		e.preventDefault();

		const {partnerId, datasetId} = this.props;

		const resetRemapButton = () => this.setState({
			remapButtonText: DEFAULT_REMAP_BUTTON_TEXT,
			remapButtonEnabled: true,
		});

		this.setState({
			remapText: <span><FontAwesomeIcon icon="sync" spin /> Remapping...</span>,
			remapButtonEnabled: false,
		});

		api.startRemap(partnerId, datasetId).then(taskId => {
			return getData(() => api.pollTask(taskId, pollTime));
		}).then(() => {
			resetRemapButton();
			this.setState({
				message: new Message('Remapped', 'Dataset has been successfully remapped'),
			});
		}).catch(err => {
			console.error(err.response);
			resetRemapButton();
		});

		return false;
	}

	downloadValueMappings = mapping => {
		this.setState({
			message: new Message('Fetching', `Fetching value mappings for ${mapping.mapped}`),
		});
		api.getValueMappings(mapping.mapped).then(mappings => {
			this.props.dispatch(importValueMappings(mapping.id, mappings));
			this.setState({
				message: new Message('Success', `Added value mappings for ${mapping.mapped}`)
			});
		}).catch(err => {
			console.error(err);
			this.setState({
				message: new Message('Error', `Failed to get value mappings for ${mapping.mapped}`),
			});
		});
	};

	render() {
		if (this.props.headers === null) {
			return <p>Loading...</p>;
		}

		return <Switch>
			<Route path="/partners/:partnerId/datasets/:datasetId/:mapping/mappings" exact render={() => {
				if (!Number.isInteger(this.props.selectedMapping)) {
					return <p>Aan het laden...</p>;
				}

				const {selectedMapping} = this.props;

				const mapping = this.props.mappings.find(m => m.id === this.props.selectedMapping);

				const message = (() => {
					if (!mapping) {
						return new Message('No mapping for this field', 'There is no mapping for this field, please click the back button and map this field', {
							color: 'danger',
						});
					} else if (mapping && mapping.encrypted) {
						return new Message('Mapping is encrypted', 'An encrypted mapping cannot be edited', {
							color: 'danger',
						});
					}

					return this.state.message;
				})();

				return <ValueMappingEditor
					returnURL={`/partners/${this.props.partnerId}/datasets/${this.props.datasetId}/headers`}
					onSubmit={this.onSubmit}
					filters={this.props.filters}
					mapping={mapping || null}
					filterName={`${this.props.datasetId}_${this.props.selectedMapping}`}
					globalMappings={this.props.globalValueMappings}
					headers={this.props.selectedMappingHeaders}
					onMappingChange={(header, value) => {
						this.props.dispatch(setSelectedMappingHeaderValue(selectedMapping, header, value.mapping));
					}}
					message={message}
					onRemapButtonClick={this.onRemapClick}
					remapButtonText={this.state.remapButtonText}
					remapButtonEnabled={this.state.remapButtonEnabled}
					paginatorName={`${this.props.datasetId}_${this.props.selectedMapping}`}
				/>;
			}} />

			<Route path="/partners/:partnerId/datasets/:datasetId/headers" exact render={() => {
				return <MappingEditor
					partnerId={this.props.partnerId}
					datasetId={this.props.datasetId}
					onSubmit={this.onSubmit}
					onMappingChange={(mapping, values) => {
						this.props.dispatch(updateMapping(mapping, values));
					}}
					downloadValueMappings={this.downloadValueMappings}
					filters={this.props.filters}
					headers={this.props.headers}
					globalMappings={this.props.globalMappings}
					mappings={this.props.mappings}
					message={this.state.message}
					onRemapButtonClick={this.onRemapClick}
					remapButtonText={this.state.remapButtonText}
					remapButtonEnabled={this.state.remapButtonEnabled}
				/>;
			}} />
		</Switch>;
	}
}

const mapStateToProps = store => {
	const {partnerId, datasetId, selectedMapping, headers, globalMappings, mappings, selectedMappingHeaders, globalValueMappings} = store.mappingEditor;
	const {filters} = store.filter;

	return {
		partnerId,
		datasetId,
		headers,
		filters,
		globalMappings,
		mappings,
		selectedMapping,
		selectedMappingHeaders,
		globalValueMappings,
	};
};

export default connect(mapStateToProps)(MappingEditorContainer);
