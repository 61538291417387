import React from 'react';
import {connect} from 'react-redux';
import HttpStatus from 'http-status-codes';

import AuthForm from '../components/auth/AuthForm';
import {login} from '../api';
import {setAccount} from '../redux/actions/auth';
import {withMessages} from '../hoc/with-messages';

const AUTH_MESSAGE = 'auth-message';
class AuthContainer extends React.Component {
	clear = () => {
		this.props.clearMessage(AUTH_MESSAGE);
	};

	setTwoFa = () => {
		this.props.setMessage(AUTH_MESSAGE, '2fa ingeschakeld', 'Vul uw 2fa token in', {
			color: 'info',
			twoFA: true
		});
	}

	onSubmit = (username, password, token='') => {
		if(token) {
			this.setTwoFa();
		}
		login(username, password, token)
			.then(res => {
				if(res.status === HttpStatus.PARTIAL_CONTENT){
					this.setTwoFa();
				}else {
					this.props.dispatch(setAccount(res.data));
				}
			})
			.catch(err => {
				if(err?.response?.status === HttpStatus.FORBIDDEN) {
					this.props.setMessage(AUTH_MESSAGE, 'Token ongeldig', 'Vul een geldig token in', {
						color: 'danger',
						twoFA: true
					});
				}else {
					this.props.setMessage(AUTH_MESSAGE, 'Inloggen mislukt', err.response.data.message, {
						color: 'danger',
					});
				}
			});
	}

	render = () => <AuthForm
		onSubmit={this.onSubmit}
		lastMessage={this.props.messages[AUTH_MESSAGE]}
		clear={this.clear}
	/>;
}

export default connect()(withMessages(AuthContainer));
