import React from 'react';
import Message from '../messages/Message';

export const withMessages = BaseComponent => class ComponentWithMessages extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			messages: {},
		};
	}

	setMessage = (name, heading, content, data) => {
		return new Promise(resolve => {
			const messages = Object.assign({}, this.state.messages);
			messages[name] = new Message(heading, content, data);

			this.setState({
				messages,
			}, resolve);
		});
	}

	clearMessage = name => {
		return new Promise(resolve => {
			const messages = Object.assign({}, this.state.messages);
			delete messages[name];

			this.setState({
				messages,
			}, resolve);
		});
	}

	render() {
		return <BaseComponent
			setMessage={this.setMessage}
			clearMessage={this.clearMessage}
			messages={this.state.messages}
			{...this.props}
		/>;
	}
};
