import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ListGroupItem, UncontrolledTooltip} from 'reactstrap';
import {listGroupItemProps} from './list-group-action-props';

export default class ListItem extends React.Component {
	static propTypes = {
		text: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.node,
		]).isRequired,
		actions: PropTypes.arrayOf(listGroupItemProps).isRequired,
	};

	constructor(props) {
		super(props);

		this.tooltipTargetBase = `tooltip_${Date.now()}_${Math.random()}`.replace('.', '');
	}

	render() {
		return <ListGroupItem tag="div" className="d-flex align-items-center">
			{this.props.text}

			<div className="actions ml-auto">
				{this.props.item && this.props.item['2fa'] && <FontAwesomeIcon icon="key" className="fa-2x mr-3" />}
				{this.props.actions.map((action, i) => {
					const iconProps = {
						className: [
							'fa-2x',
							'text-info',
							'clickable',
						].concat((action.iconClass || '').split(' ')),
						icon: action.icon,
					};

					if (action.onClick) {
						iconProps.onClick = action.onClick;
					}

					iconProps.className = iconProps.className.join(' ');

					let faIcon = <FontAwesomeIcon key={i} id={action.tooltip} {...iconProps} />;

					if (action.link) {
						const linkProps = {
							key: i,
						};

						if (i !== this.props.actions.length - 1 && !action.tooltip) {
							linkProps.className = 'mr-2';
						}

						if (action.linkIsRegular) {
							linkProps.href = action.link;
							linkProps.rel = 'noopener, noreferrer';

							faIcon = <a id={action.tooltip} {...linkProps}>
								<FontAwesomeIcon {...iconProps} />
							</a>;
						} else {
							linkProps.to = action.link;

							faIcon = <Link id={action.tooltip} {...linkProps}>
								<FontAwesomeIcon {...iconProps} />
							</Link>;
						}
					}

					if (action.tooltip) {
						return <div key={i} id={this.tooltipTargetBase + i} className={classNames('iconWithTooltip', 'd-inline-block', {
							'mr-2': this.props.actions.length - 1 !== i,
						})}>
							{faIcon}
							<UncontrolledTooltip placement="bottom" target={`#${this.tooltipTargetBase + i}`} delay={0}>
								{action.tooltip}
							</UncontrolledTooltip>
						</div>;
					}

					return faIcon;
				})}
			</div>
		</ListGroupItem>;
	}
}
