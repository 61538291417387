import React, { Component } from 'react';
import { Container, Table } from 'reactstrap';
import { getMailPressure } from '../api';

export default class PressureContainer extends Component {
	state = {
		pressure: []
	};

	componentDidMount() {
		getMailPressure().then(pressure => {
			pressure.forEach(p => {
				p.date = new Date(p.date);
			});
			pressure.sort((lhs, rhs) => {
				if(lhs.date > rhs.date) {
					return 1;
				} else if(lhs.date < rhs.date) {
					return -1;
				}
				return 0;
			});
			this.setState({ pressure });
		}).catch(err => {
			console.error('Failed to get pressure', err);
		});
	}

	render() {
		let last = NaN;
		return <Container>
			<h1>Maildruk</h1>
			<Table>
				<thead>
					<tr>
						<th>Datum</th>
						<th>E-mailadressen</th>
					</tr>
				</thead>
				<tbody>
					{this.state.pressure.map(line => {
						let className;
						if(line.date.getDay() < last) {
							className = 'border-bottom';
						}
						last = line.date.getDay();
						return <tr className={className}>
							<td className="border-0">{line.date.toLocaleDateString()}</td>
							<td className="border-0">{line.count}</td>
						</tr>;
					})}
				</tbody>
			</Table>
		</Container>;
	}
}
