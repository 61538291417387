import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import {icons} from './fa-icons';

import {store} from './redux/store';

import App from './containers/App';
import ApiDocsContainer from './containers/ApiDocsContainer';
import {history} from './history';

import 'react-datepicker/dist/react-datepicker.min.css';
import './index.css';

library.add(...icons);

const app =
	<Provider store={store}>
		<Router history={history}>
			<Switch>
				<Route path="/apidocs" component={ApiDocsContainer} />
				<Route component={App} />
			</Switch>
		</Router>
	</Provider>;

render(app, document.getElementById('root'));
