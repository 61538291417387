import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import {Table, Form, FormGroup, Container, Button, Input, InputGroup, InputGroupAddon} from 'reactstrap';
import InputAutocomplete from '../../Autocomplete';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Alert from '../../alert/Alert';
import PaginatorContainer from '../../../paginator/PaginatorContainer';
import {withMessages} from '../../../hoc/with-messages';
import FilterContainer from '../../../containers/FilterContainer';
import {makeRegExp} from '../../../util/string';

const EDITOR_MESSAGE = 'EDITOR_MESSAGE';

const MAX_DISPLAY_ITEMS = 10;

function DateFormatToolTip() {
	const [shown, setShown] = useState(false);
	if(!shown) {
		return <p className="text-primary clickable" onClick={() => setShown(true)}><u>Datum formaat</u></p>;
	}
	return <div className="mb-2">
		Geef in de datumkolom de notatie van datumvelden zoals ze in het databestand zijn genoteerd.<br />
		Datums worden vervolgens in het formaat YYYY-MM-DD HH:mm:ss opgeslagen. <br />
		<i>Let op hoofdlettergevoeligheid.</i><br />
		<table className="mt-2">
			<tr>
				<th className="px-3">Invoer</th>
				<th className="px-3">Beschrijving</th>
			</tr>
			<tr>
				<td className="px-3 border-top">YYYY</td>
				<td className="px-3 border-top">Jaar</td>
			</tr>
			<tr>
				<td className="px-3">MM</td>
				<td className="px-3">Maand</td>
			</tr>
			<tr>
				<td className="px-3">DD</td>
				<td className="px-3">Dag</td>
			</tr>
			<tr>
				<td className="px-3 border-top">gggg</td>
				<td className="px-3 border-top">Week jaar</td>
			</tr>
			<tr>
				<td className="px-3">w / ww</td>
				<td className="px-3">Week nummer</td>
			</tr>
			<tr>
				<td className="px-3">MMM MMMM</td>
				<td className="px-3">Maand naam (Jan ... December)</td>
			</tr>
			<tr>
				<td className="px-3">ddd dddd</td>
				<td className="px-3">Dagnaam (Mon ... Sunday)</td>
			</tr>
			<tr>
				<td className="px-3 border-top">HH</td>
				<td className="px-3 border-top">Uur</td>
			</tr>
			<tr>
				<td className="px-3">mm</td>
				<td className="px-3">Minuten</td>
			</tr>
			<tr>
				<td className="px-3">ss</td>
				<td className="px-3">Seconden</td>
			</tr>
		</table>
	</div>;
}

function getValue(mapping, key, def = '') {
	if (mapping && key in mapping) {
		return mapping[key];
	}

	return def;
}

function MappingRow({ globalMappings, onMappingChange, partnerId, datasetId, mapping, downloadValueMappings }) {
	const value = getValue(mapping, 'mapped');
	const input = <Input type="text" readOnly value={mapping.field} />;
	return <tr key={mapping.id}>
		<td>
			{mapping.mapped && !mapping.encrypted ? <InputGroup>
				{input}
				<InputGroupAddon addonType="append">
					<Link className="btn btn-info" to={`/partners/${partnerId}/datasets/${datasetId}/${mapping.id}/mappings`}>Map kenmerken</Link>
				</InputGroupAddon>
			</InputGroup> : input}
		</td>

		<td>
			<InputAutocomplete
				items={globalMappings}
				value={value}
				onChange={e => onMappingChange(mapping, { mapped: e.target.value })}
				shouldItemRender={(item, val) => item.toLowerCase().includes(val.toLowerCase())}
			/>
		</td>

		<td className="text-center">
			<Button color="primary" disabled={!value} onClick={e => {
				e.preventDefault();
				downloadValueMappings(mapping);
			}}>
				<FontAwesomeIcon icon="file-download" />
			</Button>
		</td>
		<td className="text-center">
			<Button color="success" onClick={e => {
				e.preventDefault();
				onMappingChange(null, { field: mapping.field, insertAfter: mapping.id });
			}}>
				<FontAwesomeIcon icon="plus" />
			</Button>
		</td>

		<td>
			<Input
				type="text"
				value={getValue(mapping, 'date', '')}
				onChange={e => onMappingChange(mapping, { date: e.target.value })}
			/>
		</td>
		<td>
			<Input
				type="text"
				value={getValue(mapping, 'exclude', '')}
				onChange={e => onMappingChange(mapping, { exclude: e.target.value })}
			/>
		</td>
		<td>
			<Input
				min={0}
				type="number"
				value={getValue(mapping, 'trim', '')}
				onChange={e => {
					if(e.target.value === '') {
						onMappingChange(mapping, { trim: '' });
					} else if(!isNaN(e.target.value) && Number.isInteger(+e.target.value)) {
						onMappingChange(mapping, { trim: +e.target.value });
					}
				}}
			/>
		</td>
		<td className="text-center">
			<input
				type="checkbox"
				checked={getValue(mapping, 'encrypted', false)}
				onChange={e => onMappingChange(mapping, { encrypted: e.target.checked })}
			/>
		</td>
		<td className="text-center">
			<input
				type="checkbox"
				checked={getValue(mapping, 'lower', false)}
				onChange={e => onMappingChange(mapping, { lower: e.target.checked })}
			/>
		</td>
		<td className="text-center">
			<input
				type="checkbox"
				checked={getValue(mapping, 'strip', false)}
				onChange={e => onMappingChange(mapping, { strip: e.target.checked })}
			/>
		</td>
		<td className="text-center">
			<input
				type="checkbox"
				checked={getValue(mapping, 'emailDomain', false)}
				onChange={e => onMappingChange(mapping, { emailDomain: e.target.checked })}
			/>
		</td>
	</tr>;
}

function ExtraRow({ globalMappings, onMappingChange, mapping }) {
	return <tr key={mapping.id}>
		<td>
			<InputAutocomplete
				items={globalMappings}
				value={getValue(mapping, 'mapped')}
				onChange={e => onMappingChange(mapping, { mapped: e.target.value })}
				shouldItemRender={(item, val) => item.toLowerCase().includes(val.toLowerCase())}
			/>
		</td>
		<td className="text-center">
			<Button color="success" onClick={e => {
				e.preventDefault();
				onMappingChange(null, { type: 'extra', insertAfter: mapping.id });
			}}>
				<FontAwesomeIcon icon="plus" />
			</Button>
		</td>
		<td>
			<Input
				type="text"
				value={getValue(mapping, 'value', '')}
				onChange={e => onMappingChange(mapping, { value: e.target.value })}
			/>
		</td>
		<td className="text-center">
			<input
				type="checkbox"
				checked={getValue(mapping, 'encrypted', false)}
				onChange={e => onMappingChange(mapping, { encrypted: e.target.checked })}
			/>
		</td>
	</tr>;
}

function ColumnMappings({ datasetId, globalMappings, onMappingChange, partnerId, downloadValueMappings, items }) {
	return <Table borderless>
		<thead>
			<tr>
				<th colSpan={4} />
				<th className="text-center">Datum formaat</th>
				<th className="text-center">Filter</th>
				<th className="text-center">Inkorten</th>
				<th className="text-center">
					<FontAwesomeIcon icon="lock" title="Encrypt" />
				</th>
				<th className="text-center">
					<span title="Kleine letters">Aa</span>
				</th>
				<th className="text-center">
					<span title="Wituimte weghalen">_</span>
				</th>
				<th className="text-center">
					<span title="Domein splitsen">@</span>
				</th>
			</tr>
		</thead>
		<tbody>
			<PaginatorContainer
				name={datasetId}
				items={items}
				renderItem={mapping => <MappingRow
					globalMappings={globalMappings}
					onMappingChange={onMappingChange}
					partnerId={partnerId}
					datasetId={datasetId}
					mapping={mapping}
					downloadValueMappings={downloadValueMappings} />}
				maxDisplayItems={MAX_DISPLAY_ITEMS}
			/>
		</tbody>
	</Table>;
}

function ExtraColumns({ datasetId, globalMappings, onMappingChange, items }) {
	if(!items.length) {
		return <p>
			<Button color="success" onClick={e => {
				e.preventDefault();
				onMappingChange(null, { type: 'extra' });
			}}>
				<FontAwesomeIcon icon="plus" />
			</Button>
		</p>;
	}
	return <Table borderless>
		<thead>
			<tr>
				<th>Kolom</th>
				<th />
				<th>Vaste waarde</th>
				<th className="text-center">
					<FontAwesomeIcon icon="lock" title="Encrypt" />
				</th>
			</tr>
		</thead>
		<tbody>
			<PaginatorContainer
				name={`${datasetId}extra`}
				items={items}
				renderItem={mapping => <ExtraRow
					globalMappings={globalMappings}
					onMappingChange={onMappingChange}
					mapping={mapping} />}
				maxDisplayItems={MAX_DISPLAY_ITEMS}
			/>
		</tbody>
	</Table>;
}

function MappingEditor({ mappings, filters, message, messages, datasetId, onSubmit, partnerId, onRemapButtonClick, remapButtonEnabled = true, remapButtonText, globalMappings, onMappingChange, downloadValueMappings }) {
	const editorMessage = messages[EDITOR_MESSAGE];

	let filtered;
	const filter = filters.find(f => f.name === datasetId);
	if (filter?.value) {
		const pattern = makeRegExp(filter.value, 'i');
		filtered = mappings.filter(m => m.type === 'column' && pattern.test(m.field));
	} else {
		filtered = mappings.filter(m => m.type === 'column');
	}

	return <div id="dataset-header-editor">
		<Container>
			<h1>Map bestanden naar bekende kolommen</h1>

			{editorMessage && <Alert message={editorMessage} />}
			{message && <Alert message={message} />}
		</Container>

		<Container className="text-left" fluid>
			<FilterContainer name={datasetId} />

			<Form onSubmit={onSubmit}>
				<h2>Bestandskolommen</h2>
				<ColumnMappings
					datasetId={datasetId}
					globalMappings={globalMappings}
					onMappingChange={onMappingChange}
					partnerId={partnerId}
					downloadValueMappings={downloadValueMappings}
					items={filtered} />
				<h2>Vaste kolommen</h2>
				<ExtraColumns datasetId={datasetId} globalMappings={globalMappings} onMappingChange={onMappingChange} items={mappings.filter(m => m.type === 'extra')} />

				<FormGroup>
					<Button color="primary" type="submit">Bewaar</Button>
					<Link className="btn btn-info ml-2" to={`/partners/${partnerId}/datasets`}>Terug naar bestanden</Link>
					<Button className="ml-2" color="info" onClick={onRemapButtonClick} disabled={!remapButtonEnabled}>{remapButtonText}</Button>
				</FormGroup>

			</Form>
			<DateFormatToolTip />
		</Container>
	</div>;
}

export default withMessages(MappingEditor);
