import {PropTypes} from 'prop-types';

export const campaignPropTypes = PropTypes.shape({
	addresses: PropTypes.number,
	budget: PropTypes.number,
	customer: PropTypes.string,
	mailings: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.number,
	]),
	name: PropTypes.string,
	recordCount: PropTypes.number,
	start: PropTypes.string,
	type: PropTypes.string,
	_id: PropTypes.string,
});
