import React, { Component, Fragment } from 'react';
import { Container, Form, FormGroup, Row, Col, Input, Button, Label, ButtonGroup } from 'reactstrap';
import InputAutocomplete from '../Autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Papa from 'papaparse';

const BASE_MAPPINGS = {
	id: 'id',
	dataset: 'ns',
	datasetDescription: 'ns',
	domain: 'domain'
};

export default class IntersectEditor extends Component {
	constructor(props) {
		super(props);
		const state = {
			file: null,
			showLoader: false,
			mappings: []
		};
		if(props.line) {
			state.file = props.line._file || null;
			if(state.file) {
				state.mappings = props.line._mappings;
			}
		}
		this.state = state;
	}

	setMapping(i, key, value) {
		const mappings = this.state.mappings.slice();
		const mapping = Object.assign({}, mappings[i]);
		mapping[key] = value;
		if(key === 'encrypted') {
			delete mapping.type;
		}
		mappings[i] = mapping;
		this.setState({ mappings });
	}

	renderMappingForm() {
		if (this.state.showLoader) {
			return <div className="text-center">
				<hr />
				<FontAwesomeIcon icon="cog" spin size="2x" /> CSV analyseren, een moment geduld...
			</div>;
		}
		const { mappings } = this.state;
		if (mappings.length === 0) {
			return null;
		}
		let mapped = false;
		return <Fragment>
			<hr />

			<h2>Found headers:</h2>

			<Form onSubmit={this.onSubmit}>
				{mappings.map((header, i) => {
					if(header.mapped) {
						mapped = true;
					} else if(header.type === 'base' && header.base) {
						mapped = true;
					}
					return <Row key={i} className="mb-3">
						<Col>
							<Input type="text" readOnly value={header.field} />
						</Col>

						<Col>
							{header.type === 'base' ? <Row>
								<Col>
									<Input type="select" value={header.base || ''} onChange={e => this.setMapping(i, 'base', e.target.value)}>
										<option value=""></option>
										<option value="id">Solar Id</option>
										<option value="dataset">Bestand Id</option>
										<option value="datasetDescription">Bestandsomschrijving</option>
										<option value="domain">Domain</option>
									</Input>
								</Col>
								{header.base === 'datasetDescription' && <Col>
									Partner Id
									<Input type="select" value={header.basePartner || ''} onChange={e => this.setMapping(i, 'basePartner', e.target.value)}>
										<option value=""></option>
										{mappings.map(({ field }) => <option value={field} key={field}>{field}</option>)}
									</Input>
								</Col>}
							</Row> : <InputAutocomplete
								items={this.props.attributes || []}
								value={header.mapped}
								onChange={e => this.setMapping(i, 'mapped', e.target.value)}
								shouldItemRender={(item, val) => item.toLowerCase().includes(val.toLowerCase())}
							/>}
						</Col>
						<Col>
							<ButtonGroup className="d-block">
								<Button disabled={!header.mapped && !header.type} color={header.encrypted && !header.type ? 'success' : 'light'} onClick={() => this.setMapping(i, 'encrypted', true)}>Encrypted</Button>
								<Button disabled={!header.mapped && !header.type} color={header.encrypted || header.type ? 'light' : 'danger'} onClick={() => this.setMapping(i, 'encrypted', false)}>Unencrypted</Button>
								<Button color={header.type ? 'secondary' : 'light'} onClick={() => this.setMapping(i, 'type', 'base')}>Special</Button>
							</ButtonGroup>
						</Col>
					</Row>;
				})}

				<FormGroup>
					<Button type="submit" color="primary" disabled={!mapped}>Submit</Button>
				</FormGroup>
			</Form>
		</Fragment>;
	}

	checkCsv = e => {
		e.preventDefault();
		this.setState({ showLoader: true });

		Papa.parse(this.state.file, {
			preview: 1,
			header: true,
			complete: res => {
				this.setState({
					showLoader: false,
					mappings: res.meta.fields.map(field => {
						return {
							field, mapped: '', encrypted: false
						};
					})
				});
			},
		});
	};

	onSubmit = e => {
		e.preventDefault();
		const mult = 1e6;
		const { mappings, file } = this.state;
		const baseMappings = [];
		const mappedMappings = [];
		const fieldNames = new Set();
		mappings.forEach(m => {
			if(m.type === 'base') {
				const { base, basePartner, field, generated, type } = m;
				if(base) {
					baseMappings.push({ base, basePartner, field, generated, type });
				}
			} else {
				const { field, encrypted } = m;
				const mapped = m.mapped.trim();
				if(mapped) {
					fieldNames.add(mapped);
					mappedMappings.push({ field, encrypted, mapped });
				}
			}
		});
		const fields = [...fieldNames];
		baseMappings.forEach(m => {
			if(!m.generated) {
				m.generated = `gen_${m.base}`;
			}
			while(fieldNames.has(m.generated)) {
				m.generated += '0';
			}
			fieldNames.add(m.generated);
			fields.push({ base: BASE_MAPPINGS[m.base], mapped: m.generated });
		});
		this.props.edit({
			[this.props.type]: {
				id: this.props.line ? this.props.line.id: Math.floor(Math.random() * mult),
				_mappings: baseMappings.concat(mappedMappings),
				_file: file,
				fields,
				file: file.name
			}
		});
	};

	render() {
		return <Container id="csvUpload">
			<h1>Upload a CSV file</h1>

			<Form onSubmit={this.checkCsv}>
				<FormGroup row>
					<Col>
						<Label>File</Label>
						<input className="d-block" type="file" onChange={e => this.setState({ file: e.target.files[0] })} accept=".csv" />

						{this.props.line && this.state.file && <p>Currently editing <strong>{this.state.file.name}</strong></p>}
					</Col>
				</FormGroup>

				<FormGroup>
					<Button type="submit" color="primary" disabled={!this.state.file}>Check CSV</Button>
				</FormGroup>
			</Form>

			{this.renderMappingForm()}
		</Container>;
	}
}
