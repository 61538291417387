import React, { Component, Fragment } from 'react';
import {FormGroup, Col, Input, Label} from 'reactstrap';
import { MailingState } from '../../enum/MailingState';
import SelectCampaign from './SelectCampaign';

export default class CampaignMailingSelector extends Component {
	renderMailingDropdown() {
		const { campaign, mailing, setMailing } = this.props;
		if (!campaign) {
			return <span>Selecteer eerst een campagne</span>;
		}
		const currentValue = mailing && mailing._id || '';
		return <Input type="select" value={currentValue} onChange={e => setMailing(e.target.value)}>
			<option value="" disabled>Selecteer een mailing</option>
			{campaign.mailings.filter(m => m.state === MailingState.DRAFT).map(m => {
				return <option key={m._id} value={m._id}>{m.name}</option>;
			})}
		</Input>;
	}

	render() {
		const { campaigns, campaign, setCampaign, campaignDays, setCampaignDays } = this.props;
		return <Fragment>
			<FormGroup row>
				<Label xs={12} lg={2}>
					Campagne
				</Label>
				<Col xs={12} lg={4}>
					<SelectCampaign
						setDays={setCampaignDays}
						days={campaignDays}
						campaigns={campaigns}
						value={campaign && campaign._id || ''}
						onChange={setCampaign} />
				</Col>
			</FormGroup>

			<FormGroup row>
				<Label xs={12} lg={2}>
					Mailing
				</Label>
				<Col xs={12} lg={4}>
					{this.renderMailingDropdown()}
				</Col>
			</FormGroup>
		</Fragment>;
	}
}
