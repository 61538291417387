function simpleKey(args) {
	return `${args.length} ${args.join(',')}`;
}

export default function memoize(func, makeKey = simpleKey, cache = Object.create(null)) {
	return async (...args) => {
		const key = makeKey(args);
		if(cache[key]) {
			return cache[key];
		}
		cache[key] = func(...args);
		try {
			return await cache[key];
		} finally {
			delete cache[key];
		}
	};
}
