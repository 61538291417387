import React from 'react';
import {connect} from 'react-redux';
import * as api from '../api';
import {clearCampaign, setCampaign, setCampaigns} from '../redux/actions/campaigns';

/*
Add these to static propTypes in child component:

fetchCampaigns: PropTypes.func.isRequired,
fetchCampaign: PropTypes.func.isRequired,
clearCampaign: PropTypes.func.isRequired,
fetchCampaignsError: PropTypes.string,
fetchCampaignError: PropTypes.string,
 */

export const withCampaigns = BaseComponent => connect()(class ComponentWithCampaigns extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			fetchCampaignsError: null,
			fetchCampaignError: null,
		};
	}

	componentDidMount() {
		const {campaignId} = this.props.match.params;

		this.fetchCampaigns();

		if (campaignId !== undefined && campaignId !== null) {
			this.fetchCampaign(campaignId);
		}
	}

	componentDidUpdate(prevProps) {
		const currentCampaignId = this.props.match.params.campaignId;
		const prevCampaignId = prevProps.match.params.campaignId;

		if (currentCampaignId !== null && currentCampaignId !== undefined && currentCampaignId !== prevCampaignId) {
			this.fetchCampaign(currentCampaignId);
		}
	}

	fetchCampaign = campaignId => {
		return api.fetchCampaign(campaignId).then(campaign => {
			this.props.dispatch(setCampaign(campaign));
		}).catch(err => {
			this.setState({
				fetchCampaignError: err.response.data.message,
			});
		});
	};

	fetchCampaigns = () => {
		return api.fetchCampaigns().then(campaigns => {
			this.props.dispatch(setCampaigns(campaigns));
		}).catch(err => {
			this.setState({
				fetchCampaignsError: err.response.data.message,
			});
		});
	};

	clearCampaign = () => {
		this.props.dispatch(clearCampaign());
	};

	render() {
		return <BaseComponent
			{...this.props}
			{...this.state}
			fetchCampaigns={this.fetchCampaigns}
			fetchCampaign={this.fetchCampaign}
			clearCampaign={this.clearCampaign}
		/>;
	}
});
