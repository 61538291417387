import React from 'react';
import {Button, Form, FormGroup, Input, Label} from 'reactstrap';
import {withFormUtils} from '../../hoc/with-form-utils';

class TypeForm extends React.Component {
	componentDidMount() {
		const {setDefaultValue, addTrimmedFields} = this.props;

		setDefaultValue('type', '');
		addTrimmedFields('type');
	}

	render() {
		const {getdata, onInputChange, onFormSubmit} = this.props;

		return <Form onSubmit={onFormSubmit}>
			<FormGroup>
				<Label>Type:</Label>
				<Input type="text" name="type" value={getdata('type')} onChange={onInputChange} />
			</FormGroup>

			<FormGroup>
				<Button type="submit" color="primary">Submit</Button>
			</FormGroup>
		</Form>;
	}
}

export default withFormUtils(TypeForm);
