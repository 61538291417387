import React, { Component, Fragment } from 'react';
import { Col, Container, Button, Nav, NavItem, NavLink, TabContent, TabPane, FormGroup, Input, Label } from 'reactstrap';
import Alert from '../alert/Alert';
import CampaignMailingSelector from './CampaignMailingSelector';
import SelectionSettings from './SelectionSettings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import QueryResult from './QueryResult';
import elements from './tokens/elements';
import { withPrompts } from '../../hoc/with-prompts';
import { QueryBuilderState } from '../../enum/QueryBuilderState';
import QueryGroup from './QueryGroup';

export function parseQuery(token, edit, editLine) {
	for(const key of Object.keys(token)) {
		const type = elements[key];
		if(!type) {
			if(key.startsWith('_')) {
				throw new Error(`Unknown token type ${key}`);
			}
			continue;
		}
		return React.createElement(type, {
			token: token[key],
			edit, editLine
		});
	}
	return null;
}

function QueryState({ state }) {
	switch (state) {
		case QueryBuilderState.SAVED:
			return <span><FontAwesomeIcon icon="check" /> Saved</span>;
		case QueryBuilderState.DONE:
			return <span><FontAwesomeIcon icon="check" /> Done</span>;
		case QueryBuilderState.NOT_SAVED:
			return <span><FontAwesomeIcon icon="times" /> Failed to save</span>;
		case QueryBuilderState.SAVING:
			return <span><FontAwesomeIcon icon="sync" spin /> Saving...</span>;
		case QueryBuilderState.PERFORMING_QUERY:
			return <span><FontAwesomeIcon icon="sync" spin /> Performing query...</span>;
		default:
			return null;
	}
}

class QueryBuilder extends Component {
	state = {
		preDefined: '',
		tab: 'result'
	};

	renderPredefinedQueries() {
		const maxLength = 65;
		const { preDefined } = this.state;
		const { preDefinedQueries } = this.props;
		return <FormGroup row>
			<Label xs={12} lg={2}>Selectie template</Label>
			<Col xs={12} lg={4}>
				<Input type="select" value={preDefined} onChange={this.selectPredefinedQuery}>
					<option value="">Lege selectie</option>
					{preDefinedQueries.map(campaign => {
						const selection = campaign.mailings.selections;
						return <option key={selection._id} value={selection._id}>
							{selection.description ? selection.description.substring(0, maxLength) : selection._id}
						</option>;
					})}
				</Input>
			</Col>
		</FormGroup>;
	}

	selectPredefinedQuery = e => {
		const { preDefinedQueries, edit } = this.props;
		const selection = preDefinedQueries.find(c => c.mailings.selections._id === e.target.value);
		if(selection) {
			const campaign = selection._id;
			const mailing = selection.mailings._id;
			const preDefined = selection.mailings.selections._id;
			this.setState({ preDefined });
			edit(selection.mailings.selections.query, campaign, mailing, preDefined);
		} else {
			this.setState({ preDefined: '' });
		}
	};

	renderQueryResult() {
		const { result, campaign, mailing, selection, saved, prompt, mailingState, saveMailing, setResultLine, redistribute, applyFactor, state } = this.props;
		const { tab } = this.state;
		if (!result.query) {
			return null;
		}
		return <Fragment>
			<hr />

			<Nav tabs>
				<NavItem>
					<NavLink
						className={tab === 'result' ? 'active' : null}
						onClick={() => this.setState({ tab: 'result' })}>
						Resultaten
					</NavLink>
				</NavItem>

				<NavItem>
					<NavLink
						className={tab === 'query' ? 'active' : null}
						onClick={() => this.setState({ tab: 'query' })}>
						Query
					</NavLink>
				</NavItem>
			</Nav>

			<TabContent activeTab={tab}>
				<TabPane tabId="result" className="pt-3">
					<QueryResult
						result={result.result}
						campaign={campaign}
						mailing={mailing || {}}
						selection={selection}
						prompt={prompt}
						mailingState={mailingState}
						saveMailing={saveMailing}
						setResultLine={setResultLine}
						redistribute={redistribute}
						applyFactor={applyFactor}
						state={state}
						saved={saved} />
				</TabPane>

				<TabPane tabId="query" className="pt-3">
					<h2>Solr Query</h2>
					<textarea className="form-control" readOnly onFocus={e => e.target.select()} style={{
						minHeight: '250px',
					}} value={result.query || ''} />
					<textarea className="form-control" readOnly onFocus={e => e.target.select()} style={{
						minHeight: '250px',
					}} value={result.saveQuery || ''} />
				</TabPane>
			</TabContent>
		</Fragment>;
	}

	render() {
		const {
			edit, query, lastMessage, setSetting,
			campaigns, campaign, setCampaign,
			mailing, setMailing, preDefinedQueries,
			settings, editLine, canSubmit,
			selection, state, executeQuery,
			traverseQuery, campaignDays, setCampaignDays
		} = this.props;
		return <Container fluid className="query-builder pb-5">
			<h1>Doelgroep selecteren</h1>
			{lastMessage && <Container className="mt-2">
				<Alert message={lastMessage} />
			</Container>}
			<CampaignMailingSelector
				campaignDays={campaignDays}
				setCampaignDays={setCampaignDays}
				campaign={campaign}
				mailing={mailing}
				setCampaign={setCampaign}
				setMailing={setMailing}
				campaigns={campaigns} />
			{preDefinedQueries.length > 0 && this.renderPredefinedQueries()}
			<QueryGroup query={query} edit={edit} editLine={editLine} traverseQuery={traverseQuery} />
			<hr />
			<SelectionSettings {...settings}
				campaignDays={campaignDays}
				setCampaignDays={setCampaignDays}
				campaigns={campaigns}
				setSetting={setSetting} />
			<Button className="mr-2" color="info" onClick={() => this.props.prompt({
				title: 'Nieuwe query?',
				text: 'Weet je zeker dat je een nieuwe query wilt maken?',
				onConfirm: this.props.reset
			})}>Nieuwe query</Button>
			<Button id="saveQuery" className="mr-2" color="primary" onClick={executeQuery} disabled={!canSubmit}>
				{selection ? 'Bewaar' : 'Maak aan'}
			</Button>
			<QueryState state={state} />
			{this.renderQueryResult()}
		</Container>;
	}
}

export default withPrompts(QueryBuilder);
