import React from 'react';
import { MDBProgress } from 'mdbreact';
import moment from 'moment';

const DONE = 100;
const RESULTS = {
	read: 'Gevonden',
	saved: 'Opgeslagen',
	skipped: 'Overgeslagen',
	deleted: 'Verwijderd',
	unchanged: 'Onveranderd'
};

function getClassName(state) {
	let styleName = 'card mb-3 p-3 ';

	if(state === 'done') {
		styleName += 'alert-success';
	} else if (state === 'parsing' || state === 'saving' || state === 'updating' || state === 'executing') {
		styleName += 'alert-primary';
	} else if (state === 'failed') {
		styleName += 'alert-danger';
	} else {
		styleName += 'alert-dark';
	}

	return styleName;
}

export function ProgressBar({ percentage, state }) {
	if(percentage >= DONE) {
		return null;
	}
	return <MDBProgress material value={percentage} height="20px" striped>
		{Math.round(percentage)}% ({state})
	</MDBProgress>;
}

function displayError(err) {
	if(err) {
		return <strong>({err.message})</strong>;
	}
	return null;
}

function displayUploadInformation(result) {
	if(result && result.read) {
		const stats = [];
		Object.keys(RESULTS).forEach(key => {
			if(key in result) {
				stats.push(`${RESULTS[key]}: ${result[key]}`);
			}
		});
		return <small>{stats.join(' | ')}</small>;
	}
	return null;
}

export default function Task(props) {
	return <div className={getClassName(props.state)}>
		<small>
			{moment(props.date).format('DD-MM-YYYY HH:mm:ss')}{props.date ? ' | ' : ''}
			<strong>{props.type}</strong>{props.partner || props.dataset ? ' | ' : ''}
			{props.partner}{props.dataset ? ' | ' : ''}
			{props.dataset}
		</small>

		<table>
			<tbody>
				{props.username ? <tr><td><small>Gebruiker</small></td><td className="pl-2"><small>{props.username}</small></td></tr> : <tr><td></td></tr>}
				{props.state ? <tr><td><small>Status</small></td><td className="pl-2"><small>{props.state} {displayError(props.error)}</small></td></tr> : <tr><td></td></tr>}
				{props.file ? <tr><td><small>Bestandsnaam</small></td><td className="pl-2"><small>{props.file}</small></td></tr> : <tr><td></td></tr>}
				{props.id ? <tr><td><small>Taak ID</small></td><td className="pl-2"><small>{props.id}</small></td></tr> : <tr><td></td></tr>}
			</tbody>
		</table>
		{displayUploadInformation(props.result)}
		<ProgressBar percentage={props.percentage} state={props.state} />
	</div>;
}
