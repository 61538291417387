const baseURL = 'https://solarii-custom.omg.nl';
const stylesheets = {
	staging: 'solarii-preview-override.css',
	production: 'solarii-override.css',
};
const stylesheetId = 'solarii-css-override';

function getEnvironment() {
	const {hostname} = window.location;

	if (hostname === 'localhost') {
		return 'dev';
	} else if (/solarii-preview.nl/.test(hostname)) {
		return 'staging';
	} else if (/solar.omgdmp.nl/.test(hostname)) {
		return 'production';
	}

	return 'unknown';
}

function getStylesheet() {
	const environment = getEnvironment();

	console.log('[CustomStyleInjector.getStylesheet] Found environment:', environment);

	return stylesheets[environment];
}

export function inject() {
	if (document.getElementById(stylesheetId)) {
		console.log('[CustomStyleInjector.inject] Custom style has already been injected');
		return;
	}

	const stylesheet = getStylesheet();

	if (!stylesheet) {
		console.log('[CustomStyleInjector.inject] No stylesheet found for this domain');
		return;
	}

	const styleURL = `${baseURL}/${stylesheet}`;

	const link = document.createElement('link');
	link.rel = 'stylesheet';
	link.href = styleURL;
	link.id = stylesheetId;

	document.head.appendChild(link);
	console.log('[CustomStyleInjector.inject] Injected custom style');
}

export function remove() {
	const link = document.getElementById(stylesheetId);
	if(link) {
		link.remove();
	}
}
