import React from 'react';
import PropTypes from 'prop-types';
import {Container} from 'reactstrap';
import Alert from '../alert/Alert';
import CampaignForm from './CampaignForm';
import Message from '../../messages/Message';

export default class NewCampaign extends React.Component {
	static propTypes = {
		onSubmit: PropTypes.func.isRequired,
		lastMessage: PropTypes.instanceOf(Message),
		types: PropTypes.array,
		fetchTypes: PropTypes.func.isRequired,
	};

	componentDidMount() {
		this.props.fetchTypes();
	}

	render() {
		return <div id="newCampaign">
			<Container>
				<h1>Nieuwe campagne</h1>

				{this.props.lastMessage && <Alert message={this.props.lastMessage} />}

				<CampaignForm onSubmit={this.props.onSubmit} types={this.props.types} />
			</Container>
		</div>;
	}
}
