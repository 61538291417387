const DEFAULT_POLL_TIME = 5000;

export class Poller {
	watchers = [];
	promiseFunc = null;
	timeoutId = null;
	pollTime = 0;

	constructor(promiseFunc, pollTime = DEFAULT_POLL_TIME) {
		this.promiseFunc = promiseFunc;
		this.pollTime = pollTime;
	}

	poll = () => {
		return this.promiseFunc()
			.then(data => {
				const promises = this.watchers.filter(watcher => watcher.matchProp(data)).map(watcher => watcher.onMatch(data));
				if(!['done', 'failed'].includes(data.state)) {
					promises.push(new Promise(resolve => {
						setTimeout(resolve, this.pollTime);
					}).then(this.poll));
				}
				return Promise.all(promises);
			});
	}

	start = () => {
		return this.poll();
	}

	stop = () => {
		if (this.timeoutId !== null) {
			clearTimeout(this.timeoutId);
		}

		return this;
	}

	/**
	 * Add a watcher
	 *
	 * @param {Function} matchProp
	 * @param {Function} onMatch
	 */
	addWatcher = (matchProp, onMatch) => {
		this.watchers.push({
			matchProp,
			onMatch,
		});

		return this;
	}

}

export function getPollerData(poller) {
	const promise = new Promise((resolve) => {
		poller.addWatcher(data => data.state === 'done', data => resolve(data));
		poller.addWatcher(data => data.state === 'failed', data => Promise.reject(new Error(data.message)));
	});
	return poller.start().then(() => promise);
}

export function getData(promiseFunc, pollTime) {
	const poller = new Poller(promiseFunc, pollTime);
	return getPollerData(poller);
}

export function getResult(promiseFunc, pollTime) {
	return getData(promiseFunc, pollTime).then(data => data.result);
}
