import React from 'react';
import {FormGroup, Col, Button, Form, Input} from 'reactstrap';
import Select from 'react-select';

export default class InterestTypePicker extends React.Component {
	constructor(props) {
		super(props);
		const state = {
			selectedOptions: [],
			selectedType: 'click'
		};
		const { line } = props;
		if(line) {
			state.selectedType = Object.keys(line)[0] || state.selectedType;
			state.selectedOptions = (line[state.selectedType] || []).map(value => {
				return {
					value, label: value
				};
			});
		}
		this.state = state;
	}

	getOptions() {
		return this.props.types.map(t => ({ value: t, label: t }));
	}

	onSubmit = e => {
		e.preventDefault();
		const values = this.state.selectedOptions.map(o => o.value);
		if(values.length) {
			this.props.edit({
				interests: {
					[this.state.selectedType]: values
				}
			});
		}
	};

	render() {
		return <div>
			<h1>Filter op interesse type</h1>

			<Form onSubmit={this.onSubmit}>
				<FormGroup row>
					<Col xs={12} lg={2} className="d-flex align-items-center">
						Type
					</Col>

					<Col xs={12} lg={4}>
						<Input type="select" onChange={e => this.setState({ selectedType: e.target.value })} value={this.state.selectedType}>
							<option value="click">Klik</option>
							<option value="open">Open</option>
						</Input>
					</Col>
				</FormGroup>

				<FormGroup row>
					<Col xs={12} lg={2} className="d-flex align-items-center">
						Interesse
					</Col>

					<Col xs={12} lg={4}>
						<Select
							options={this.getOptions()}
							value={this.state.selectedOptions}
							onChange={selectedOptions => this.setState({ selectedOptions })}
							isMulti
							isSearchable
						/>
					</Col>
				</FormGroup>

				<FormGroup>
					<Button type="submit" color="primary" disabled={this.state.selectedOptions.length < 1}>{this.props.line ? 'Opslaan' : 'Toevoegen'}</Button>
				</FormGroup>
			</Form>
		</div>;
	}
}
