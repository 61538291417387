import React from 'react';
import PropTypes from 'prop-types';
import { Container, Form, Label, Input, FormText, FormGroup, Button } from 'reactstrap';
import Papa from 'papaparse';

import Alert from '../alert/Alert';

import { withMessages } from '../../hoc/with-messages';

const MESSAGE = 'MESSAGE';

class BlacklistUpload extends React.Component {
	static propTypes = {
		submitEmailToBlacklist: PropTypes.func.isRequired,
		submitDomainToBlacklist: PropTypes.func.isRequired,
	}

	state = {
		file: null,
		domainFile: null,
		datasetFile: null,
		buttonEnabled: true,
	};

	onEmailSubmit = e => {
		e.preventDefault();

		if (this.canSubmit()) {
			this.props.clearMessage(MESSAGE);

			this.setState({
				buttonEnabled: false,
			});

			Papa.parse(this.state.file, {
				preview: 1,
				complete: res => {
					const {data} = res;
					if (data[0].includes('email')) {
						this.props.submitEmailToBlacklist(this.state.file, 'email')
							.catch(() => {
								this.setState({
									buttonEnabled: true,
								});
							});
						return this.props.setMessage(MESSAGE, 'Upload gestart', 'Het bestand wordt geupload', {
							color: 'success',
						});
					}
					this.props.setMessage(MESSAGE, 'Ongeldig bestand', 'Het bestand is fout of bevat geen kolom genaamd email', {
						color: 'danger',
					});
				},
			});
		}
	}

	onDomainSubmit = e => {
		e.preventDefault();

		if(this.canSubmitDomain()){
			this.props.clearMessage(MESSAGE);

			this.setState({
				buttonEnabled: false,
			});

			Papa.parse(this.state.domainFile, {
				complete: res => {
					const {data} = res;
					if(data[0].includes('domein')) {
						this.props.submitDomainToBlacklist(this.state.domainFile, 'domein')
							.catch(() => {
								this.setState({
									buttonEnabled: true,
								});
							});
						return this.props.setMessage(MESSAGE, 'Upload gestart', 'Het bestand wordt geupload', {
							color: 'success',
						});
					}
					this.props.setMessage(MESSAGE, 'Ongeldig bestand', 'Het bestand is fout of bevat geen kolom genaamd domein', {
						color: 'danger',
					});
				}
			});
		}
	}

	onDatasetSubmit = e => {
		e.preventDefault();

		if(this.canSubmitDataset()){
			this.props.clearMessage(MESSAGE);

			this.setState({
				buttonEnabled: false,
			});

			Papa.parse(this.state.datasetFile, {
				complete: res => {
					const {data} = res;
					if(data[0].includes('email') && data[0].includes('bestand')) {
						this.props.submitPartnerBlacklistCSV(this.state.datasetFile, 'email', 'partner', 'bestand')
							.catch(() => {
								this.setState({
									buttonEnabled: true,
								});
							});
						return this.props.setMessage(MESSAGE, 'Upload gestart', 'Het bestand wordt geupload', {
							color: 'success',
						});
					}
					this.props.setMessage(MESSAGE, 'Ongeldig bestand', 'Het bestand is fout of bevat niet alle verplichte kolommen', {
						color: 'danger',
					});
				}
			});
		}
	}

	canSubmit = () => this.state.buttonEnabled && this.state.file !== null;
	canSubmitDomain = () => this.state.buttonEnabled && this.state.domainFile !== null;
	canSubmitDataset = () => this.state.buttonEnabled && this.state.datasetFile !== null;

	render = () => <Container>
		<h1 className="mb-4">Upload CSV's voor de blacklist</h1>
		{this.props.lastMessage && <Alert message={this.props.lastMessage} />}
		{this.props.messages[MESSAGE] && <Alert message={this.props.messages[MESSAGE]} />}

		<h3>Blacklist domeinen</h3>
		<Form onSubmit={this.onDomainSubmit}>
			<FormGroup>
				<label>Domeinen CSV:</label>
				<Input type="file" onChange={e => this.setState({ domainFile: e.target.files[0], buttonEnabled: true })} />
				<FormText>Let op dat deze CSV een kolom moet bevatten genaamd <strong>domein</strong></FormText>
			</FormGroup>
			<FormGroup>
				<Button className="mb-4" color="primary" disabled={!this.canSubmitDomain()}>Verstuur</Button>
			</FormGroup>
		</Form>

		<hr className="my-4" />

		<h3>Blacklist e-mailadressen</h3>
		<Form onSubmit={this.onEmailSubmit}>
			<FormGroup>
				<Label>E-mailadres CSV:</Label>
				<Input type="file" onChange={e => this.setState({ file: e.target.files[0], buttonEnabled: true })} />
				<FormText>Let op dat deze CSV een kolom moet bevatten genaamd <strong>email</strong></FormText>
			</FormGroup>
			<FormGroup>
				<Button color="primary" disabled={!this.canSubmit()}>Verstuur</Button>
			</FormGroup>
		</Form>

		<hr className="my-4" />

		<h3>Blacklist bestanden</h3>
		<Form onSubmit={this.onDatasetSubmit}>
			<FormGroup>
				<Label>E-mailadres CSV:</Label>
				<Input type="file" onChange={e => this.setState({ datasetFile: e.target.files[0], buttonEnabled: true })} />
				<FormText>Let op dat deze CSV de kolommen <strong>email</strong> en <strong>bestand</strong> moet bevatten</FormText>
			</FormGroup>
			<FormGroup>
				<Button color="primary" disabled={!this.canSubmitDataset()}>Verstuur</Button>
			</FormGroup>
		</Form>
	</Container>;
}

export default withMessages(BlacklistUpload);
