import React, { Component } from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText, Table } from 'reactstrap';

const COLS = ['new', 'notOpenedOrClicked', 'opens', 'clicks'];
const KEY_LEFT = 37;
const KEY_RIGHT = 39;
const KEY_UP = 38;
const KEY_DOWN = 40;

function Column({ readOnly, value, type, onChange, onKeyDown, setRef }) {
	return <td>
		<InputGroup>
			<Input
				type="text"
				max={value.original[type]}
				value={value.modified[type]}
				readOnly={readOnly}
				onChange={onChange}
				onKeyDown={onKeyDown}
				innerRef={setRef}
			/>
			<InputGroupAddon addonType="append">
				<InputGroupText>/ {value.original[type]}</InputGroupText>
			</InputGroupAddon>
		</InputGroup>
	</td>;
}

export default class SolrQueryResultTable extends Component {
	refs = [];

	createKeyHandler(y, type) {
		const x = COLS.indexOf(type);
		return e => {
			let ref;
			if(e.keyCode === KEY_LEFT) {
				if(e.target.selectionStart === 0 && x > 0) {
					ref = this.refs[y][COLS[x - 1]];
				}
			} else if(e.keyCode === KEY_RIGHT) {
				if(e.target.selectionStart === e.target.value.length && x < COLS.length - 1) {
					ref = this.refs[y][COLS[x + 1]];
				}
			} else if(e.keyCode === KEY_UP) {
				if(y > 1) {
					ref = this.refs[y - 1][type];
				}
			} else if(e.keyCode === KEY_DOWN) {
				if(y < this.refs.length - 1) {
					ref = this.refs[y + 1][type];
				}
			}
			if(ref) {
				e.preventDefault();
				ref.focus();
			}
		};
	}

	setRef(i, type) {
		return ref => {
			if(this.refs.length !== this.props.result.length) {
				this.refs = Array(this.props.result.length);
			}
			this.refs[i] = this.refs[i] || {};
			this.refs[i][type] = ref;
		};
	}

	render() {
		const { result, aggregate, setResultLine, selectColumn, selectedColumns } = this.props;
		return <Table hover size="sm" className="query-result-table">
			<thead>
				<tr>
					<th colSpan={2}>Volgorde</th>
					<th>Naam</th>
					<th className="text-center">Extra inzet</th>
					<th>
						Nieuw
						<Input className="ml-3" type="checkbox" value="new" checked={selectedColumns.new} onChange={selectColumn} />
					</th>
					<th>
						Inactief
						<Input className="ml-3" type="checkbox" value="notOpenedOrClicked" checked={selectedColumns.notOpenedOrClicked} onChange={selectColumn} />
					</th>
					<th>
						Opens
						<Input className="ml-3" type="checkbox" value="opens" checked={selectedColumns.opens} onChange={selectColumn} />
					</th>
					<th>
						Kliks
						<Input className="ml-3" type="checkbox" value="clicks" checked={selectedColumns.clicks} onChange={selectColumn} />
					</th>
					<th>Totaal</th>
				</tr>
			</thead>

			<tbody>
				<tr>
					<td colSpan={2} />
					<td colSpan={2}>Totalen</td>
					{COLS.map(type => <Column key={type} type={type} value={aggregate} readOnly />)}
					<Column type="total" value={aggregate} readOnly />
				</tr>

				{result.map((line, i) => <tr key={i}>
					<td>
						{!line.saved && <input type="checkbox" checked={line.selected} onChange={e => setResultLine(i, 'selected', e)} />}
					</td>
					<td>
						<Input
							type="text"
							value={line.sort}
							readOnly
						/>
					</td>
					<td className="dataset">{line.saved ? 'Reeds opgeslagen' : line.dataset.name || 'Onbekend'}</td>
					<td className="text-center">{line.dataset ? line.dataset.factor : ''}</td>
					{COLS.map(type => <Column
						key={type}
						type={type}
						value={line}
						readOnly={line.saved}
						setRef={this.setRef(i, type)}
						onChange={e => setResultLine(i, type, e)}
						onKeyDown={this.createKeyHandler(i, type)} />)}
					<Column type="total" value={line} readOnly />
				</tr>)}
			</tbody>
		</Table>;
	}
}
