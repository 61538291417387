import React from 'react';
import PropTypes from 'prop-types';
import {Pagination, PaginationItem as PItem, PaginationLink as PLink} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getClassName from 'classnames';

export default function Paginator(props) {
	const {itemCount, maxDisplayItems, currentPage, onPaginatorPageClick, maxPaginatorButtons, centered} = props;
	const pageCount = Math.ceil(itemCount / maxDisplayItems);

	if (pageCount <= 1) {
		return null; // Paginating 1 or less pages doesn't make sense
	}

	const prevEnabled = currentPage > 1;
	const nextEnabled = currentPage < pageCount;

	// eslint-disable-next-line no-magic-numbers
	const idealButtonsOnEachSide = (maxPaginatorButtons - 1) / 2;
	const start = Math.max(0,
		Math.min(pageCount - maxPaginatorButtons,
			Math.max(0, currentPage - (idealButtonsOnEachSide + 1))
		)
	);

	const range = Array(Math.min(maxPaginatorButtons, pageCount)).fill(0).map((_, i) => start + i);

	return <Pagination className={getClassName(
		'mt-2',
		{
			'd-flex': centered,
			'justify-content-center': centered,
		}
	)}>
		<PItem disabled={!prevEnabled}>
			<PLink id="fast-backward" onClick={e => onPaginatorPageClick(e, 1)}>
				<FontAwesomeIcon icon="fast-backward" />
			</PLink>
		</PItem>
		<PItem disabled={!prevEnabled}>
			<PLink id="step-backward" onClick={e => onPaginatorPageClick(e, currentPage - 1)}>
				<FontAwesomeIcon icon="step-backward" />
			</PLink>
		</PItem>
		{range.map(i => {
			const pageNum = i + 1;
			return <PItem key={i} active={pageNum === currentPage}>
				<PLink onClick={e => onPaginatorPageClick(e, pageNum)}>{pageNum}</PLink>
			</PItem>;
		})}
		<PItem disabled={!nextEnabled}>
			<PLink id="step-forward" onClick={e => onPaginatorPageClick(e, currentPage + 1)}>
				<FontAwesomeIcon icon="step-forward" />
			</PLink>
		</PItem>
		<PItem disabled={!nextEnabled}>
			<PLink id="fast-forward" onClick={e => onPaginatorPageClick(e, pageCount)}>
				<FontAwesomeIcon icon="fast-forward" />
			</PLink>
		</PItem>
	</Pagination>;
}

Paginator.propTypes = {
	itemCount: PropTypes.number.isRequired,
	maxDisplayItems: PropTypes.number.isRequired,
	currentPage: PropTypes.number.isRequired,
	onPaginatorPageClick: PropTypes.func.isRequired,
	maxPaginatorButtons: PropTypes.number.isRequired,
	centered: PropTypes.bool.isRequired,
};
