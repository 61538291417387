import React from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import {Container} from 'reactstrap';
import PartnerDatasetForm from './PartnerDatasetForm';
import Alert from '../alert/Alert';
import Message from '../../messages/Message';

export default class NewPartnerDataset extends React.Component {
	static propTypes = {
		partnerId: PropTypes.string.isRequired,
		onSubmit: PropTypes.func.isRequired,
		fetchTypes: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			lastMessage: null,
			doRedirect: false,
			types: [],
		};

		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		this.props.fetchTypes().then(types => {
			this.setState({
				types,
			});
		}).catch(err => {
			this.setState({
				lastMessage: new Message('Could not fetch types', err.response.data.message, {
					color: 'warn',
				}),
			});
		});
	}

	onSubmit(dataset) {
		this.setState({
			lastMessage: null,
		});

		dataset.types = dataset.types.map(t => {
			if (t.value) {
				return t.value;
			}

			return t;
		});

		this.props.onSubmit(this.props.partnerId, dataset).then(() => {
			this.setState({
				doRedirect: true,
			});
		}).catch(err => {
			this.setState({
				lastMessage: new Message('Could not save dataset', err.message, {
					color: 'danger',
				}),
			});
		});
	}

	render() {
		if (this.state.doRedirect) {
			return <Redirect to={`/partners/${this.props.partnerId}/datasets`} />;
		}

		const alert = this.state.lastMessage && <Alert message={this.state.lastMessage} />;

		return <div id="newDataset">
			<Container>
				<h1>New dataset</h1>

				{alert}

				<PartnerDatasetForm
					onSubmit={this.onSubmit}
					types={this.state.types}
				/>
			</Container>
		</div>;
	}
}
