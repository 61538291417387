import React from 'react';
import {FormGroup, Col, Button, Form} from 'reactstrap';
import Select from 'react-select';

export default class DatasetTypePicker extends React.Component {
	constructor(props) {
		super(props);
		const state = {
			selectedOption: null
		};
		if (props.line) {
			const value = props.line.type;
			state.selectedOption = { value, label: value };
		}
		this.state = state;
	}

	getOptions() {
		return this.props.types.map(t => ({ value: t, label: t }));
	}

	onSubmit = e => {
		e.preventDefault();
		const type = this.state.selectedOption;
		if(type) {
			this.props.edit({
				dataset: { type: type.value }
			});
		}
	};

	render() {
		return <div>
			<h1>Filter op bestands label</h1>

			<Form onSubmit={this.onSubmit}>
				<FormGroup row>
					<Col xs={12} lg={2} className="d-flex align-items-center">
						Bestands label
					</Col>

					<Col xs={12} lg={4}>
						<Select
							options={this.getOptions()}
							value={this.state.selectedOption}
							onChange={selectedOption => this.setState({ selectedOption })}
						/>
					</Col>
				</FormGroup>

				<FormGroup>
					<Button type="submit" color="primary" disabled={!this.state.selectedOption}>{this.props.line ? 'Opslaan' : 'Toevoegen'}</Button>
				</FormGroup>
			</Form>
		</div>;
	}
}
