import React from 'react';
import SweetAlert from 'sweetalert2';
import withReactContext from 'sweetalert2-react-content';
import 'sweetalert2/dist/sweetalert2.css';

const swalWithBootstrapButtons = SweetAlert.mixin({
	confirmButtonClass: 'btn btn-success ml-2',
	cancelButtonClass: 'btn btn-danger mr-2',
	buttonsStyling: false,
});

const Alert = withReactContext(swalWithBootstrapButtons);

const defaultPromptProps = {
	title: 'Weet je het zeker?',
	text: 'Weet je zeker dat je dit wilt doen?',
	type: 'warning',
	confirmButtonText: 'Ja',
	cancelButtonText: 'Nee',
};

export const withPrompts = BaseComponent => class ComponentWithDeletePrompt extends React.Component {
	prompt = (opts = {}) => {
		const options = Object.assign({}, defaultPromptProps, opts);

		Alert.fire({
			title: options.title,
			text: options.text,
			type: options.type,
			showCancelButton: true,
			reverseButtons: true,
			confirmButtonText: options.confirmButtonText,
			cancelButtonText: options.cancelButtonText,
		}).then(res => {
			if (res.value) {
				options.onConfirm();
			}
		});
	};

	render = () => <BaseComponent prompt={this.prompt} {...this.props} />
};
