import React from 'react';
import PropTypes from 'prop-types';
import {Form, Button, FormGroup, Label} from 'reactstrap';
import Select from 'react-select';
import {Link} from 'react-router-dom';

import PartnerField from './PartnerField';
import {withFormUtils} from '../../hoc/with-form-utils';
import {isort} from '../../util/array';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class PartnerDatasetForm extends React.Component {
	static propTypes = {
		data: PropTypes.object,
		types: PropTypes.array,
		commissionLink: PropTypes.string,
		participationLink: PropTypes.string,
	};

	static defaultProps = {
		data: {},
	};

	componentDidMount() {
		const {setDefaultValue} = this.props;

		setDefaultValue({
			name: '',
			description: '',
			emailColumnName: '',
			idColumnName: '',
			active: false,
			separateInvoice: false,
		});
	}

	toMultiSelect(t) {
		return { value: t, label: t};
	}

	getOptions() {
		return isort(this.props.types).map(this.toMultiSelect);
	}

	getCurrentTypes() {
		const typesData = this.props.getdata('types');

		const isArrayOfStrings = Array.isArray(typesData) && typesData.every(t => typeof t === 'string');

		if (isArrayOfStrings) {
			return typesData.map(this.toMultiSelect);
		}

		return typesData;
	}

	renderDatasetLinks() {
		if (!this.props.getdata('key')) {
			return null;
		}

		const items = [];

		if (this.props.commissionsLink) {
			items.push(<FormGroup key="c">
				<Link to={this.props.commissionsLink}>
					<FontAwesomeIcon icon="hand-holding-usd" size="2x" /> Stel commissies in
				</Link>
			</FormGroup>);
		}

		if (this.props.participationLink) {
			items.push(<FormGroup key="p">
				<Link to={this.props.participationLink}>
					<span style={{ marginRight: '10px', display: 'inline-block' }}>
						<FontAwesomeIcon icon="file-invoice" size="2x" />
					</span> Stel extra inzet in
				</Link>
			</FormGroup>);
		}

		if (this.props.testPersonsLink) {
			items.push(<FormGroup key="t">
				<Link to={this.props.testPersonsLink}>
					<span style={{ marginRight: '10px', display: 'inline-block' }}>
						<FontAwesomeIcon icon="users-cog" size="2x" />
					</span> Stel testpersonen in
				</Link>
			</FormGroup>);
		}

		return items;
	}

	render() {
		const {onFormSubmit, onInputChange, getdata} = this.props;
		const hasAPIKey = getdata('key') !== '';

		return <Form onSubmit={onFormSubmit}>
			{hasAPIKey && <PartnerField label="API Key" name="key" onChange={() => false} getdata={getdata} readOnly />}
			<PartnerField label="Bestandnaam (alleen letters en cijfers)" name="name" onChange={onInputChange} getdata={getdata} pattern="[A-Za-z0-9]+" title="Name may only contain letters and numbers" />

			<FormGroup>
				<Label>Voeg label(s) toe</Label>
				<Select
					name="types"
					onChange={selected => onInputChange({
						target: {
							name: 'types',
							value: selected,
						},
					})}
					options={this.getOptions()}
					isMulti
					isSearchable
					value={this.getCurrentTypes()}
				/>
			</FormGroup>

			<PartnerField type="textarea" label="Omschrijving" name="description" onChange={onInputChange} getdata={getdata} />
			<PartnerField label="Geef hier de headernaam van de e-mail kolom aan" name="emailColumnName" onChange={onInputChange} getdata={getdata} />

			{this.renderDatasetLinks()}

			<PartnerField type="checkbox" label="Maak bestand actief / inactief" name="active" onChange={onInputChange} getdata={getdata} />
			<PartnerField type="checkbox" label="Geef aan of het bestand apart gefactureerd dient te worden" name="separateInvoice" onChange={onInputChange} getdata={getdata} />
			<PartnerField type="number" label="Geef de prioriteitstelling van het bestand aan (lager is eerder)" name="sort" onChange={onInputChange} getdata={getdata} min={0} step={1} />

			<FormGroup>
				<Button color="primary" type="submit">Bewaar</Button>
			</FormGroup>
		</Form>;
	}
}

export default withFormUtils(PartnerDatasetForm);
