import React, { Component } from 'react';
import { Container, Form, FormGroup, Input, Col, Row, Button } from 'reactstrap';
import InputAutocomplete from '../Autocomplete';
import { Link, Redirect } from 'react-router-dom';
import Alert from '../alert/Alert';
import Message from '../../messages/Message';

export default class NewExportColumn extends Component {
	state = {
		column: '',
		type: 'string',
		redirect: false,
		message: null
	};

	render() {
		const { column, type, redirect, message } = this.state;
		if(redirect) {
			return <Redirect to="/csv-columns" />;
		}
		return <Container>
			<h1>Nieuwe kolom</h1>
			{message && <Alert message={message} />}
			<Form onSubmit={e => {
				e.preventDefault();
				this.setState({ err: null });
				this.props.add(this.state).then(() => {
					this.setState({ redirect: true });
				}).catch(err => {
					this.setState({ message: new Message('Error', err.message, { color: 'danger' }) });
				});
			}}>
				<Row>
					<Col>
						<InputAutocomplete
							items={this.props.globalMappings}
							placeholder="Kolom"
							value={column}
							onChange={e => this.setState({ column: e.target.value })}
							shouldItemRender={(item, val) => item.toLowerCase().includes(val.toLowerCase())}
						/>
					</Col>
					<Col>
						<Input type="select" value={type} onChange={e => this.setState({ type: e.target.value })}>
							<option value="date">date</option>
							<option value="number">number</option>
							<option value="string">string</option>
						</Input>
					</Col>
				</Row>
				<FormGroup className="mt-2">
					<Button color="primary" type="submit">Voeg toe</Button>
					<Link className="btn btn-info ml-2" to="/csv-columns">Terug</Link>
				</FormGroup>
			</Form>
		</Container>;
	}
}
