import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'reactstrap';
import {connect} from 'react-redux';
import moment from 'moment';

import * as api from '../api';
import {withLoader} from '../hoc/with-loader';
import ListItems from '../components/crud/ListItems';
import {setSelections} from '../redux/actions/campaigns';
import {withPrompts} from '../hoc/with-prompts';
import { MailingState } from '../enum/MailingState';
import './SelectionContainer.css';
import {withMessages} from '../hoc/with-messages';

const SELECTION_CONTAINER_MESSAGE = 'selection-container-message';

class SelectionContainer extends React.Component {
	state = {}

	static propTypes = {
		renderWithLoader: PropTypes.func,
	};

	componentWillMount() {
		this.fetchCampaign();
		this.fetchMailing();
	}

	componentDidMount() {
		this.fetchMailing();
	}

	fetchCampaign() {
		const {campaignId} = this.props.match.params;
		api.fetchCampaign(campaignId).then(data => {
			this.setState({campaignName: data.name});
		}).catch(err => {
			console.log('Campagne informatie kon niet worden opgehaald', err);
		});
	}

	fetchMailing() {
		const {campaignId, mailingId} = this.props.match.params;
		api.fetchMailing(campaignId, mailingId).then(data => {
			this.setState({mailingName: data.name, mailingState: data.state});
			this.props.dispatch(setSelections(data.selections));
		}).catch(err => {
			console.log('Mailing informatie kon niet worden opgehaald', err);
		});
	}

	deleteSelection = selection => {
		const {campaignId, mailingId} = this.props.match.params;

		api.deleteSelection(campaignId, mailingId, selection._id).then(() => {
			this.props.setMessage(SELECTION_CONTAINER_MESSAGE, 'Selectie is verwijderd', `Selectie '${selection._id} is succesvol verwijderd`, {
				color: 'success',
			});
			this.fetchMailing();
		}).catch(err => {
			this.props.setMessage(SELECTION_CONTAINER_MESSAGE, 'Kon selectie niet verwijderen', err.response.data.message, {
				color: 'danger',
			});
		});
	};

	togglePreDefinedQuery = selection => {
		const {campaignId, mailingId} = this.props.match.params;

		api.updateSelection(campaignId, mailingId, selection._id, {preDefined: selection.preDefined ? false : true}).then(() => {
			this.fetchMailing();
		}).catch(err => {
			console.log(err);
		});
	}

	renderList = () => <ListItems
		containerId="selections"
		headerText="Selecties"
		description={`Campagne: ${this.state.campaignName}, Verzending: ${this.state.mailingName}`}
		items={this.props.selections.slice().reverse()}
		getItemDisplayText={selection => <Row className="selectionsRow">
			<Col lg="5">{selection._id}</Col>
			<Col lg="3">{moment(selection.date).format('DD-MM-YYYY HH:mm:ss')}</Col>
			<Col>
				Aantal e-mails: <strong>{selection.recordCount}</strong><br />
				{selection.description && selection.description}
			</Col>
		</Row>}
		getPrependActions={selection => {
			const {campaignId, mailingId} = this.props.match.params;
			const icon = selection.preDefined ? 'toggle-on' : 'toggle-off';

			return [
				{
					icon: icon,
					onClick: () => {
						this.togglePreDefinedQuery(selection);
					},
					tooltip: 'Voorgedefinieerde query',
				},
				{
					link: `/api/campaigns/${campaignId}/mailings/${mailingId}/selections/${selection._id}/csv`,
					linkIsRegular: true,
					icon: 'file-csv',
					tooltip: 'Download CSV',
				},
			];
		}}
		getEditAction={selection => {
			if(this.state.mailingState !== MailingState.DRAFT) {
				return [];
			}
			const {campaignId, mailingId} = this.props.match.params;
			return {
				link:  `/selection-builder/${campaignId}/${mailingId}/${selection._id}`,
				icon: 'edit',
				tooltip: 'Bewerk deze selectie',
			};
		}}
		getDeleteAction={selection => {
			if(this.state.mailingState !== MailingState.DRAFT) {
				return [];
			}
			return {
				icon: 'times',
				iconClass: 'text-danger',
				onClick: () => this.props.prompt({
					title: `Verwijder selectie ${selection._id}?`,
					text: `Weet je zeker dat je selectie ${selection._id} wilt verwijderen?`,
					onConfirm: () => this.deleteSelection(selection),
				}),
				tooltip: 'Selectie verwijderen',
			};
		}}
		lastMessage={this.props.messages[SELECTION_CONTAINER_MESSAGE]}
	/>;

	render = () => this.props.renderWithLoader(this.props.selections, this.renderList);
}

export default connect(store => {
	return {
		selections: store.campaigns.selections,
	};
})(withLoader(withPrompts(withMessages(SelectionContainer))));
