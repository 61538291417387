import React from 'react';
import PropTypes from 'prop-types';
import Message from '../../messages/Message';
import { withPrompts } from '../../hoc/with-prompts';
import { withMessages } from '../../hoc/with-messages';
import ListItems from '../crud/ListItems';
import { withLoader } from '../../hoc/with-loader';

const PARTNER_MESSAGE = 'partner-message';

class ListPartners extends React.Component {
	static propTypes = {
		fetchPartners: PropTypes.func.isRequired,
		onDelete: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			partners: null,
		};
	}

	componentDidMount() {
		this.getPartners();
	}

	getPartners = () => {
		this.props.fetchPartners().then(partners => {
			this.setState({
				partners,
			});
		}).catch(err => {
			this.setState({ partners: [] });

			this.props.setMessage(PARTNER_MESSAGE, 'Error while fetching partners', err.message, {
				color: 'danger',
			});
		});
	}

	onDeleteClick = (e, partner) => {
		if (e.nativeEvent) {
			e.nativeEvent.preventDefault();
			e.nativeEvent.stopPropagation();
		}

		e.preventDefault();
		e.stopPropagation();

		this.props.prompt({
			title: `Delete ${partner.name}?`,
			text: `Are you sure you want to delete ${partner.name}?`,
			onConfirm: () => {
				this.setState({
					lastMessage: null,
				});

				this.props.onDelete(partner.id).then(() => {
					this.getPartners();
					this.setState({
						lastMessage: new Message('Success', 'Partner has been deleted', {
							color: 'success',
						}),
					});
				}).catch(err => {
					this.setState({
						lastMessage: new Message('An error occured while deleting partner', err.message, {
							color: 'danger',
						}),
					});
				});
			},
		});

		return false;
	}

	renderList = () => <ListItems
		containerId="listPartners"
		headerText="Partners"
		items={this.state.partners}
		newLink={{
			text: 'Nieuwe partner',
			url: '/partners/new',
		}}
		getItemDisplayText={partner => `${partner.name} (${partner.id})`}
		getPrependActions={partner => {
			return [
				{
					icon: 'table',
					link: `/partners/${partner.id}/datasets`,
					tooltip: `Bestanden van ${partner.name}`,
				},
				{
					icon: 'users',
					link: `/partners/${partner.id}/contacts`,
					tooltip: `Contacten van ${partner.name}`,
				},
			];
		}}
		getEditAction={partner => {
			return [
				{
					icon: 'edit',
					link: `/partners/${partner.id}/edit`,
					tooltip: `${partner.name} bewerken`,
				},
				{
					icon: 'mail-bulk',
					iconClass: 'text-danger',
					link: `/partners/${partner.id}/blacklist`,
					tooltip: `Blacklist van ${partner.name}`,
				},
			];
		}}
		getDeleteAction={partner => {
			return {
				icon: 'times',
				iconClass: 'text-danger',
				onClick: e => this.onDeleteClick(e, partner),
				tooltip: `${partner.name} verwijderen`,
			};
		}}
		lastMessage={this.props.lastMessage}
	/>;

	render = () => this.props.renderWithLoader(this.state.partners, this.renderList);
}

export default withPrompts(withMessages(withLoader(ListPartners)));
