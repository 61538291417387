import React from 'react';
import PropTypes from 'prop-types';
import {Container, Form, FormGroup, Label, Input, Button} from 'reactstrap';

import Message from '../../messages/Message';
import Alert from '../alert/Alert';

const TOKEN_LENGTH = 6;

export default class AuthForm extends React.Component {
	static propTypes = {
		onSubmit: PropTypes.func.isRequired,
		lastMessage: PropTypes.instanceOf(Message),
		clear: PropTypes.func.isRequired
	};

	state = {
		username: '',
		password: '',
		token: ''
	}

	onSubmit = e => {
		e.preventDefault();

		if(this.state.token){
			this.setState({ token: '' });
		}

		if (this.state.username && this.state.password) {
			this.props.onSubmit(this.state.username, this.state.password);
		}

		return false;
	};

	onChange = e => {
		this.setState({
			[e.target.name]: e.target.value,
		});
		if(e.target.name === 'token' && e.target.value.length === TOKEN_LENGTH){
			this.props.onSubmit(this.state.username, this.state.password, e.target.value);
		}
	}

	handleBack = () => {
		this.setState({
			username: '',
			password: '',
			token: ''
		});
		this.props.clear();
	}

	render = () => {
		const { lastMessage } = this.props;
		return <div id="loginForm">
			<Container>
				<h1>Inloggen</h1>

				{lastMessage && <Alert message={lastMessage} />}
				{
					lastMessage?.getData()?.twoFA?
						<>
							<FormGroup>
								<Label>2FA token</Label>
								<Input type="text" name="token" value={this.state.token} onChange={this.onChange} autoFocus />
							</FormGroup>

							<Button type="button" color="info" onClick={this.handleBack}>Terug</Button>
						</>
						:
						<Form onSubmit={this.onSubmit}>
							<FormGroup>
								<Label>Gebruikersnaam</Label>
								<Input type="text" name="username" value={this.state.username} onChange={this.onChange} autoFocus />
							</FormGroup>

							<FormGroup>
								<Label>Wachtwoord</Label>
								<Input type="password" name="password" value={this.state.password} onChange={this.onChange} />
							</FormGroup>

							<FormGroup>
								<Button color="primary">Log in</Button>
							</FormGroup>
						</Form>
				}
			</Container>
		</div>;
	};
}
