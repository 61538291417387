import React from 'react';
import {PropTypes} from 'prop-types';
import {Navbar, NavItem, NavLink, Nav, Container} from 'reactstrap';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default class Navigation extends React.Component {
	static propTypes = {
		isLoggedIn: PropTypes.bool.isRequired,
		logout: PropTypes.func.isRequired,
		account: PropTypes.shape({
			name: PropTypes.string,
			username: PropTypes.string,
		}),
		toggleSidebar: PropTypes.func.isRequired,
	};

	onLogoutClick = e => {
		e.preventDefault();
		this.props.logout();
		return false;
	}

	render = () => <div className="greyBackground">
		<div className="link">
			<Link className="navbar-brand" to="/">
				<img src="/logo.png" alt="OMG" className="logo"/>
			</Link>
		</div>
		<Container className="custom-container">
			<div className="logout">
				<Navbar>
					{this.props.isLoggedIn && <Nav>
						<NavItem>
							<NavLink href="#" onClick={this.onLogoutClick}>Log uit ({this.props.account.name})</NavLink>
						</NavItem>
					</Nav>}

					<div className="menuToggle clickable d-block d-lg-none" onClick={this.props.toggleSidebar}>
						<FontAwesomeIcon icon="bars" size="2x" className="text-dark" />
					</div>
				</Navbar>
			</div>
		</Container>
	</div>;
}
