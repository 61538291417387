import React, { Component } from 'react';
import { Button, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parseQuery } from './QueryBuilder';
import FlatQueryEditor, { flatten, compile } from './FlatQueryEditor';

export default class QueryGroup extends Component {
	state = {
		flat: [],
		open: false
	};

	componentDidMount() {
		this.componentDidUpdate({});
	}

	componentDidUpdate(props) {
		const { query, traverseQuery, edit } = this.props;
		if(props.query !== query && query._flat !== this.state.flat) {
			const flat = flatten(traverseQuery, query);
			this.setState({ flat }, () => {
				edit(Object.assign({}, query, { _flat: flat }));
			});
		}
	}

	edit = query => {
		if(query && !query.and && !query.or) {
			const { _flat } = query;
			delete query._flat;
			query = { and: [query], _flat };
		}
		this.props.edit(query);
	}

	editFlat = flat => {
		this.setState({ flat }, () => {
			this.edit(compile(flat));
		});
	};

	render() {
		const { query, editLine } = this.props;
		const { flat, open } = this.state;
		if(query._flat !== flat) {
			return null;
		}
		return <div className="query-group">
			<div className="my-1">
				<FlatQueryEditor edit={this.editFlat} editLine={editLine} flat={flat} />
			</div>
			<div className="d-flex">
				<div className="w-100 mr-2"><hr /></div> <Button size="sm" color="light" onClick={() => this.setState({ open: !open })}>
					<FontAwesomeIcon icon={open ? 'chevron-up' : 'chevron-down'} />
				</Button>
			</div>
			<Collapse isOpen={open}>
				{parseQuery(query, this.edit, editLine)}
			</Collapse>
		</div>;
	}
}
