import React from 'react';
import { InputGroup, InputGroupAddon, Input, Button, FormGroup } from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default function Token({ value, edit, remove, negate }) {
	return <FormGroup className="my-1 token">
		<InputGroup>
			<Input type="text" value={value} readOnly />
			<InputGroupAddon addonType="append" className="block-button-group bg-white">
				<Button color="secondary" outline onClick={edit}><FontAwesomeIcon icon="edit" /></Button>
				{negate && <Button color="secondary" outline onClick={negate}><FontAwesomeIcon icon="adjust" /></Button>}
				<Button color="secondary" outline onClick={remove}><FontAwesomeIcon icon="times" /></Button>
			</InputGroupAddon>
		</InputGroup>
	</FormGroup>;
}
