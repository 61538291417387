import React from 'react';
import { Alert, Container, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import { Link } from 'react-router-dom';

export default function MappingsByDataset({ error, mappings }) {
	if(error) {
		return <Container>
			<Alert color="danger">{error}</Alert>
		</Container>;
	} else if(!mappings) {
		return <Container>
			<p>Loading...</p>
		</Container>;
	}
	return <Container>
		{mappings.map(mapping => {
			return <Row key={mapping._id} className="mb-1">
				<Col xs={4}>{mapping._id}</Col>
				<Col xs={8}>
					<ListGroup>
						{mapping.datasets.map(dataset => {
							return <ListGroupItem key={dataset.id}>
								<Row>
									<Col><Link to={`/partners/${encodeURIComponent(dataset.partner)}/datasets`}>{dataset.partner}</Link></Col>
									<Col>
										<Link to={`/partners/${encodeURIComponent(dataset.partner)}/datasets/${encodeURIComponent(dataset.id)}/headers`}>
											{dataset.description || dataset.name}
										</Link>
									</Col>
								</Row>
							</ListGroupItem>;
						})}
					</ListGroup>
				</Col>
			</Row>;
		})}
	</Container>;
}
