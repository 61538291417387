import PropTypes from 'prop-types';

export const listGroupItemProps = PropTypes.shape({
	link: PropTypes.string,
	linkIsRegular: PropTypes.bool, // true: link = <a> | false: link = <Link>
	icon: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	iconClass: PropTypes.string,
	tooltip: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node,
	]),
});
