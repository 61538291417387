import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { parseQuery } from '../QueryBuilder';
import { Button, ButtonGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class GroupToken extends Component {
	edit(i) {
		return query => {
			const tokens = this.props.token.slice();
			if(query) {
				tokens[i] = query;
			} else {
				tokens.splice(i, 1);
			}
			this.props.edit({ [this.props.operator]: tokens });
		};
	}

	addToken(query) {
		const tokens = this.props.token.slice();
		tokens.push(query);
		this.props.edit({ [this.props.operator]: tokens });
	}

	addLine = () => {
		this.props.editLine(query => this.addToken(query));
	};

	remove = () => {
		this.props.edit(null);
	};

	negate = () => {
		this.props.edit({ not: {
			[this.props.operator]: this.props.token
		} });
	};

	render() {
		return <fieldset className={`border container-fluid pb-2 mb-1 position-relative border-${this.props.border}`}>
			<legend className="m-0 w-auto text-monospace small">{this.props.label}</legend>
			<Row>{this.props.token.map((token, i) => <Col key={i}>{parseQuery(token, this.edit(i), this.props.editLine)}</Col>)}</Row>
			<Row><Col><Button color="info" className="token" onClick={this.addLine}><FontAwesomeIcon icon="plus" /></Button></Col></Row>
			<ButtonGroup size="sm" className="mt-2">
				<Button color="primary" onClick={() => this.addToken({ and: [] })}>EN</Button>
				<Button color="primary" onClick={() => this.addToken({ or: [] })}>OF</Button>
				<Button color="primary" onClick={this.negate}>Niet</Button>
			</ButtonGroup>
			<button className="close" onClick={this.remove} title="Verwijder">
				<span>&times;</span>
			</button>
		</fieldset>;
	}
}
