import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import './Sidebar.css';
import { version as webVersion } from '../../package.json';

const CONNECTOR_STATUS = {
	idle: 'text-success',
	busy: 'text-warning',
	default: 'text-danger'
};

function SidebarNavigationItem({ className, url, name }) {
	return <li className={`sideMenuListItem ${className || ''}`}>
		<Link to={url}>{name}</Link>
	</li>;
}

export default function Sidebar({ connectorStatus, version, isLoggedIn, isMenuOpen }) {
	return isLoggedIn && <aside className={classNames('sidebar', {
		active: isMenuOpen,
	})}>
		<div className="sideMenuContainer">
			<div className="side-top">
				<h2 className="sideMenuHeader partners">Partners</h2>
				<ul className="sideMenu partners">
					<SidebarNavigationItem url="/partners" name="Lijst van partners" className="listPartnersListItem" />
					<SidebarNavigationItem url="/partners/new" name="Nieuwe partner" className="newPartnerListItem" />
					<SidebarNavigationItem url="/types" name="Bekijk labels" className="listLabelsListItem" />
					<SidebarNavigationItem url="/valuemapped" name="Bekijk value mappings" className="listValueMappingsListItem" />
				</ul>

				<h2 className="sideMenuHeader campaigns">Campagnes</h2>
				<ul className="sideMenu campaigns">
					<SidebarNavigationItem url="/campaigns" name="Lijst van campagnes" className="listCampaignsListItem" />
					<SidebarNavigationItem url="/selection-builder" name="Doelgroepen selecteren" className="selectionBuilderListItem" />
					<SidebarNavigationItem url="/counts" name="Bestandsaantallen" className="countingsListItem" />
					<SidebarNavigationItem url="/interest-types" name="Bekijk interesse types" className="listInterestsTypeListItem" />
					<SidebarNavigationItem url="/tasks" name="Bekijk taken" className="listTasksListItem" />
					<SidebarNavigationItem url="/csv-columns" name="Bekijk kolommen" className="listColumnsListItem" />
					<SidebarNavigationItem url="/pressure" name="Bekijk maildruk" className="pressureListItem" />
					<SidebarNavigationItem url="/settings" name="Verzending instellingen" className="listSettingsListItem" />
				</ul>

				<h2 className="sideMenuHeader users">Gebruikers</h2>
				<ul className="sideMenu users">
					<SidebarNavigationItem url="/users" name="Lijst van gebruikers" className="listUsersListItem" />
				</ul>

				<h2 className="sideMenuHeader uploadCSV">Upload CSV</h2>
				<ul className="sideMenu uploadCSV">
					<SidebarNavigationItem url="/upload" name="Upload bestanden" className="uploadFilesListItem" />
					<SidebarNavigationItem url="/deleteall" name="Verwijder uit bestand" className="deleteFilesListItem" />
					<SidebarNavigationItem url="/blacklist/upload" name="Upload blacklist" className="listUploadBlacklistListItemsersListItem" />
					<SidebarNavigationItem url="/blacklist" name="Bekijk blacklist" className="showBlacklistListItem" />
					<SidebarNavigationItem url="/blacklist/domain" name="Bekijk domein blacklist" className="DomainBlacklistList" />
					<SidebarNavigationItem url="/dns-check" name="Check domeinen" className="dnsCheckListItem" />
				</ul>

				<h2 className="sideMenuHeader uploadCSV">Verrijking</h2>
				<ul className="sideMenu uploadCSV">
					<SidebarNavigationItem url="/enrichment/upload" name="Verrijkingsbestand toevoegen" className="uploadEnrichmentListItem" />
					<SidebarNavigationItem url="/enrichment" name="Verrijkingsbestanden overzicht" className="listEnrichmentListItem" />
				</ul>
			</div>
			<div className="side-bottom">
				<h2>
					<FontAwesomeIcon
						icon="network-wired"
						className={CONNECTOR_STATUS[connectorStatus] || CONNECTOR_STATUS.default}
						title={`MongoDB Connector status: ${connectorStatus}`} />
				</h2>
				{version && <div className="small">
					<Row>
						<Col xs={6}>Website:</Col>
						<Col xs={6} className="text-right">{webVersion || 'Unknown'}</Col>
					</Row>
					<Row>
						<Col xs={6}>API:</Col>
						<Col xs={6} className="text-right">{version.api || 'Unknown'}</Col>
					</Row>
					<Row>
						<Col xs={6}>Updater:</Col>
						<Col xs={6} className="text-right">{version.updater || 'Unknown'}</Col>
					</Row>
				</div>}
			</div>
		</div>
	</aside>;
}
