import React from 'react';
import {Container} from 'reactstrap';
import Alert from '../alert/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function UpdateOrCreateItem(props) {
	if (!props.loaded) {
		return <p>
			<FontAwesomeIcon icon="cog" spin />{' '}
			Aan het laden, een moment geduld...
		</p>;
	}
	const { selectedCampaign } = props;
	return <div id={props.containerId}>
		<Container>
			<h1>{props.headerText}</h1>
			{selectedCampaign && <h5>Campagne: {selectedCampaign.name}</h5>}

			{props.lastMessage && <Alert message={props.lastMessage} />}

			{props.form}
		</Container>
	</div>;
}
