import React, { Fragment } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import UploadFormContainer from './UploadFormContainer';
import PartnerCRUDContainer from './PartnerCRUDContainer';
import MappingEditorContainer from './MappingEditorContainer';
import CampaignContainer from './CampaignContainer';
import TypeContainer from './TypeContainer';
import MailingContainer from './MailingContainer';
import QueryBuilderContainer from './QueryBuilderContainer';
import SelectionContainer from './SelectionContainer';
import AuthContainer from './AuthContainer';
import NavigationContainer from './NavigationContainer';
import UserContainer from './UserContainer';
import BlacklistContainer from './BlacklistContainer';
import BreadcrumbContainer from './BreadcrumbContainer';
import CountsContainer from './CountsContainer';
import TasksContainer from './TasksContainer';
import PartnerBlacklistContainer from './PartnerBlacklistContainer';
import ColumnsContainer from './ColumnsContainer';
import SidebarContainer from './SidebarContainer';
import SettingsContainer from './SettingsContainer';
import InterestTypeContainer from './InterestTypeContainer';
import Error404 from '../components/Error404';
import PressureContainer from './PressureContainer';
import EnrichmentUploadContainer from './EnrichmentUploadContainer';
import EnrichmentContainer from './EnrichmentContainer';
import ValueMappingsContainer from './ValueMappingsContainer';
import ValueMappingContainer from './ValueMappingContainer';
import CSVDeleteContainer from './CSVDeleteContainer';
import DNSCheckContainer from './DNSCheckContainer';

import './App.css';
import { checkLogin } from '../api';
import { setAccount } from '../redux/actions/auth';
import { setAppLoaded } from '../redux/actions/app';
import { inject, remove } from '../custom/CustomStyleInjector';

import {
	DATASET_VALUE_MAPPINGS, DATASET_EDIT_MAPPINGS, PARTNER_BLACKLIST, PARTNERS,
	CAMPAIGNS_MAILING_SELECTIONS, CAMPAIGNS_MAILING, CAMPAIGNS, UPLOAD, TYPES,
	QUERY_BUILDER, BLACKLIST, USERS, INTERESTS, TASKS, CSV_COLUMNS, SETTINGS,
	COUNTS, PRESSURE, ENRICHMENT_UPLOAD, ENRICHMENT, VALUE_MAPPED,
	VALUE_MAPPED_FIELD, DELETE_CSV, DNS_CHECK
} from '../util/routes';

class App extends React.Component {
	state = {
		loaded: false,
	}

	componentDidMount() {
		inject();
		checkLogin()
			.then(account => this.props.dispatch(setAccount(account)))
			.catch(() => undefined)
			.then(() => {
				this.props.dispatch(setAppLoaded());
				this.setState({ loaded: true });
			});
	}

	componentWillUnmount() {
		remove();
	}

	render() {
		const { account } = this.props;
		return <div className="App">
			<NavigationContainer account={account} />

			<section id="mainContent">
				<SidebarContainer />

				{this.state.loaded && <main className="content">
					{account ? <Fragment>
						<BreadcrumbContainer />

						<Switch>
							<Route {...DNS_CHECK.route} component={DNSCheckContainer} />
							<Route {...DELETE_CSV.route} component={CSVDeleteContainer} />
							<Route {...VALUE_MAPPED_FIELD.route} component={ValueMappingContainer} />
							<Route {...VALUE_MAPPED.route} component={ValueMappingsContainer} />
							<Route {...ENRICHMENT_UPLOAD.route} component={EnrichmentUploadContainer} />
							<Route {...ENRICHMENT.route} component={EnrichmentContainer} />
							<Route {...DATASET_VALUE_MAPPINGS.route} component={MappingEditorContainer} />
							<Route {...DATASET_EDIT_MAPPINGS.route} component={MappingEditorContainer} />
							<Route {...PARTNER_BLACKLIST.route} component={PartnerBlacklistContainer} />
							<Route {...PARTNERS.route} component={PartnerCRUDContainer} />
							<Route {...CAMPAIGNS_MAILING_SELECTIONS.route} component={SelectionContainer} />
							<Route {...CAMPAIGNS_MAILING.route} component={MailingContainer} />
							<Route {...CAMPAIGNS.route} component={CampaignContainer} />
							<Route {...UPLOAD.route} component={UploadFormContainer} />
							<Route {...TYPES.route} component={TypeContainer} />
							<Route {...QUERY_BUILDER.route} component={QueryBuilderContainer} />
							<Route {...BLACKLIST.route} component={BlacklistContainer} />
							<Route {...USERS.route} component={UserContainer} />
							<Route {...INTERESTS.route} component={InterestTypeContainer} />
							<Route {...TASKS.route} component={TasksContainer} />
							<Route {...CSV_COLUMNS.route} component={ColumnsContainer} />
							<Route {...SETTINGS.route} component={SettingsContainer} />
							<Route {...PRESSURE.route} component={PressureContainer} />
							<Route {...COUNTS.route} component={CountsContainer} />
							<Route component={Error404} />
						</Switch>
					</Fragment> : <AuthContainer />}
				</main>}
			</section>
		</div>;
	}
}

export default withRouter(connect(store => {
	return {
		account: store.auth.account
	};
})(App));
