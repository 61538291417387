import React from 'react';
import { Button, Input } from 'reactstrap';
import './MailingCounter.css';

export default function MailingCounter({ mailing, selection, redistribute, applyFactor, remaining, setRemaining }) {
	return <div className="mailingCounter">
		<div className="mailingCounterEntry">
			<h5>Mailing</h5>
			<p><Input type="text" value={mailing} readOnly /></p>
		</div>
		<div className="mailingCounterEntry">
			<h5>In selectie</h5>
			<p><Input type="text" value={selection} readOnly /></p>
		</div>
		<div className="mailingCounterEntry">
			<h5>Totaal nog over</h5>
			<p><Input type="text" value={remaining} onChange={setRemaining} /></p>
		</div>
		{redistribute && <Button className="mailingCounterButton" color="primary" onClick={redistribute}>Opnieuw berekenen</Button>}
		{applyFactor && <Button className="mailingCounterButton" color="info" onClick={applyFactor}>Extra inzet toevoegen</Button>}
	</div>;
}
