import React from 'react';
import PropTypes from 'prop-types';
import {Container, ListGroup, Form, FormGroup, Input} from 'reactstrap';
import {Link} from 'react-router-dom';

import Alert from '../alert/Alert';
import ListItem from '../list-group/ListItem';
import Message from '../../messages/Message';
import PaginatorContainer from '../../paginator/PaginatorContainer';
import {makeRegExp} from '../../util/string';

const maxInputLength = 100;

export default class ListItems extends React.Component {
	state = {
		searchTerm: '',
	};
	static propTypes = {
		containerId: PropTypes.string.isRequired,
		headerText: PropTypes.string,
		headerTag: PropTypes.string,
		preListText: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.node,
		]),
		items: PropTypes.array.isRequired,
		newLink: PropTypes.shape({
			text: PropTypes.string.isRequired,
			url: PropTypes.string.isRequired,
		}),
		itemIdProperty: PropTypes.any,
		itemDisplayTextProperty: PropTypes.any,
		lastMessage: PropTypes.instanceOf(Message),
		getItemDisplayText: PropTypes.func,
		getEditAction: PropTypes.func,
		getDeleteAction: PropTypes.func,
		getPrependActions: PropTypes.func,
		getAppendActions: PropTypes.func,
		hasPaginator: PropTypes.bool,
		maxDisplayItems: PropTypes.number,
	};

	static defaultProps = {
		headerText: '',
		headerTag: 'h1',
		preListText: null,
		itemDisplayTextProperty: null,
		getItemDisplayText: null,
		hasPaginator: false,
		maxDisplayItems: 10,
		getPrependActions: () => [],
		getAppendActions: () => [],
		getEditAction: () => [],
		getDeleteAction: () => [],
	};

	componentWillUnmount = () => {
		if(this.props.clearMessage){
			this.props.clearMessage();
		}
	}

	getDisplayText = item => {
		if (this.props.getItemDisplayText !== null) {
			return this.props.getItemDisplayText(item);
		} else if (this.props.itemDisplayTextProperty !== null) {
			return item[this.props.itemDisplayTextProperty];
		}

		throw new Error('No display text methods have been specified');
	};

	getActions = item => [].concat(
		this.props.getPrependActions(item),
		this.props.getEditAction(item),
		this.props.getDeleteAction(item),
		this.props.getAppendActions(item),
	);

	onSearch = e => {
		e.preventDefault();

		this.setState({ searchTerm: e.target.value });
	}

	renderItem = (item, i) => <ListItem key={'item' + i} item={item} text={this.getDisplayText(item)} actions={this.getActions(item)} />;

	doesItemMatchFilter = (item, filter) => {
		const pattern = makeRegExp(filter, 'i');

		if (typeof item === 'string' && pattern.test(item)) {
			return true;
		}

		return [
			item.name,
			item.username,
			item.customer,
			item.id,
		].filter(checkItem => checkItem).some(checkItem => pattern.test(checkItem));
	};

	renderListGroup = () => {
		let items = this.props.items;
		if (this.state.searchTerm) {
			items = items.filter(item => this.doesItemMatchFilter(item, this.state.searchTerm));
		}
		if (this.props.hasPaginator) {
			return <PaginatorContainer
				items={items}
				renderItem={this.renderItem}
				centered
			/>;
		}
		return items.map((item, i) => this.renderItem(item, i));
	}

	render() {
		return <div className={this.props.containerId}>
			<Container className="mb-4">
				<Form className="float-right w-50">
					<FormGroup className="mt-4">
						<Input type="text" placeholder="Zoeken" maxLength={maxInputLength} onChange={this.onSearch} autoFocus={true}/>
					</FormGroup>
				</Form>
				{this.props.headerText && React.createElement(this.props.headerTag, null, this.props.headerText)}

				{this.props.description && <p>{this.props.description}</p>}

				{this.props.newLink && <p><Link to={this.props.newLink.url}>{this.props.newLink.text}</Link></p>}

				{this.props.lastMessage && <Alert message={this.props.lastMessage} />}

				{this.props.preListText}

				<ListGroup className="mt-5">
					{this.renderListGroup()}
				</ListGroup>
			</Container>
		</div>;
	}
}
