import React from 'react';
import {connect} from 'react-redux';
import {Switch, Route} from 'react-router-dom';
import moment from 'moment';

import * as api from '../api';
import { history } from '../history';
import { setMiscDataset } from '../redux/actions/misc';
import { withLoader } from '../hoc/with-loader';
import ListItems from '../components/crud/ListItems';
import UpdateOrCreateItem from '../components/crud/UpdateOrCreateItem';
import RangedValueForm from '../components/ranged-value/RangedValueForm';
import {withMessages} from '../hoc/with-messages';

import {typeToInputNameMappings} from '../mappings/DatasetRangedValueText';
import { withPrompts } from '../hoc/with-prompts';

const validTypes = [
	'commission',
	'participation',
];

const typeMappings = {
	commission: 'Commissie',
	participation: 'Extra inzet',
};

const LIST_MESSAGE = 'LIST_MESSAGE';
const CREATE_MESSAGE = 'CREATE_MESSAGE';
const EDIT_MESSAGE = 'EDIT_MESSAGE';

class DatasetRangedValueContainer extends React.Component {
	componentDidMount() {
		if (!this.checkTypeValid()) {
			return history.goBack();
		}

		this.fetchDataset();
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.type !== prevProps.match.params.type && !this.checkTypeValid()) {
			history.goBack();
		}
	}

	componentWillUnmount() {
		this.props.dispatch(setMiscDataset(null));
	}

	checkTypeValid = () => {
		const {type} = this.props.match.params;

		return validTypes.includes(type);
	};

	fetchDataset = () => {
		const {id, datasetId} = this.props.match.params;

		api.fetchDataset(id, datasetId).then(dataset => {
			this.props.dispatch(setMiscDataset(dataset));
		}).catch(err => console.error(err));
	};

	onCreateSubmit = data => {
		const {id, datasetId, type} = this.props.match.params;

		const typeMappingText = typeMappings[type];

		api.addDatasetExtra(id, datasetId, type, data).then(() => {
			this.props.clearMessage(CREATE_MESSAGE);
			this.props.setMessage(LIST_MESSAGE, `${typeMappingText} toegevoegd`, `De ${typeMappingText.toLowerCase()} is successvol toegevoegd`, {
				color: 'success',
			});
			this.fetchDataset();
			history.push(`/partners/${id}/datasets/${datasetId}/${type}`);
		}).catch(err => {
			this.props.setMessage(CREATE_MESSAGE, `Kon ${typeMappingText.toLowerCase()} niet toevoegen`, err.response.data.message, {
				color: 'danger',
			});
		});
	};

	onUpdateSubmit = (data, itemId) => {
		const {id, datasetId, type} = this.props.match.params;
		const foundItem = this.props.dataset[type].find(item => item._id === itemId);
		const typeMappingText = typeMappings[type];

		if (!foundItem) {
			this.props.setMessage(EDIT_MESSAGE, `Kon ${typeMappingText.toLowerCase()} niet bewerken`, `Deze ${typeMappingText.toLowerCase()} kon niet worden gevonden`, {
				color: 'danger',
			});
		}

		const {start} = foundItem;

		delete data._id;

		api.updateDatasetExtra(id, datasetId, type, start, data.end, data).then(() => {
			this.props.clearMessage(EDIT_MESSAGE);
			this.props.setMessage(LIST_MESSAGE, `${typeMappingText} bewerkt`, `De ${typeMappingText.toLowerCase()} is successvol bewerkt`, {
				color: 'success',
			});
			this.fetchDataset();
			history.push(`/partners/${id}/datasets/${datasetId}/${type}`);
		}).catch(err => {
			this.props.setMessage(EDIT_MESSAGE, `Kon ${typeMappingText.toLowerCase()} niet bewerken`, err.response.data.message, {
				color: 'danger',
			});
		});
	};

	deleteItem = item => {
		const {id, datasetId, type} = this.props.match.params;
		const {start, end} = item;

		api.deleteDatasetExtra(id, datasetId, type, start, end).then(() => {
			this.fetchDataset();
			this.props.setMessage(LIST_MESSAGE, `${typeMappings[type]} verwijderd`, `De ${typeMappings[type].toLowerCase()} is succesvol verwijderd`, {
				color: 'success',
			});
		}).catch(err => {
			this.props.setMessage(LIST_MESSAGE, `Kon ${typeMappings[type]} niet verwijderen`, err.response.data.message, {
				color: 'danger',
			});
		});
	};

	renderItems = () => {
		const {type, id, datasetId} = this.props.match.params;
		const typeMappingText = typeMappings[type];

		return <ListItems
			containerId={`${this.props.match.params.type}Dataset`}
			headerText={`${typeMappingText} voor bestand ${this.props.dataset.name}`}
			items={this.props.dataset[this.props.match.params.type] ? this.props.dataset[this.props.match.params.type].slice().reverse() : []}
			newLink={{
				text: `Nieuwe ${typeMappingText.toLowerCase()}`,
				url: `/partners/${id}/datasets/${datasetId}/${type}/new`,
			}}
			getItemDisplayText={item => {
				const format = 'DD-MM-YYYY';
				const start = moment(item.start).format(format);
				const end = moment(item.end).format(format);

				const typeText = [
					item[typeToInputNameMappings[type]],
				];

				if (type === 'commission') {
					typeText.push(typeMappings[type].toLowerCase());
				}

				return <span><strong>{typeText.join(' ')}</strong> van <strong>{start}</strong> tot <strong>{end}</strong></span>;
			}}
			getEditAction={item => {
				return {
					icon: 'edit',
					link: `/partners/${id}/datasets/${datasetId}/${type}/${item._id}/edit`,
					tooltip: `Deze ${typeMappingText.toLowerCase()} bewerken`,
				};
			}}
			getDeleteAction={item => {
				return {
					icon: 'times',
					iconClass: 'text-danger',
					tooltip: `Deze ${typeMappingText.toLowerCase()} bewerken`,
					onClick: () => {
						const format = 'DD-MM-YYYY';
						const start = moment(item.start).format(format);
						const end = moment(item.end).format(format);

						this.props.prompt({
							title: `${typeMappings[type]} verwijderen?`,
							text: `Weet je zeker dat je de ${typeMappings[type].toLowerCase()} van ${start} tot ${end} wilt verwijderen?`,
							onConfirm: () => this.deleteItem(item),
						});
					},
				};
			}}
			lastMessage={this.props.messages[LIST_MESSAGE]}
		/>;
	};

	render = () => <Switch>
		<Route path="/partners/:id/datasets/:datasetId/:type/:extraId/edit" render={props => {
			const {type, extraId} = props.match.params;
			const typeMappingText = typeMappings[type];
			const foundItem = this.props.dataset && this.props.dataset[type].find(item => item._id === extraId);

			const form = <RangedValueForm
				action='edit'
				onSubmit={data => this.onUpdateSubmit(data, extraId)}
				inputText={typeMappingText}
				type={type}
				data={foundItem}
			/>;

			return <UpdateOrCreateItem
				containerId={`${typeMappingText}DatasetEdit`}
				headerText={`${typeMappingText} bewerken`}
				loaded={!!this.props.dataset}
				form={form}
				lastMessage={this.props.messages[EDIT_MESSAGE]}
			/>;
		}} />

		<Route path="/partners/:id/datasets/:datasetId/:type/new" render={() => {
			const {type} = this.props.match.params;
			const typeMappingText = typeMappings[type];

			const form = <RangedValueForm
				action='new'
				onSubmit={this.onCreateSubmit}
				inputText={typeMappingText}
				type={type}
			/>;

			return <UpdateOrCreateItem
				containerId={`${typeMappingText}DatasetCreate`}
				headerText={`Nieuwe ${typeMappingText.toLowerCase()}`}
				loaded
				form={form}
				lastMessage={this.props.messages[CREATE_MESSAGE]}
			/>;
		}} />

		<Route render={() => this.props.renderWithLoader(this.props.dataset, this.renderItems)} />
	</Switch>
}

const mapStateToProps = store => {
	return {
		dataset: store.misc.dataset,
	};
};

export default connect(mapStateToProps)(withLoader(withMessages(withPrompts(DatasetRangedValueContainer))));
