import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import TypeSelector from './TypeSelector';
import ContainsEditor from './ContainsEditor';
import InterestTypePicker from './InterestTypePicker';
import DatasetTypePicker from './DatasetTypePicker';
import AttributeEditor from './AttributeEditor';
import IntersectEditor from './IntersectEditor';

export default class LineEditor extends Component {
	renderEditor() {
		const { type, edit, token, interestTypes, labels, attributes, editType } = this.props;
		const line = editType === type ? token : null;
		switch (type) {
			case 'complement':
			case 'intersect':
				return <IntersectEditor attributes={attributes} edit={edit} line={line} type={type} />;
			case 'attribute':
				return <AttributeEditor attributes={attributes} edit={edit} line={line} />;
			case 'in':
			case 'dateRange':
			case 'match':
			case 'range':
				return <AttributeEditor attributes={attributes} edit={edit} line={line} type={type} />;
			case 'dataset':
				return <DatasetTypePicker types={labels} edit={edit} line={line} />;
			case 'interests':
				return <InterestTypePicker types={interestTypes} edit={edit} line={line} />;
			case 'contains':
				return <ContainsEditor edit={edit} line={line} />;
			default:
				return <TypeSelector edit={edit} />;
		}
	}

	render() {
		if(!this.props.edit) {
			return <Redirect to="/selection-builder" />;
		}
		return <Container className="lineEditor">
			{this.renderEditor()}
		</Container>;
	}
}
