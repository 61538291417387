import React from 'react';
import { FormGroup, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

export default function TypeSelector() {
	return <div>
		<FormGroup>
			<Link to="/selection-builder/add-line/attribute">
				<Button block color="primary">Selecteren op kenmerken</Button>
			</Link>
		</FormGroup>

		<FormGroup>
			<Link to="/selection-builder/add-line/dataset">
				<Button block color="primary">Label</Button>
			</Link>
		</FormGroup>

		<FormGroup>
			<Link to="/selection-builder/add-line/interests">
				<Button block color="primary">Interesse type</Button>
			</Link>
		</FormGroup>

		<FormGroup>
			<Link to="/selection-builder/add-line/contains">
				<Button block color="primary">Externe kenmerken</Button>
			</Link>
		</FormGroup>

		<FormGroup>
			<Link to="/selection-builder/add-line/intersect">
				<Button block color="primary">Bestandsmatch</Button>
			</Link>
		</FormGroup>

		<FormGroup>
			<Link to="/selection-builder/add-line/complement">
				<Button block color="primary">Bestand uitsluiten</Button>
			</Link>
		</FormGroup>
	</div>;
}
