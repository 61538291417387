import React, { Component } from 'react';
import { FormGroup, Col, Button, Form, Input } from 'reactstrap';

export default class ContainsEditor extends Component {
	constructor(props) {
		super(props);
		const { line } = props;
		const state = {
			selectedType: 'os',
			values: ['']
		};
		if(line) {
			state.selectedType = Object.keys(line)[0] || state.selectedType;
			state.values = (line[state.selectedType] || []).concat('');
		}
		this.state = state;
	}

	changeValue(i) {
		return e => {
			const values = this.state.values.slice();
			values[i] = e.target.value;
			if(i === values.length - 1 && values[i]) {
				values.push('');
			}
			this.setState({ values });
		};
	}

	onSubmit = e => {
		e.preventDefault();
		const values = this.state.values.map(value => value.trim()).filter(value => value);
		if(values.length) {
			this.props.edit({
				contains: {
					[this.state.selectedType]: values
				}
			});
		}
	};

	render() {
		const { line } = this.props;
		const { values } = this.state;
		return <div>
			<h1>Filter op externe waarden</h1>

			<Form onSubmit={this.onSubmit}>
				<FormGroup row>
					<Col xs={12} lg={2}>
						Type
					</Col>

					<Col xs={12} lg={4}>
						<Input type="select" onChange={e => this.setState({ selectedType: e.target.value })} value={this.state.selectedType}>
							<option value="os">Operating System</option>
							<option value="id">Solar Id</option>
							<option value="domain">Domain</option>
						</Input>
					</Col>
				</FormGroup>

				<FormGroup row>
					<Col xs={12} lg={2}>
						Waarde
					</Col>

					<Col xs={12} lg={4}>
						{values.map((value, i) => <FormGroup key={i}>
							<Input type="text" value={value} onChange={this.changeValue(i)} />
						</FormGroup>)}
					</Col>
				</FormGroup>

				<FormGroup>
					<Button type="submit" color="primary" disabled={!values.some(value => value)}>{line ? 'Opslaan' : 'Toevoegen'}</Button>
				</FormGroup>
			</Form>
		</div>;
	}
}
