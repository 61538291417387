import React, { Fragment } from 'react';
import moment from 'moment';
import {fetchPartners, fetchDatasets, importDataset, pollTask} from '../api';

import UploadFileForm from '../components/UploadFileForm';
import Message from '../messages/Message';
import { getResult } from '../util/Poller';

const pollTime = 2000;

export default class UploadFormContainer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			lastMessage: null,
			partners: [],
			datasets: null,
		};

		this.fetchDatasets = this.fetchDatasets.bind(this);
	}

	componentDidMount() {
		fetchPartners().then(partners => {
			this.setState({
				partners,
			});
		}).catch(err => {
			this.setState({
				lastMessage: new Message('Unable to fetch partners', err.message, {
					color: 'danger',
				}),
			});
		});
	}

	fetchDatasets(partnerId) {
		fetchDatasets(partnerId).then(datasets => {
			this.setState({
				datasets,
			});
		}).catch(err => {
			this.setState({
				lastMessage: new Message('Unable to fetch datasets', err.message, {
					color: 'danger',
				}),
			});
		});
	}

	uploadFile = (partnerId, datasetName, file) => {
		const getMessage = response => {
			try {
				const data = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;

				console.log(data);

				if ('message' in data) {
					return data.message;
				} else if ('errorMessage' in data) {
					return data.errorMessage;
				}

				return data;
			} catch (e) {
				console.warn(e);
				return response.data;
			}
		};

		this.setState({
			lastMessage: new Message('Aan het uploaden', `Bestand ${file.name} wordt geüpload, een moment geduld`, {
				color: 'info',
			}),
		});
		const start = moment();
		importDataset(partnerId, datasetName, file)
			.then(data => data.task)
			.then(taskId => {
				return getResult(() => pollTask(taskId), pollTime);
			})
			.then(data => {
				const { skipped, saved, read, deleted, unchanged } = data;
				const delta = moment.duration(moment().diff(start)).asMilliseconds();
				const lastMessage = new Message('Upload complete', <Fragment>
					<p>The upload of {file.name} was successful.</p>
					<dl className="row text-left">
						<dt className="col-6 col-lg-3">Rows</dt>
						<dd className="col-6 col-lg-9">{read}</dd>
						<dt className="col-6 col-lg-3">Saved</dt>
						<dd className="col-6 col-lg-9">{saved}</dd>
						<dt className="col-6 col-lg-3">Skipped</dt>
						<dd className="col-6 col-lg-9">{skipped}</dd>
						<dt className="col-6 col-lg-3">Deleted</dt>
						<dd className="col-6 col-lg-9">{deleted}</dd>
						<dt className="col-6 col-lg-3">Unchanged</dt>
						<dd className="col-6 col-lg-9">{unchanged}</dd>
						<dt className="col-6 col-lg-3">Took</dt>
						<dd className="col-6 col-lg-9">{delta} ms</dd>
					</dl>
				</Fragment>, {
					color: 'success',
				});

				this.setState({
					lastMessage,
				});
			})
			.catch(error => {
				this.setState({
					lastMessage: new Message(`Error while uploading ${file.name}`, getMessage(error.response), {
						color: 'danger',
					}),
				});
			});
	}

	render() {
		return <UploadFileForm
			lastMessage={this.state.lastMessage}
			uploadFile={this.uploadFile}
			partners={this.state.partners}
			datasets={this.state.datasets}
			fetchDatasets={this.fetchDatasets}
			partnerId={this.props.match.params.partnerId || ''}
			datasetId={this.props.match.params.datasetId || ''}
		/>;
	}
}
