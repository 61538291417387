import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default class EnrichmentUpload extends Component {
	onDrop = files => {
		if(files && files[0]) {
			this.props.uploadCsv(files[0]);
		}
	};

	render() {
		return <Dropzone
			onDrop={this.onDrop}
			accept=".csv"
			multiple={false}
			className="dropzone2">

			<h1>Drag & Drop</h1>
			<p>Een CSV bestand</p>
			<p>(of klik om een bestand te uploaden)</p>

			<FontAwesomeIcon
				className="icon-larger"
				icon="cloud-upload-alt"
				size="10x"
				color="grey"
			/>
		</Dropzone>;
	}
}
