import React, { Component } from 'react';
import Token from './Token';

export default class In extends Component {
	remove = () => {
		this.props.edit(null);
	};

	edit = () => {
		this.props.editLine(this.props.edit, 'in', this.props.token);
	};

	render() {
		return <div className="pt-1">{Object.keys(this.props.token).map(key => {
			return <Token key={key}
				edit={this.edit}
				remove={this.remove}
				value={`${key} in ${JSON.stringify(this.props.token[key])}`} />;
		})}</div>;
	}
}
