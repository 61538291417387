import React from 'react';
import { Container, Table, Input, FormGroup } from 'reactstrap';
import { Switch } from '../query-builder/SelectionSettings';
import { withLoader } from '../../hoc/with-loader';

function CountsComponent({ renderWithLoader, countData, useBlacklist, toggleBlacklist, partners, partner, setPartner }) {
	return renderWithLoader(countData, () => {
		let newTotal = 0;
		let inactiveTotal = 0;
		let opensTotal = 0;
		let clicksTotal = 0;
		let _total = 0;
		return <Container>
			<h1>Bestandsaantallen</h1>
			<label>Gebruik blacklist?</label>
			<FormGroup>
				<Switch value={useBlacklist} onChange={toggleBlacklist} />
			</FormGroup>
			<Input type="select" value={partner} onChange={setPartner}>
				<option value="">Alle partners</option>
				{partners.map(p => <option key={p} value={p}>{p}</option>)}
			</Input>
			<Table size="sm" className="mt-2">
				<thead>
					<tr>
						<th>Volgorde</th>
						<th>Naam</th>
						<th className="text-center">Actief</th>
						<th className="text-right">Extra inzet</th>
						<th className="text-right">Nieuw</th>
						<th className="text-right">Inactief</th>
						<th className="text-right">Opens</th>
						<th className="text-right">Kliks</th>
						<th className="text-right">Totaal</th>
					</tr>
				</thead>
				<tbody>
					{
						countData.map((row, i) => {
							newTotal += row.new;
							inactiveTotal += row.notOpenedOrClicked;
							opensTotal += row.opens;
							clicksTotal += row.clicks;
							_total += row.total;
							return <tr key={i}>
								<td>{row.dataset.sort}</td>
								<td>{row.dataset.name}</td>
								<td className="text-center"><input type="checkbox" disabled checked={row.dataset.active || false} /></td>
								<td className="text-right">{row.dataset.factor}</td>
								<td className="text-right">{row.new}</td>
								<td className="text-right">{row.notOpenedOrClicked}</td>
								<td className="text-right">{row.opens}</td>
								<td className="text-right">{row.clicks}</td>
								<td className="text-right">{row.total}</td>
							</tr>;
						})
					}
				</tbody>
				<thead>
					<tr>
						<th>Totalen:</th>
						<th colSpan={3}></th>
						<th className="text-right">{newTotal}</th>
						<th className="text-right">{inactiveTotal}</th>
						<th className="text-right">{opensTotal}</th>
						<th className="text-right">{clicksTotal}</th>
						<th className="text-right">{_total}</th>
					</tr>
				</thead>
			</Table>
		</Container>;
	});
}

export default withLoader(CountsComponent);
