import React from 'react';
import { Alert, Container, ListGroup, ListGroupItem } from 'reactstrap';
import { Link } from 'react-router-dom';

export default function ValueMappingsList({ fields, error }) {
	if(error) {
		return <Container>
			<Alert color="danger">{error}</Alert>
		</Container>;
	} else if(!fields) {
		return <Container>
			<p>Loading...</p>
		</Container>;
	}
	return <Container>
		<ListGroup>
			{fields.map(field => <ListGroupItem key={field}>
				<Link to={`/valuemapped/${encodeURIComponent(field)}`}>{field}</Link>
			</ListGroupItem>)}
		</ListGroup>
	</Container>;
}
