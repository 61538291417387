import React from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import PropTypes from 'prop-types';
import { history } from '../history';

import * as api from '../api';
import {setTypes} from '../redux/actions/types';
import ListTypes from '../components/types/ListTypes';
import NewType from '../components/types/NewType';
import {withMessages} from '../hoc/with-messages';

const TYPE_CREATE_ERROR = 'type-create-error';
const LIST_MESSAGE = 'type-list-message';

class TypeContainer extends React.Component {
	static propTypes = {
		setMessage: PropTypes.func.isRequired,
		messages: PropTypes.object.isRequired,
	};

	componentDidMount() {
		this.fetchTypes();
	}

	fetchTypes = () => {
		return api.fetchTypes().then(types => {
			this.props.dispatch(setTypes(types));
		}).catch(err => {
			this.props.setMessage(LIST_MESSAGE, 'Kon labels niet ophalen', err.response.data.message, {
				color: 'danger',
			});
		});
	};

	onCreateSubmit = data => {
		if (!data.type) {
			this.props.setMessage(TYPE_CREATE_ERROR, 'Ongeldige label', 'Label mag niet leeg zijn of alleen maar witruimte bevatten', {
				color: 'danger',
			});
			return;
		}

		api.createNewType(data)
			.then(() => {
				this.props.setMessage(LIST_MESSAGE, 'Label succesvol aangemaakt', 'De label is succesvol aangemaakt', {
					color: 'success',
				});
				this.fetchTypes().then(() =>
					history.push('/types')
				);
			})
			.catch(err => {
				this.props.setMessage(TYPE_CREATE_ERROR, 'Label kon niet worden opgeslagen', err.response.data.message, {
					color: 'danger',
				});
			});
	};

	deleteType = type => {
		api.deleteType(type)
			.then(() => {
				this.fetchTypes();
				this.props.setMessage(LIST_MESSAGE, 'Label is succesvol verwijderd', `Label '${type}' is successvol verwijderd`, {
					color: 'success',
				});
			})
			.catch(err => {
				this.props.setMessage(LIST_MESSAGE, 'Label kon niet worden verwijderd', err.response.data.message, {
					color: 'danger',
				});
			});
	};

	render() {
		return <Switch>
			<Route path="/types/new" render={() => <NewType onSubmit={this.onCreateSubmit} lastMessage={this.props.messages[TYPE_CREATE_ERROR]} />} />
			<Route path="/types" render={() => <ListTypes deleteType={this.deleteType} types={this.props.types} lastMessage={this.props.messages[LIST_MESSAGE]} />} />
		</Switch>;
	}
}

const mapStateToProps = store => {
	const {types} = store.types;

	return {
		types,
	};
};

export default connect(mapStateToProps)(withMessages(TypeContainer));
