import React from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import PropTypes from 'prop-types';

import * as api from '../api';
import { history } from '../history';
import ListItemsPaginated from '../components/crud/ListItemsPaginated';
import InterestTypeForm from '../components/interest-types/InterestTypeForm';
import {withMessages} from '../hoc/with-messages';
import {withLoader} from '../hoc/with-loader';
import { setInterestTypes } from '../redux/actions/interest-types';
import UpdateOrCreateItem from '../components/crud/UpdateOrCreateItem';
import {withPrompts} from '../hoc/with-prompts';

const LIST_MESSAGE = 'list-message';
const CREATE_MESSAGE = 'create-message';

class InterestTypeContainer extends React.Component {
	static propTypes = {
		setMessage: PropTypes.func.isRequired,
		messages: PropTypes.object.isRequired,
	};

	componentDidMount() {
		this.fetchAllTypes();
	}

	fetchAllTypes = () => {
		return api.fetchInterestTypes().then(types => {
			this.props.dispatch(setInterestTypes(types));
		}).catch(err => {
			this.props.setMessage(LIST_MESSAGE, 'Kon interesse types niet ophalen', err.response.data.message, {
				color: 'danger',
			});
		});
	};

	fetchTypes = (page, maxDisplay) => {
		return api.fetchPagInterestTypes(page, maxDisplay).then(types => {
			return types;
		}).catch(err => {
			this.props.setMessage(LIST_MESSAGE, 'Kon interesse types niet ophalen', err.response.data.message, {
				color: 'danger',
			});
		});
	}


	searchTypes = (page, maxDisplay, searchTerm) => {
		return api.fetchPagInterestTypesSearch(page, maxDisplay, searchTerm).then(types => {
			return types;
		}).catch(err => {
			this.props.setMessage(LIST_MESSAGE, 'Kon interesse types niet ophalen', err.response.data.message, {
				color: 'danger',
			});
		});
	}

	onCreateSubmit = data => {
		if (!data.name) {
			this.props.setMessage(CREATE_MESSAGE, 'Ongeldige interesse type', 'Interesse type naam mag niet leeg zijn of alleen maar witruimte bevatten', {
				color: 'danger',
			});
			return;
		}

		api.createClickInterestType(data)
			.then(() => {
				this.props.setMessage(LIST_MESSAGE, 'Interesse type succesvol aangemaakt', 'De interesse type is succesvol aangemaakt', {
					color: 'success',
				});
				this.fetchAllTypes().then(() =>
					history.push('/interest-types')
				);
			})
			.catch(err => {
				this.props.setMessage(CREATE_MESSAGE, 'Interesse type kon niet worden opgeslagen', err.response.data.message, {
					color: 'danger',
				});
			});
	};

	deleteType = type => {
		api.deleteClickInterestType(type)
			.then(() => {
				this.fetchAllTypes();
				this.props.setMessage(LIST_MESSAGE, 'Interesse type is succesvol verwijderd', `Interesse type '${type}' is successvol verwijderd`, {
					color: 'success',
				});
			})
			.catch(err => {
				this.props.setMessage(LIST_MESSAGE, 'Interesse type kon niet worden verwijderd', err.response.data.message, {
					color: 'danger',
				});
			});
	};

	render() {
		return <Switch>
			<Route path="/interest-types/new" render={() => {
				const form = <InterestTypeForm
					onSubmit={this.onCreateSubmit}
				/>;

				return <UpdateOrCreateItem
					containerId="newClickInterestType"
					headerText="Nieuwe klikinteresse"
					lastMessage={this.props.messages[CREATE_MESSAGE]}
					form={form}
					loaded
				/>;
			}} />
			<Route path="/interest-types" render={() => this.props.renderWithLoader(this.props.interestTypes, () => <ListItemsPaginated
				containerId="listInterestTypes"
				headerText="Lijst van interesse types"
				fetchItems={this.fetchTypes}
				fetchItemsSearch={this.searchTypes}
				nothingFoundMessage="Geen interesses types gevonden"
				LoadingMessage="Interesse types ophalen..."
				maxDisplayItems={10}
				newLink={{
					text: 'Nieuwe klikinteresse',
					url: '/interest-types/new',
				}}
				getItemDisplayText={type => type}
				getDeleteAction={type => {
					return {
						icon: 'times',
						iconClass: 'text-danger',
						onClick: () => this.props.prompt({
							title: 'interest type verwijderen?',
							text: `Weet je zeker dat je interest type '${type}' wilt verwijderen?`,
							onConfirm: () => this.deleteType(type),
						}),
						tooltip: `${type} verwijderen`,
					};
				}}
				lastMessage={this.props.messages[LIST_MESSAGE]}
			/>)} />
		</Switch>;
	}
}

const mapStateToProps = store => {
	const {interestTypes} = store.interestTypes;

	return {
		interestTypes,
	};
};
export default connect(mapStateToProps)(withMessages(withLoader(withPrompts(InterestTypeContainer))));
