import React from 'react';
import ListItems from '../crud/ListItems';

const LIST_MESSAGE = 'LIST_MESSAGE';

export default function DomainBlacklist(props) {
	return props.renderWithLoader(props.domains, () => <div className="container">
		<ListItems
			containerId="blacklist"
			headerText="Domein blacklist"
			items={props.domains}
			newLink={{
				text: 'Upload CSV bestand',
				url: '/blacklist/upload',
			}}
			hasPaginator={true}
			getItemDisplayText={domain => domain}
			getDeleteAction={domain => {
				return {
					icon: 'times',
					iconClass: 'text-danger',
					onClick: () => props.prompt({
						title: 'Verwijder van blacklist?',
						text: `Weet je zeker dat je ${domain} van de blacklist wilt verwijderen?`,
						onConfirm: () => props.deleteDomain(domain).then(() => {
							props.setMessage(LIST_MESSAGE, 'Domein verwijderd', `Het domein ${domain} is verwijderd uit de blacklist`, {
								color: 'success',
							});
							props.getDomainBlacklist();
						}).catch(err => {
							console.log(err);
						})
					})
				};
			}}
			lastMessage={props.messages[LIST_MESSAGE]}
			clearMessage={props.clearMessage}
		/>
	</div>);
}
