import React, { Component } from 'react';
import { Container } from 'reactstrap';
import Papa from 'papaparse';
import { connect } from 'react-redux';
import EnrichmentUpload from '../components/enrichment/EnrichmentUpload';
import EnrichmentForm from '../components/enrichment/EnrichmentForm';
import Alert from '../components/alert/Alert';
import Message from '../messages/Message';
import * as api from '../api';
import { setGlobalMappings } from '../redux/actions/mapping-editor';
import { getData } from '../util/Poller';

const POLL_INTERVAL = 1000;

class EnrichmentUploadContainer extends Component {
	state = {
		file: null,
		error: null,
		name: '',
		mappings: [],
		uploading: false
	};

	uploadCsv = file => {
		this.setState({ error: null });
		Papa.parse(file, {
			preview: 1,
			header: true,
			complete: res => {
				this.setState({
					file,
					name: file.name,
					mappings: res.meta.fields.map(field => {
						return {
							field, mapped: '', match: false
						};
					})
				});
			},
			error: err => {
				this.setState({
					error: new Message('Failed to parse CSV', err.message, { color: 'danger' })
				});
			}
		});
	};

	setName = name => {
		this.setState({ name });
	};

	setMappings = mappings => {
		this.setState({ mappings });
	};

	submit = () => {
		const mappings = [];
		this.state.mappings.forEach(mapping => {
			if(mapping.mapped) {
				mappings.push(mapping);
			}
		});
		if(mappings.length) {
			this.setState({
				uploading: true,
				error: new Message('Uploading', 'Het bestand wordt geüpload.')
			});
			const { name, file } = this.state;
			api.uploadEnrichmentFile(name, file, mappings).then(({ task }) => {
				return getData(() => api.pollTask(task), POLL_INTERVAL);
			}).then(() => {
				this.setState({
					uploading: false,
					file: null,
					name: '',
					mappings: [],
					error: new Message('Uploading', 'Het bestand is geüpload.', { color: 'success' })
				});
			}).catch(err => {
				this.setState({
					uploading: false,
					error: new Message('Error', err.response && err.response.data.message || err.message, { color: 'danger' })
				});
			});
		}
	};

	componentDidMount() {
		api.fetchGlobalDatasetHeaderMappings().then(mappings => {
			this.props.dispatch(setGlobalMappings(mappings));
		}).catch(err => {
			this.setState({
				error: new Message('Unable to fetch attributes', err.response.data.message, { color: 'danger' })
			});
		});
	}

	render() {
		const { file, error, name, mappings, uploading } = this.state;
		return <Container>
			{error && <Alert message={error} />}
			{!file && <EnrichmentUpload uploadCsv={this.uploadCsv} />}
			{file && <EnrichmentForm
				name={name}
				submit={this.submit}
				mappings={mappings}
				uploading={uploading}
				setName={this.setName}
				setMappings={this.setMappings}
				attributes={this.props.globalMappings} />}
		</Container>;
	}
}

export default connect(({ mappingEditor }) => {
	const { globalMappings } = mappingEditor;
	return { globalMappings };
})(EnrichmentUploadContainer);
