import React, { Fragment } from 'react';
import { Input } from 'reactstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function toOption(item) {
	return {
		value: item._id,
		label: item.name
	};
}

export default function SelectCampaign({ campaigns, value, onChange, multiple, days, setDays }) {
	let select;
	if(!campaigns) {
		select = <Fragment>
			<FontAwesomeIcon icon="cog" spin /> Campagnes zijn aan het laden...
		</Fragment>;
	} else if(multiple) {
		select = <Select isMulti isSearchable options={campaigns.map(toOption)} value={value} onChange={onChange} />;
	} else {
		select = <Input type="select" value={value} onChange={e => onChange(e.target.value)}>
			<option value="" disabled>Selecteer een campagne</option>
			{campaigns.map(c => {
				return <option key={c._id} value={c._id}>{c.name}</option>;
			})}
		</Input>;
	}
	return <Fragment>
		{select}
		<p className="my-1 small text-muted">Toon alleen campagnes uit de laatste <input
			className="form-control d-inline w-auto form-control-sm"
			type="number" value={days} onChange={e => setDays(e.target.value)} /> dagen</p>
	</Fragment>;
}
