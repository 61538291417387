import React from 'react';
import {Input} from 'reactstrap';

export default function Filter(props) {
	return <Input
		className="mb-3"
		type="text"
		onChange={props.onChange}
		value={props.value}
	/>;
}
