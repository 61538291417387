import React from 'react';
import PropTypes from 'prop-types';
import {Form, FormGroup, Button} from 'reactstrap';
import PartnerField from './PartnerField';
import {withFormUtils} from '../../hoc/with-form-utils';

class PartnerTestPersonForm extends React.Component {
	static propTypes = {
		onSubmit: PropTypes.func.isRequired,
	};

	componentDidMount() {
		this.props.setDefaultValue({
			gender: 'M'
		});
	}

	render() {
		const {onFormSubmit, onInputChange, getdata} = this.props;

		return <Form onSubmit={onFormSubmit}>
			<PartnerField label="Voornaam" name="firstName" onChange={onInputChange} getdata={getdata} />
			<PartnerField label="Tussenvoegsel" name="preposition" onChange={onInputChange} getdata={getdata} />
			<PartnerField label="Achternaam" name="lastName" onChange={onInputChange} getdata={getdata} />
			<PartnerField type="select" label="Geslacht" name="gender" onChange={onInputChange} getdata={getdata}>
				<option value="M">Man</option>
				<option value="F">Vrouw</option>
				<option value="U">Onbekend</option>
			</PartnerField>
			<PartnerField label="E-mail" name="email" onChange={onInputChange} getdata={getdata} />

			<FormGroup>
				<Button color="primary" type="submit">Submit</Button>
			</FormGroup>
		</Form>;
	}
}

export default withFormUtils(PartnerTestPersonForm);
