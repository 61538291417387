import React from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import {Container} from 'reactstrap';
import PartnerForm from './PartnerForm';
import Message from '../../messages/Message';
import Alert from '../alert/Alert';

export default class UpdatePartner extends React.Component {
	static propTypes = {
		partnerId: PropTypes.string.isRequired,
		fetchPartner: PropTypes.func.isRequired,
		onSubmit: PropTypes.func.isRequired,
		onDelete: PropTypes.func,
	};

	static defaultProps = {
		onDelete: null,
	};

	constructor(props) {
		super(props);

		this.state = {
			partner: null,
			errorMessage: null,
			onlyShowError: false,
			doRedirect: false,
		};

		this.getContact = this.getPartner.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onDelete = this.onDelete.bind(this);
	}

	componentDidMount() {
		this.getPartner();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.partnerId !== this.props.partnerId) {
			this.getPartner();
		}
	}

	getPartner() {
		this.setState({
			errorMessage: null,
			onlyShowError: false,
		});

		this.props.fetchPartner(this.props.partnerId).then(partner => {

			if(partner.vatPercentage < 1) {
				const hundredPercent = 100;
				partner.vatPercentage = partner.vatPercentage * hundredPercent;
			}

			this.setState({
				partner,
			});
		}).catch(err => {
			this.setState({
				errorMessage: new Message('An error occured while fetching partner', err.message, {
					color: 'danger',
				}),
				onlyShowError: true,
			});
		});
	}

	onSubmit(partner) {
		this.setState({
			onlyShowError: false,
			errorMessage: null,
		});

		if(partner.vatPercentage >= 1) {
			const hundredPercent = 100;
			partner.vatPercentage = partner.vatPercentage / hundredPercent;
		}

		this.props.onSubmit(this.props.partnerId, partner).then(() => {
			this.setState({
				doRedirect: true,
			});
		}).catch(err => {
			this.setState({
				errorMessage: new Message('An error occured while saving partner', err.message, {
					color: 'danger',
				}),
			});
		});
	}

	onDelete() {
		if (typeof this.props.onDelete === 'function') {
			this.props.onDelete(this.props.partnerId).then(() => {
				this.setState({
					doRedirect: true,
				});
			}).catch(err => {
				this.setState({
					errorMessage: new Message('An error occured while deleting partner', err.message, {
						color: 'danger',
					}),
				});
			});
		}
	}

	render() {
		if (this.state.doRedirect) {
			return <Redirect to="/partners" />;
		}

		const alert = this.state.errorMessage && <Alert message={this.state.errorMessage} />;

		if (this.state.onlyShowError) {
			return alert;
		}

		if (!this.state.partner) {
			return 'Loading partner...';
		}

		return <div id="updatePartner">
			<Container>
				<h1>Update partner {this.state.partner.name}</h1>

				{alert}

				<PartnerForm
					onSubmit={this.onSubmit}
					data={this.state.partner}
					idIsReadReadOnly
				/>
			</Container>
		</div>;
	}
}
