import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import ListItems from '../components/crud/ListItems';
import NewExportColumn from '../components/mailings/NewExportColumn';
import { getColumns, deleteColumn, addColumn, fetchGlobalDatasetHeaderMappings } from '../api';
import { setGlobalMappings } from '../redux/actions/mapping-editor';
import { withPrompts } from '../hoc/with-prompts';

function sort(col) {
	return (lhs, rhs) => {
		const a = col ? lhs[col] : lhs;
		const b = col ? rhs[col] : rhs;
		return a.localeCompare(b);
	};
}

class ColumnsContainer extends Component {
	state = {
		columns: null
	};

	fetchColumns = () => {
		getColumns().then(columns => {
			columns.sort(sort('_id')).forEach(c => {
				c.name = c._id;
			});
			this.setState({ columns });
		}).catch(err => {
			console.error('Failed to get columns', err);
		});
	}

	componentDidMount() {
		this.fetchColumns();
		fetchGlobalDatasetHeaderMappings().then(data => {
			this.props.dispatch(setGlobalMappings(data.sort(sort())));
		});
	}

	renderList = () => {
		const { columns } = this.state;
		if(!columns) {
			return null;
		}
		return <ListItems
			containerId="csv-columns"
			headerText="CSV Export kolommen"
			items={columns}
			newLink={{
				text: 'Nieuwe kolom',
				url: '/csv-columns/new',
			}}
			hasPaginator={true}
			getItemDisplayText={column => <Row className="w-75">
				<Col>{column._id}</Col>
				<Col>{column.type || 'string'}</Col>
			</Row>}
			getDeleteAction={column => {
				return {
					icon: 'times',
					iconClass: 'text-danger',
					onClick: () => this.props.prompt({
						title: 'Verwijder',
						text: `Weet je zeker dat je ${column._id} wilt verwijderen?`,
						onConfirm: () => deleteColumn(column._id).then(() => {
							this.fetchColumns();
						}).catch(err => {
							console.log(err);
						}),
					}),
				};
			}}
		/>;
	}

	add = column => {
		return addColumn(column).then(() => {
			this.fetchColumns();
		});
	};

	render() {
		const { globalMappings } = this.props;
		return <Switch>
			<Route path="/csv-columns/new" render={() => <NewExportColumn globalMappings={globalMappings} add={this.add} />} />
			<Route render={this.renderList} />
		</Switch>;
	}
}

export default connect(({ mappingEditor }) => {
	return { globalMappings: mappingEditor.globalMappings };
})(withPrompts(ColumnsContainer));
