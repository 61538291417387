import React from 'react';
import PropTypes from 'prop-types';

import Message from '../../messages/Message';
import {withPrompts} from '../../hoc/with-prompts';
import {withLoader} from '../../hoc/with-loader';
import ListItems from '../crud/ListItems';

class ListPartnerContacts extends React.Component {
	static propTypes = {
		partnerId: PropTypes.string.isRequired,
		fetchPartner: PropTypes.func.isRequired,
		onDelete: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			partner: null,
		};
	}

	componentDidMount() {
		this.getPartner();
	}

	getPartner = () => {
		this.props.fetchPartner(this.props.partnerId).then(partner => {
			this.setState({
				partner,
			});
		}).catch(err => console.error(err));
	}

	onDeleteClick = (e, partnerId, contact) => {
		if (e.nativeEvent) {
			e.nativeEvent.preventDefault();
			e.nativeEvent.stopPropagation();
		}

		e.preventDefault();
		e.stopPropagation();

		const contactId = contact.id;
		const contactName = [ contact.firstName, contact.preposition, contact.lastName ].join(' ');
		this.props.prompt({
			title: `Delete partner contact ${contactName}?`,
			text: `Are you sure you want to delete partner contact ${contactName}?`,
			onConfirm: () => {
				this.setState({
					lastMessage: null,
				});

				this.props.onDelete(partnerId, contactId).then(() => {
					this.getPartner();
					this.setState({
						lastMessage: new Message('Success', 'Contact has been deleted', {
							color: 'success',
						}),
					});
				}).catch(err => {
					this.setState({
						lastMessage: new Message('An error occured while deleting contact', err.message, {
							color: 'danger',
						}),
					});
				});
			},
		});

		return false;
	}

	renderList = () => <ListItems
		containerId="listContacts"
		headerText={`Contacten van ${this.state.partner.name}`}
		items={this.state.partner.contactPersons}
		newLink={{
			text: 'Nieuw contact',
			url: `/partners/${this.state.partner.id}/contacts/new`,
		}}
		getItemDisplayText={contact => [ contact.firstName, contact.preposition, contact.lastName ].join(' ')}
		getEditAction={contact => {
			return {
				icon: 'edit',
				link: `/partners/${this.props.partnerId}/contacts/${contact.id}/edit`,
				tooltip: 'Dit contact bewerken',
			};
		}}
		getDeleteAction={contact => {
			return {
				icon: 'times',
				onClick: e => this.onDeleteClick(e, this.props.partnerId, contact),
				tooltip: 'Dit contact verwijderen',
			};
		}}
		lastMessage={this.state.lastMessage}
	/>;

	render = () => {
		const {partner} = this.state;

		let contacts = null;

		if (partner) {
			contacts = partner.contactPersons;
		}

		return this.props.renderWithLoader(contacts, this.renderList);
	}
}

export default withPrompts(withLoader(ListPartnerContacts));
