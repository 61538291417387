import React, { Component } from 'react';

import { parseQuery } from '../QueryBuilder';

export default class Not extends Component {
	edit = query => {
		if(query) {
			if(query.not) {
				return this.props.edit(query.not);
			}
			this.props.edit({ not: query });
		} else {
			this.props.edit(null);
		}
	};

	render() {
		return <fieldset className="border container-fluid pb-2 border-danger">
			<legend className="m-0 w-auto text-monospace small">&not;</legend>
			{parseQuery(this.props.token, this.edit, this.props.editLine)}
		</fieldset>;
	}
}
