import React, { Component } from 'react';
import * as api from '../api';
import EnrichmentList from '../components/enrichment/EnrichmentList';

export default class EnrichmentContainer extends Component {
	state = {
		files: []
	};

	getFiles() {
		api.getEnrichmentFiles().then(files => {
			files.sort((lhs, rhs) => {
				if(typeof lhs.date === 'string') {
					lhs.date = new Date(lhs.date);
				}
				if(typeof rhs.date === 'string') {
					rhs.date = new Date(rhs.date);
				}
				if(lhs.date > rhs.date) {
					return 1;
				} else if(rhs.date < lhs.date) {
					return -1;
				}
				return 0;
			});
			this.setState({ files });
		}).catch(err => {
			console.warn('Failed to get enrichment files', err);
		});
	}

	componentDidMount() {
		this.getFiles();
	}

	deleteFile = file => {
		api.deleteFile(file._id).then(() => {
			this.getFiles();
		}).catch(err => {
			console.warn('Failed to delete file', err);
		});
	};

	render() {
		return <EnrichmentList files={this.state.files} deleteFile={this.deleteFile} />;
	}
}
