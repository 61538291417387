import React from 'react';
import {Form, FormGroup, Label, Input, Button} from 'reactstrap';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';

import {withFormUtils} from '../../hoc/with-form-utils';
import {typeToInputNameMappings} from '../../mappings/DatasetRangedValueText';

class RangedValueForm extends React.Component {
	static propTypes = {
		inputText: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
	};

	componentDidMount() {
		const date = new Date().toISOString();

		this.props.setDefaultValue({
			start: date,
			end: date,
		});
	}

	componentDidUpdate() {
		const {getdata, onInputChange} = this.props;
		const start = getdata('start');
		const end = getdata('end');

		const realEndDate = end ? new Date(end) : new Date();

		if (start && new Date(start).getTime() > realEndDate.getTime()) {
			onInputChange({
				target: {
					name: 'end',
					value: new Date(start).toISOString(),
				},
			});
		}
	}

	render = () => {
		const {onFormSubmit, onInputChange, onNumberInputChange, getdata} = this.props;
		const defaultStart = new Date().toISOString();
		const realStart = getdata('start');
		const actualStart = realStart !== '' ? realStart : defaultStart;

		const defaultEnd = new Date().toISOString();
		const realEnd = getdata('end');
		const actualEnd = realEnd !== '' ? realEnd : defaultEnd;

		const inputName = typeToInputNameMappings[this.props.type];

		return <Form onSubmit={onFormSubmit}>
			<FormGroup>
				<Label>Start datum</Label>
				<DatePicker
					disabled={this.props.action === 'edit' ? true : false}
					className="form-control"
					dropdownMode="scroll"
					dateFormat="dd-MM-yyyy"
					selected={new Date(actualStart)}
					onChange={date => {
						onInputChange({
							target: {
								name: 'start',
								value: date.toISOString(),
							},
						});
					}}
				/>
			</FormGroup>

			<FormGroup>
				<Label>Eind datum</Label>
				<DatePicker
					className="form-control"
					dropdownMode="scroll"
					dateFormat="dd-MM-yyyy"
					minDate={new Date(actualStart)}
					selected={new Date(actualEnd)}
					onChange={date => {
						onInputChange({
							target: {
								name: 'end',
								value: date.toISOString(),
							},
						});
					}}
				/>
			</FormGroup>

			<FormGroup>
				<Label>{this.props.inputText}</Label>
				<Input type="text" name={inputName} value={getdata(inputName)} onChange={onNumberInputChange} />
			</FormGroup>

			<FormGroup>
				<Button type="submit" color="primary" disabled={!getdata(inputName)}>Opslaan</Button>
			</FormGroup>
		</Form>;
	};
}

export default withFormUtils(RangedValueForm);
