import React, { Component } from 'react';
import Token from './Token';

export function stringify(fields) {
	return `["${fields.map(field => {
		if(typeof field === 'object') {
			return `solar.${field.mapped}`;
		}
		return field;
	}).join('", "')}"]`;
}

export default class Intersect extends Component {
	remove = () => {
		this.props.edit(null);
	};

	edit = () => {
		this.props.editLine(this.props.edit, 'intersect', this.props.token);
	};

	negate = () => {
		this.props.edit({ complement: this.props.token });
	};

	render() {
		let value;
		if(this.props.token._file) {
			value = `match met ${this.props.token._file.name}`;
		} else if(this.props.token.file) {
			value = `Bestaande match met ${this.props.token.file} op ${stringify(this.props.token.fields)}`;
		} else {
			value = `Bestaande match op ${stringify(this.props.token.fields)}`;
		}
		return <Token
			edit={this.edit}
			remove={this.remove}
			negate={this.negate}
			value={value} />;
	}
}
