import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getConnectorStatus, getVersion } from '../api';
import Sidebar from '../components/Sidebar';
const SLEEP = 2000;
let timeout = 0;

class SidebarContainer extends Component {
	state = {
		status: 'unknown',
		version: null
	};

	componentDidMount() {
		if(this.props.account) {
			this.updateStatus();
			this.getVersion();
		}
	}

	componentDidUpdate(prevProps) {
		if(!prevProps.account && this.props.account) {
			this.updateStatus();
			this.getVersion();
		}
	}

	updateStatus = () => {
		if(this.props.account) {
			getConnectorStatus().then(data => {
				this.setState({ status: data.status });
			}).catch(err => {
				console.warn('Failed to get mongo connector status', err);
			}).then(() => {
				timeout = setTimeout(this.updateStatus, SLEEP);
			});
		}
	}

	getVersion() {
		getVersion().then(version => {
			this.setState({ version });
		}).catch(err => {
			console.warn('Failed to get version', err);
		});
	}

	componentWillUnmount() {
		clearTimeout(timeout);
	}

	render() {
		const { account, menuState } = this.props;
		const { status, version } = this.state;
		return <Sidebar
			isLoggedIn={account !== null}
			isMenuOpen={menuState === 'open'}
			connectorStatus={status}
			version={version}
		/>;
	}
}

const mapStateToProps = store => {
	const {account} = store.auth;
	const {menu} = store.misc;

	return {
		account,
		menuState: menu,
	};
};

export default connect(mapStateToProps)(SidebarContainer);
