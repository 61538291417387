import React, { Fragment } from 'react';
import { Container, Button, Table, Alert, FormGroup } from 'reactstrap';
import HttpStatus from 'http-status-codes';
import { ProgressBar } from '../tasks/Task';

function parseResult(result) {
	if(result.check === HttpStatus.OK) {
		return 'Gevonden';
	} else if(result.check === HttpStatus.NO_CONTENT) {
		return 'Geen MX';
	} else if(result.check === HttpStatus.NOT_FOUND) {
		return 'Niet gevonden';
	} else if(result.check === HttpStatus.BAD_REQUEST) {
		return 'Ongeldig';
	}
	return 'Error';
}

export default function DNSCheck({ checking, check, results, error, selected, select, blacklist, adding, state }) {
	let all = true;
	let any = false;
	const rows = results && results.map(result => {
		const message = parseResult(result);
		if(selected[result.domain]) {
			any = true;
		} else {
			all = false;
		}
		return <tr key={result.domain}>
			<td><input type="checkbox" checked={selected[result.domain]} onChange={e => select({ [result.domain]: e.target.checked })} /></td>
			<td>{result.domain}</td>
			<td>{result.count}</td>
			<td>{message}</td>
			<td>{result.error || ''}</td>
		</tr>;
	});
	return <Fragment>
		<Container className="mb-4">
			<h1>DNS Check</h1>
			{checking && state && <FormGroup>
				<ProgressBar {...state} />
			</FormGroup>}
			<FormGroup>
				<Button color="primary" onClick={check} disabled={checking}>Start</Button>
			</FormGroup>
			{results && <FormGroup>
				<Button color="warning" onClick={blacklist} disabled={!any || adding}>Voeg toe aan blacklist</Button>
			</FormGroup>}
			{error && <Alert color="danger">{error}</Alert>}
			{results && <Table>
				<thead>
					<tr>
						<th><input type="checkbox" checked={all} onChange={e => {
							const replacement = {};
							results.forEach(result => {
								replacement[result.domain] = e.target.checked;
							});
							select(replacement);
						}} /></th>
						<th>Domein</th>
						<th>Adressen</th>
						<th>Resultaat</th>
						<th>Error</th>
					</tr>
				</thead>
				<tbody>
					{rows}
				</tbody>
			</Table>}
		</Container>
	</Fragment>;
}
