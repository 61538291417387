import React, { Component } from 'react';
import Token from './Token';
import { stringify } from './Intersect';

export default class Complement extends Component {
	remove = () => {
		this.props.edit(null);
	};

	edit = () => {
		this.props.editLine(this.props.edit, 'complement', this.props.token);
	};

	negate = () => {
		this.props.edit({ intersect: this.props.token });
	};

	render() {
		let value;
		if(this.props.token._file) {
			value = `uitsluiting met ${this.props.token._file.name}`;
		} else if(this.props.token.file) {
			value = `Bestaande uitsluiting met ${this.props.token.file} op ${stringify(this.props.token.fields)}`;
		} else {
			value = `Bestaand uitsluiting op ${stringify(this.props.token.fields)}`;
		}
		return <Token
			edit={this.edit}
			remove={this.remove}
			negate={this.negate}
			value={value} />;
	}
}
