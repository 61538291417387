import React from 'react';
import PropTypes from 'prop-types';
import {Container} from 'reactstrap';
import Alert from '../alert/Alert';
import CampaignForm from './CampaignForm';
import Message from '../../messages/Message';

import {withLoader} from '../../hoc/with-loader';

class UpdateCampaign extends React.Component {
	static propTypes = {
		onSubmit: PropTypes.func.isRequired,
		lastMessage: PropTypes.instanceOf(Message),
		types: PropTypes.array,
		fetchTypes: PropTypes.func.isRequired,
		fetchCampaign: PropTypes.func.isRequired,
		clearCampaign: PropTypes.func.isRequired,
		campaign: PropTypes.object,
	};

	componentDidMount() {
		this.props.fetchTypes();
		this.props.fetchCampaign();
	}

	onSubmit(data) {
		this.props.onSubmit(this.props.campaign._id, data);
	}

	render = () => this.props.renderWithLoader(this.props.campaign, () => <div id="updateCampaign">
		<Container>
			<h1>Update campagne</h1>

			{this.props.lastMessage && <Alert message={this.props.lastMessage} />}

			<CampaignForm onSubmit={this.onSubmit.bind(this)} types={this.props.types} data={this.props.campaign} />
		</Container>
	</div>);
}

export default withLoader(UpdateCampaign);
