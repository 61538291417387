import React from 'react';
import PropTypes from 'prop-types';
import {Container, Form, FormGroup, Label, Col, Button, Input} from 'reactstrap';
import Select from 'react-select';

import {withLoader} from '../../hoc/with-loader';
import {withMessages} from '../../hoc/with-messages';
import {campaignPropTypes} from '../../prop-types/campaign';
import { isortName } from '../../util/array';
import Message from '../../messages/Message';
import Alert from '../alert/Alert';

const MAILING_FORM_MESSAGE = 'MAILING_FORM_MESSAGE';

const mailingAll = { value: 'all', label: 'Alle verzendingen' };

const types = [
	{ value: 'opens', label: 'Geopende' },
	{ value: 'clicks', label: 'Geklikte' },
	{ value: 'notOpenedOrClicked', label: 'Niet geopende of geklikte' },
	{ value: 'hardBounce', label: 'Hard bounced' },
	{ value: 'softBounce', label: 'Zacht bounced' },
	{ value: 'rejected', label: 'Geweigerde' },
];

class CopyMailingForm extends React.Component {
	static propTypes = {
		fetchCampaigns: PropTypes.func.isRequired,
		campaigns: PropTypes.arrayOf(campaignPropTypes),
		selectedCampaign: campaignPropTypes.isRequired,
		selectedMailingName: PropTypes.string.isRequired,
		onSubmit: PropTypes.func.isRequired,
		lastMessage: PropTypes.instanceOf(Message),
	};

	constructor(props) {
		super(props);

		this.state = {
			selectedCampaign: props.selectedCampaign,
			wasCampaignSetManually: false,
			selectedMailing: mailingAll,
			selectedType: types[0],
			description: '',
			wasDescriptionSetManually: false,
		};
	}

	componentDidMount() {
		this.props.fetchCampaigns();

		this.setState({
			description: this.createDescription(),
		});
	}

	componentDidUpdate(prevProps) {
		if (!this.state.wasCampaignSetManually && this.props.selectedCampaign !== prevProps.selectedCampaign) {
			const state = {
				selectedCampaign: this.props.selectedCampaign,
			};

			if (!this.state.wasDescriptionSetManually) {
				state.description = this.createDescription({
					campaign: this.props.selectedCampaign,
				});
			}

			this.setState(state);
		}
	}

	getCampaignOptions() {
		return isortName(this.props.campaigns).map(campaign => this.getOption(campaign));
	}

	getOption(item) {
		return { value: item._id, label: item.name };
	}

	getMailingOptions = () => [ mailingAll ].concat(isortName(this.state.selectedCampaign.mailings).map(mailing => this.getOption(mailing)));

	canSubmit = () => {
		const {selectedCampaign, selectedMailing, selectedType} = this.state;

		return ![ selectedCampaign, selectedMailing, selectedType ].includes(null);
	};

	createDescription = (params = {}) => {
		const {campaign, mailing, type} = Object.assign({}, {
			campaign: this.state.selectedCampaign,
			mailing: this.state.selectedMailing,
			type: this.state.selectedType,
		}, params);

		let mailingText = mailing.label;

		if (mailingText === mailingAll.label) {
			mailingText = mailingText.toLowerCase();
		}

		return `${type.label} e-mails van ${mailingText} in ${campaign.name}`;
	}

	onSubmit = e => {
		e.preventDefault();

		if (this.canSubmit()) {
			const {selectedCampaign, selectedMailing, description, selectedType} = this.state;

			const data = {
				campaign: selectedCampaign._id,
				description,
				type: selectedType.value,
			};

			if (selectedMailing !== mailingAll) {
				data.mailing = selectedMailing.value;
			}

			this.props.onSubmit(data);
		}

		return false;
	}

	renderForm = () => <Form onSubmit={this.onSubmit}>
		<FormGroup row>
			<Col xs={12} md={3}>
				<Label>Doel verzending</Label>
			</Col>

			<Col xs={12} md={9} lg={6}>{this.props.selectedMailingName}</Col>
		</FormGroup>

		<FormGroup row>
			<Col xs={12} md={3}>
				<Label>Campagne</Label>
			</Col>

			<Col xs={12} md={9} lg={6}>
				<Select
					options={this.getCampaignOptions()}
					value={this.getOption(this.state.selectedCampaign)}
					isSearchable
					onChange={selectedCampaign => {
						this.props.fetchCampaign(selectedCampaign.value).then(campaign => {
							const state = {
								selectedCampaign: campaign,
								wasCampaignSetManually: true,
								selectedMailing: mailingAll,
							};

							if (!this.state.wasDescriptionSetManually) {
								state.description = this.createDescription({ campaign });
							}

							this.setState(state);
						}).catch(err => {
							this.props.setMessage(MAILING_FORM_MESSAGE, 'Kon campagne niet ophalen', err.response.data.message, {
								color: 'danger',
							});
						});
					}}
				/>
			</Col>
		</FormGroup>

		<FormGroup row>
			<Col xs={12} md={3}>
				<Label>Verzending</Label>
			</Col>

			<Col xs={12} md={9} lg={6}>
				<Select
					options={this.getMailingOptions()}
					value={this.state.selectedMailing}
					isSearchable
					onChange={selectedMailing => {
						const state = {
							selectedMailing,
						};

						if (!this.state.wasDescriptionSetManually) {
							state.description = this.createDescription({ mailing: selectedMailing });
						}

						this.setState(state);
					}}
				/>
			</Col>
		</FormGroup>

		<FormGroup row>
			<Col xs={12} md={3}>
				<Label>Soort adres</Label>
			</Col>

			<Col xs={12} md={9} lg={6}>
				<Select
					options={types}
					value={this.state.selectedType}
					isSearchable
					onChange={selectedType => {
						const state = {
							selectedType,
						};

						if (!this.state.wasDescriptionSetManually) {
							state.description = this.createDescription({ type: selectedType });
						}

						this.setState(state);
					}}
				/>
			</Col>
		</FormGroup>

		<FormGroup row>
			<Col xs={12} md={3}>
				<Label>Omschrijving</Label>
			</Col>

			<Col xs={12} md={9} lg={6}>
				<Input type="textarea" value={this.state.description} onChange={e => {
					this.setState({
						description: e.target.value,
						wasDescriptionSetManually: true,
					});
				}} />
			</Col>
		</FormGroup>

		<FormGroup>
			<Button type="submit" color="primary" disabled={!this.canSubmit()}>Kopiëren</Button>
		</FormGroup>
	</Form>;

	render = () => <div id="copyMailingForm">
		<Container>
			<h1>Kopieër adressen van een mailing</h1>
			<h5>Campagne: {this.state.selectedCampaign.name}</h5>

			{this.props.lastMessage && <Alert message={this.props.lastMessage} />}
			{this.props.messages[MAILING_FORM_MESSAGE] && <Alert message={this.props.messages[MAILING_FORM_MESSAGE]} />}

			{this.props.renderWithLoader(this.props.campaigns, this.renderForm)}
		</Container>
	</div>;
}

export default withMessages(withLoader(CopyMailingForm));
