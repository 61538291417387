import React from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import {Container} from 'reactstrap';
import PartnerContactForm from './PartnerContactForm';
import Alert from '../alert/Alert';
import Message from '../../messages/Message';
import {validatePasswordIfUsername} from '../../validation/auth';

export default class NewPartnerContact extends React.Component {
	static propTypes = {
		onSubmit: PropTypes.func.isRequired,
		partnerId: PropTypes.string.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			lastMessage: null,
			doRedirect: false,
		};

		this.onSubmit = this.onSubmit.bind(this);
	}

	onSubmit(contact) {
		this.setState({
			lastMessage: null,
		});

		validatePasswordIfUsername.validate(contact).then(() => {
			this.props.onSubmit(this.props.partnerId, contact).then(() => {
				this.setState({
					doRedirect: true,
				});
			}).catch(err => {
				this.setState({
					lastMessage: new Message('Could not save partner contact', err.message, {
						color: 'danger',
					}),
				});
			});
		}).catch(err => {
			this.setState({
				lastMessage: new Message('Foute invoer', err.message, {
					color: 'danger',
				}),
			});
		});
	}

	render() {
		if (this.state.doRedirect) {
			return <Redirect to={`/partners/${this.props.partnerId}/contacts`} />;
		}

		const alert = this.state.lastMessage && <Alert message={this.state.lastMessage} />;

		return <div id="newPartnerContact">
			<Container>
				<h1>New partner contact</h1>

				{alert}

				<PartnerContactForm
					onSubmit={this.onSubmit}
				/>
			</Container>
		</div>;
	}
}
