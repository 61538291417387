import React, { Fragment } from 'react';
import { Col, Button, FormGroup, Input, Label, ButtonGroup } from 'reactstrap';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import SelectCampaign from './SelectCampaign';

export function Switch({ value, onChange }) {
	return <ButtonGroup className="d-block">
		<Button color={!value ? 'danger' : 'light'} onClick={() => onChange(false)}>Nee</Button>
		<Button color={value ? 'success' : 'light'} onClick={() => onChange(true)}>Ja</Button>
	</ButtonGroup>;
}

function renderMailingDropdown(selectedCampaigns, selectedMailings, onChange) {
	if (!selectedCampaigns.length) {
		return null;
	}
	const values = selectedCampaigns.reduce((options, campaign) => {
		campaign.mailings.forEach(mailing => {
			options.push({
				value: { mailing: mailing._id, campaign: campaign.value },
				label: mailing.name
			});
		});
		return options;
	}, []);
	return <Select isMulti className="mt-1" isSearchable options={values} value={selectedMailings} onChange={onChange} />;
}

export default function SelectionSettings({
	useBlacklist, activeDatasetsOnly, useCampaignLabel,
	useDate, pressureAge, description, setSetting,
	campaigns, selectedCampaigns, selectedMailings,
	campaignDays, setCampaignDays
}) {
	return <Fragment>
		<FormGroup row className="mb-4">
			<Col xs={12} lg={3}>
				<Label>Gebruik blacklist?</Label>
				<Switch value={useBlacklist} onChange={value => setSetting('useBlacklist', value)} />
			</Col>

			<Col xs={12} lg={3}>
				<Label>Gebruik alleen actieve datasets?</Label>
				<Switch value={activeDatasetsOnly} onChange={value => setSetting('activeDatasetsOnly', value)} />
			</Col>

			<Col xs={12} lg={3}>
				<Label>Gebruik campagne label?</Label>
				<Switch value={useCampaignLabel} onChange={value => setSetting('useCampaignLabel', value)} />
			</Col>

			<Col xs={12} lg={3}>
				<Label>Query met maildruk datum?</Label>
				<Switch value={useDate} onChange={value => setSetting('useDate', value)} />
			</Col>
		</FormGroup>

		<FormGroup row className="mb-4">
			<Col xs={12} lg={6}>
				<Label>Maildruk datum</Label>
				<DatePicker
					className="form-control"
					dropdownMode="scroll"
					dateFormat="dd-MM-yyyy"
					selected={pressureAge}
					onChange={date => setSetting('pressureAge', new Date(date))}
					disabled={!useDate}
					showWeekNumbers
				/>
			</Col>
		</FormGroup>

		<FormGroup row className="mb-4">
			<Col xs={12} lg={6}>
				<Label>Selecteer alleen uit</Label>
				<SelectCampaign multiple
					setDays={setCampaignDays}
					days={campaignDays}
					campaigns={campaigns}
					value={selectedCampaigns}
					onChange={c => setSetting('selectedCampaigns', c || [])} />
				{renderMailingDropdown(selectedCampaigns, selectedMailings, m => setSetting('selectedMailings', m || []))}
			</Col>
		</FormGroup>

		<FormGroup row>
			<Col xs={12} lg={6}>
				<Label>Beschrijving</Label>
				<Input
					type="textarea"
					value={description}
					onChange={e => setSetting('description', e.target.value)} />
			</Col>
		</FormGroup>
	</Fragment>;
}
