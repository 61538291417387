import React, { Component } from 'react';
import TaskList from '../components/tasks/TaskList';
import { getTasks } from '../api';
const SLEEP = 1000;
let timeout = 0;

export default class TasksContainer extends Component {
	state = {
		tasks: null
	};

	fetchTasks = () => {
		getTasks().then(tasks => {
			this.setState({ tasks });
		}).catch(err => {
			console.error('Failed to get tasks', err);
		}).then(() => {
			timeout = setTimeout(this.fetchTasks, SLEEP);
		});
	}

	onSearch = e => {
		e.preventDefault();
		this.setState({ searchTerm: e.target.value.toLowerCase()});
	}

	filterTasks = tasks => {
		if(this.state.searchTerm) {
			const filteredTasks = tasks.filter(task => {
				if(task.error && task.error.message && task.error.message.toLowerCase().includes(this.state.searchTerm)) {
					return true;
				}
				return ['type', 'username', 'state'].some(key => {
					return typeof task[key] === 'string' && task[key].toLowerCase().includes(this.state.searchTerm);
				});
			});
			return filteredTasks;
		}

		return tasks;
	}

	componentDidMount() {
		this.fetchTasks();
	}

	componentWillUnmount() {
		clearTimeout(timeout);
	}

	render() {
		const { tasks } = this.state;
		if(!tasks) {
			return null;
		}
		return <TaskList tasks={this.filterTasks(tasks)} onSearch={this.onSearch} searchTerm={this.state.searchTerm} />;
	}
}
