export const SET_MISC_DATASET = 'SET_MISC_DATASET';
export const SET_MISC_MENU = 'SET_MISC_MENU';

export function setMiscDataset(dataset) {
	return {
		type: SET_MISC_DATASET,
		payload: {
			dataset,
		},
	};
}

export function setMiscMenu(state) {
	if (state !== 'open') {
		state = 'closed';
	}

	return {
		type: SET_MISC_MENU,
		payload: {
			menu: state,
		},
	};
}
