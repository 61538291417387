import React, { Component, Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import { withMessages } from '../hoc/with-messages';
import QueryBuilder from '../components/query-builder/QueryBuilder';
import LineEditor from '../components/query-builder/LineEditor';

import { history } from '../history';
import * as api from '../api';
import { QueryBuilderState } from '../enum/QueryBuilderState';
import { ButtonLoadingState } from '../enum/ButtonLoadingState';
import { getData } from '../util/Poller';
import memoizePromise from '../util/memoize';

const fetchCampaign = memoizePromise(api.fetchCampaign);

const FETCH_TIMEOUT = 500;
const WEEK = 7;
const POLL_INTERVAL = 2000;
const QUERY_BUILDER_MESSAGE = 'query-builder-message';
const EMPTY_QUERY = {
	and: []
};
const INITIAL_STATE = {
	query: EMPTY_QUERY,
	campaign: null,
	mailing: null,
	selection: null,
	preDefinedQueries: [],
	settings: {
		useBlacklist: true,
		activeDatasetsOnly: true,
		useCampaignLabel: true,
		useDate: true,
		pressureAge: null,
		description: '',
		selectedCampaigns: [],
		selectedMailings: []
	},
	line: {},
	state: QueryBuilderState.NONE,
	result: {},
	saved: false,
	mailingState: ButtonLoadingState.NONE
};

function traverseQuery(query, orAnd, not, token) {
	for(const key in query) {
		if(key === 'or' || key === 'and') {
			return orAnd(query[key], key);
		} else if(key === 'not') {
			return not(query.not);
		} else if(key.startsWith('_')) {
			continue;
		}
		return token(key, query[key]);
	}
	return token();
}

function hasExecutableQuery(query) {
	return traverseQuery(query, tokens => tokens.some(hasExecutableQuery), hasExecutableQuery, type => !!type);
}

function cullQuery(query, tokens) {
	return traverseQuery(query, (array, key) => {
		const culled = array.map(q => cullQuery(q, tokens)).filter(q => q);
		if(culled.length) {
			return { [key]: culled };
		}
		return null;
	}, token => {
		const culled = cullQuery(token, tokens);
		if(culled) {
			return { not: culled };
		}
		return null;
	}, (key, token) => {
		if(!key) {
			return null;
		}
		const copy = {};
		let hasMeta = false;
		for(const field in token) {
			if(!field.startsWith('_')) {
				copy[field] = token[field];
			} else {
				hasMeta = true;
			}
		}
		if(hasMeta) {
			tokens.push(query);
		}
		return { [key]: copy };
	});
}

function waitABit() {
	return new Promise(resolve => {
		const minTime = 500;
		const rMult = 1250; // result in a number between 0 and rMult
		const timeoutTime = Math.max(minTime, Math.random() * rMult);

		// Give users the idea that it's actually being saved, the timeout time will cause a bit of randomness
		setTimeout(resolve, timeoutTime);
	});
}

function setPredefinedIntersects(query, campaign, mailing, selection) {
	return traverseQuery(query, (tokens, key) => {
		let changed = tokens;
		tokens.forEach((token, i) => {
			const o = setPredefinedIntersects(token, campaign, mailing, selection);
			if(o !== token) {
				if(changed === tokens) {
					changed = tokens.slice();
				}
				changed[i] = o;
			}
		});
		if(changed === tokens) {
			return query;
		}
		return { [key]: changed };
	}, token => {
		const not = setPredefinedIntersects(token, campaign, mailing, selection);
		if(not === token) {
			return query;
		}
		return { not };
	}, (key, token) => {
		if((key === 'intersect' || key === 'complement') && !token.selection) {
			const copy = Object.assign({
				campaign, mailing, selection
			}, token);
			return { [key]: copy };
		}
		return query;
	});
}

function getMonday(date) {
	const d = new Date(date);
	const day = d.getDay();
	d.setDate(d.getDate() + (WEEK - day) % WEEK + 1);
	return d;
}

class QueryBuilderContainer extends Component {
	static defaultProps = {
		addLine: false
	};

	state = INITIAL_STATE;

	editQuery = (query, campaign, mailing, selection) => {
		if(!query) {
			query = EMPTY_QUERY;
		} else if(selection) {
			query = setPredefinedIntersects(query, campaign, mailing, selection);
		}
		this.setState({ query: query });
	};

	componentDidMount() {
		const { campaign, mailing } = this.props.match.params;
		if(campaign) {
			this.getCampaign();
			if(mailing) {
				this.getMailing();
			}
		}
		api.fetchGlobalDatasetHeaderMappings().then(mappings => {
			this.props.setGlobalMappings(mappings);
		}).catch(err => {
			this.props.setMessage(QUERY_BUILDER_MESSAGE, 'Unable to fetch attributes', err.response.data.message, {
				color: 'danger',
			});
		});
		api.fetchTypes().then(types => {
			this.props.setTypes(types);
		}).catch(err => {
			this.props.setMessage(QUERY_BUILDER_MESSAGE, 'Types konden niet worden opgehaald', err.response.data.message, {
				color: 'danger',
			});
		});
		api.fetchInterestTypes().then(types => {
			this.props.setInterestTypes(types);
		}).catch(err => {
			this.props.setMessage(QUERY_BUILDER_MESSAGE, 'Interesses konden niet worden opgehaald', err.response.data.message, {
				color: 'danger',
			});
		});
		api.fetchPredefinedSelections().then(preDefinedQueries => {
			this.setState({ preDefinedQueries });
		}).catch(err => {
			this.props.setMessage(QUERY_BUILDER_MESSAGE, 'Unable to fetch predefined queries', err.response.data.message, {
				color: 'danger',
			});
		});
	}

	componentDidUpdate(props, state) {
		if(this.props !== props) {
			const { campaign, mailing, selection } = this.props.match.params;
			if(campaign) {
				if((!props.addLine || props.match.params.campaign) && campaign !== props.match.params.campaign) {
					this.getCampaign();
				}
				if(mailing) {
					if((!props.addLine || props.match.params.mailing) && mailing !== props.match.params.mailing) {
						this.getMailing();
					} else if(!props.addLine && selection !== props.match.params.selection && this.state.mailing) {
						const found = this.state.mailing.selections.find(s => s._id === selection);
						if(found) {
							const s = {
								selection: found
							};
							this.loadSelection(this.state.mailing, found, s);
							this.setState(s);
						}
					}
				} else if(!this.props.addLine && this.state.mailing) {
					this.setState({ mailing: null, selection: null });
				}
			} else if(!this.props.addLine && this.state.campaign && !props.addLine) {
				this.setState({ campaign: null, mailing: null, selection: null });
			}
		}
		if(this.state.campaign && this.state.mailing
			&& (!this.state.selection || !this.state.selection.recordCount)
			&& (this.state.query !== state.query || this.state.settings !== state.settings)) {
			this.saveQuery();
		}
	}

	canSubmit() {
		const { query, campaign, mailing } = this.state;
		if(!mailing || !campaign) {
			return false;
		}
		return hasExecutableQuery(query);
	}

	getCampaign() {
		fetchCampaign(this.props.match.params.campaign, true).then(campaign => {
			this.setState({ campaign });
		}).catch(err => {
			this.props.setMessage(QUERY_BUILDER_MESSAGE, 'Unable to fetch campaign', err.response.data.message, {
				color: 'danger',
			});
		});
	}

	getMailing({ params } = this.props.match) {
		api.fetchMailing(params.campaign, params.mailing).then(async mailing => {
			const selection = mailing.selections.find(s => s._id === params.selection);
			const settings = Object.assign({}, this.state.settings);
			settings.pressureAge = getMonday(mailing.plannedSend);
			const state = { mailing, selection, settings };
			if(selection) {
				await this.loadSelection(mailing, selection, state);
			}
			this.setState(state);
		}).catch(err => {
			this.props.setMessage(QUERY_BUILDER_MESSAGE, 'Unable to fetch mailing', err.response && err.response.data.message || err.message, {
				color: 'danger',
			});
		});
	}

	setCampaign = id => {
		const campaign = this.props.campaigns.find(c => c._id === id);
		if(campaign) {
			history.push(`/selection-builder/${id}`);
		} else {
			this.setState({ campaign });
		}
	};

	setMailing = id => {
		const { campaign } = this.state;
		const mailing = campaign.mailings.find(m => m._id === id);
		if(mailing) {
			history.push(`/selection-builder/${campaign._id}/${id}`);
		} else {
			this.setState({ mailing });
		}
	};

	setSelectedCampaigns(campaigns) {
		if(!campaigns.length) {
			return this.setState(state => {
				const settings = Object.assign({}, state.settings);
				settings.selectedCampaigns = [];
				settings.selectedMailings = [];
				return { settings };
			});
		}
		const alreadySelected = {};
		this.state.settings.selectedCampaigns.forEach(campaign => {
			alreadySelected[campaign.value] = campaign;
		});
		const selectedCampaigns = [];
		const promises = [];
		campaigns.forEach(campaign => {
			if(alreadySelected[campaign.value]) {
				selectedCampaigns.push(alreadySelected[campaign.value]);
				delete alreadySelected[campaign.value];
			} else {
				const value = Object.assign({}, campaign);
				selectedCampaigns.push(value);
				promises.push(fetchCampaign(campaign.value, true).then(c => {
					value.mailings = c.mailings;
				}).catch(err => {
					this.props.setMessage(QUERY_BUILDER_MESSAGE, 'Unable to fetch campaign', err.response.data.message, {
						color: 'danger',
					});
				}));
			}
		});
		const selectedMailings = this.state.settings.selectedMailings.filter(m => {
			return !alreadySelected[m.value.campaign];
		});
		Promise.all(promises).then(() => {
			this.setState(state => {
				const settings = Object.assign({}, state.settings);
				settings.selectedCampaigns = selectedCampaigns;
				settings.selectedMailings = selectedMailings;
				return { settings };
			});
		});
	}

	setSetting = (key, value) => {
		if(key === 'selectedCampaigns') {
			return this.setSelectedCampaigns(value);
		}
		this.setState(state => {
			const settings = Object.assign({}, state.settings);
			settings[key] = value;
			return { settings };
		});
	};

	loadSelection(mailing, selection, state) {
		state.query = selection.query || EMPTY_QUERY;
		if(!state.settings) {
			return;
		}
		['description', 'useBlacklist', 'useCampaignLabel', 'activeDatasetsOnly'].forEach(key => {
			if(key in selection) {
				state.settings[key] = selection[key];
			}
		});
		if(selection.pressure && 'age' in selection.pressure) {
			state.settings.pressureAge = new Date(selection.pressure.age);
		}
		const { useDatasetsFrom } = selection;
		if(useDatasetsFrom) {
			return this.loadUseDatasetsFrom(useDatasetsFrom, state.settings);
		}
	}

	loadUseDatasetsFrom(useDatasetsFrom, settings) {
		const filter = Array.isArray(useDatasetsFrom) ? useDatasetsFrom : [];
		if(useDatasetsFrom.campaign) {
			if(useDatasetsFrom.mailing) {
				filter.push({ campaign: useDatasetsFrom.campaign, mailing: useDatasetsFrom.mailing });
			} else {
				filter.push({ campaign: useDatasetsFrom.campaign });
			}
		}
		const selectedCampaigns = {};
		const selectedMailings = [];
		return Promise.all(filter.map(item => {
			return fetchCampaign(item.campaign).then(campaign => {
				selectedCampaigns[campaign._id] = {
					value: campaign._id,
					label: campaign.name,
					mailings: campaign.mailings
				};
				if(item.mailing) {
					const mailing = campaign.mailings.find(m => m._id === item.mailing);
					if(mailing) {
						selectedMailings.push({
							value: { campaign: campaign._id, mailing: mailing._id },
							label: mailing.name
						});
					}
				}
			}).catch(() => undefined);
		})).then(() => {
			settings.selectedCampaigns = Object.keys(selectedCampaigns).map(k => selectedCampaigns[k]);
			settings.selectedMailings = selectedMailings;
		});
	}

	editLine = (editToken = this.edit, type = '', token) => {
		const { url } = this.props.match;
		this.setState({
			line: {
				edit(query) {
					if(query) {
						editToken(query);
					}
					history.push(url);
				},
				token,
				editType: type
			}
		}, () => {
			history.push(`/selection-builder/add-line/${type}`);
		});
	};

	reset = () => {
		const state = Object.assign({}, INITIAL_STATE);
		delete state.preDefinedQueries;
		this.setState(state, () => {
			history.push('/selection-builder');
		});
	};

	saveQuery() {
		const { state } = this.state;
		if(state === QueryBuilderState.SAVING || state === QueryBuilderState.PERFORMING_QUERY) {
			return;
		}
		const query = cullQuery(this.state.query, []);
		if(!query) {
			return;
		}
		this.setState({ state: QueryBuilderState.SAVING });
		Promise.all([
			this.upsertQuery(query),
			waitABit()
		]).then(() => {
			this.setState({ state: QueryBuilderState.SAVED });
		}).catch(err => {
			console.error('Failed to save query', err);
			this.setState({ state: QueryBuilderState.NOT_SAVED });
		});
	}

	upsertQuery(query) {
		const {
			description, useBlacklist, activeDatasetsOnly, pressureAge, preDefined,
			useCampaignLabel, useDate, selectedCampaigns, selectedMailings
		} = this.state.settings;
		const data = {
			query,
			description,
			useBlacklist,
			activeDatasetsOnly,
			pressure: {},
			preDefined,
			useCampaignLabel,
			useDatasetsFrom: selectedMailings.map(m => m.value)
		};
		if(!data.useDatasetsFrom.length && selectedCampaigns.length) {
			data.useDatasetsFrom = selectedCampaigns.map(c => {
				return { campaign: c.value };
			});
		}
		if(useDate) {
			data.pressure.age = pressureAge;
		}
		const { campaign, mailing, selection } = this.state;
		if(selection) {
			return api.updateSelection(campaign._id, mailing._id, selection._id, data).then(() => selection._id);
		}
		return api.createNewMailingSelection(campaign._id, mailing._id, data).then(res => {
			const { selectionId } = res.data;
			this.setState({ selection: {
				_id: selectionId,
				recordCount: 0
			} });
			history.push(`/selection-builder/${campaign._id}/${mailing._id}/${selectionId}`);
			return selectionId;
		});
	}

	executeQuery = () => {
		const { state } = this.state;
		if(state === QueryBuilderState.SAVING || state === QueryBuilderState.PERFORMING_QUERY) {
			return;
		}
		const tokens = [];
		const culled = cullQuery(this.state.query, tokens);
		if(!culled) {
			return;
		}
		this.setState({ state: QueryBuilderState.SAVING, result: {}, saved: false });
		this.upsertQuery(culled).then(selection => {
			return this.uploadFiles(tokens, selection);
		}).then(() => {
			this.setState({ state: QueryBuilderState.PERFORMING_QUERY });
			const { campaign, mailing, selection } = this.state;
			return api.performQueryFromSelection(campaign._id, mailing._id, selection._id);
		}).then(({ task, query }) => {
			this.setState(s => Object.assign({}, s, {
				result: Object.assign({}, s.result, {
					query
				})
			}));
			return getData(() => api.pollTask(task), POLL_INTERVAL);
		}).then(({ result }) => {
			result = result.filter(r => {
				const values = {};
				['clicks', 'opens', 'new', 'notOpenedOrClicked', 'total'].forEach(key => {
					values[key] = r[key];
					delete r[key];
				});
				r.original = values;
				r.modified = values;
				if(r.saved) {
					r.sort = -1;
					r.selected = false;
				} else if(r.dataset) {
					r.sort = r.dataset.sort || 0;
					r.selected = true;
				}
				r.changed = false;
				return r.saved || r.dataset;
			});
			this.setState(s => Object.assign({}, s, {
				result: Object.assign({}, s.result, {
					result
				}),
				state: QueryBuilderState.DONE
			}));
		}).catch(err => {
			console.error('Failed to execute query', err);
			this.setState({ state: QueryBuilderState.NOT_SAVED });
		});
	};

	uploadFiles(tokens, selection) {
		const promises = [];
		const { campaign, mailing } = this.state;
		tokens.forEach(token => {
			if(token.intersect && token.intersect._file || token.complement && token.complement._file) {
				const { id, _file, _mappings } = token.intersect || token.complement;
				promises.push(api.uploadCSVForIntersect(campaign._id, mailing._id, selection, id, _file, _mappings).then(({ task }) => {
					return getData(() => api.pollTask(task), POLL_INTERVAL);
				}));
			}
		});
		return Promise.all(promises);
	}

	setResultLine = (i, key, e) => {
		if(key === 'selected') {
			const result = this.state.result.result.slice();
			const line = Object.assign({}, result[i]);
			result[i] = line;
			line.selected = e.target.checked;
			if(!line.selected) {
				line.changed = false;
			}
			return this.setState({
				result: Object.assign({}, this.state.result, { result })
			});
		}
		let value = +e.target.value;
		if(Number.isInteger(value)) {
			const result = this.state.result.result.slice();
			value = Math.max(0, value);
			const line = Object.assign({}, result[i]);
			result[i] = line;
			value = Math.min(value, result[i].original[key]);
			line.modified = Object.assign({}, line.modified);
			line.modified.total -= line.modified[key];
			line.modified[key] = value;
			line.modified.total += value;
			line.changed = true;
			this.setState({
				result: Object.assign({}, this.state.result, { result })
			});
		}
	};

	redistribute = (total, selectedColumns) => {
		const result = this.state.result.result.map(line => {
			if(line.dataset && !line.changed) {
				const copy = Object.assign({}, line);
				copy.modified = Object.assign({}, copy.modified);
				copy.modified.clicks = 0;
				copy.modified.opens = 0;
				copy.modified.new = 0;
				copy.modified.notOpenedOrClicked = 0;
				copy.modified.total = 0;
				return copy;
			}
			return line;
		});
		const length = result.length;
		['clicks', 'opens', 'new', 'notOpenedOrClicked'].filter(column => selectedColumns[column]).forEach(column => {
			for(let i = 0; i < length && total > 0; i++) {
				const line = result[i];
				if(line.selected) {
					if(!line.changed) {
						line.modified[column] = Math.min(line.original[column], total);
					}
					line.modified.total += line.modified[column];
					total -= line.modified[column];
				}
			}
		});
		this.setState({
			result: Object.assign({}, this.state.result, { result })
		});
	};

	applyFactor = () => {
		const result = this.state.result.result.map(line => {
			if(line.dataset && (line.modified.notOpenedOrClicked < line.original.notOpenedOrClicked || line.modified.new < line.original.new)) {
				const factor = line.dataset.factor || 1;
				const additional = Math.round((line.modified.clicks + line.modified.opens) * (factor - 1));
				if(additional > 0) {
					const copy = Object.assign({}, line);
					copy.modified = Object.assign({}, copy.modified);
					const change = Math.min(line.original.new, line.modified.new + additional) - line.modified.new;
					copy.modified.new += change;
					if(change < additional) {
						copy.modified.notOpenedOrClicked = Math.min(line.original.notOpenedOrClicked, line.modified.notOpenedOrClicked + additional - change);
					}
					return copy;
				}
			}
			return line;
		});
		this.setState({
			result: Object.assign({}, this.state.result, { result })
		});
	};

	saveMailing = () => {
		const { selection, result, campaign, mailing } = this.state;
		if(!selection || !result.result) {
			return;
		}
		this.setState({
			saved: false,
			mailingState: ButtonLoadingState.SAVING
		});
		const lines = result.result.filter(line => line.dataset && line.modified.total > 0).map(line => {
			const out = Object.assign({}, line.modified);
			out.id = line.dataset.id;
			return out;
		});
		api.saveMailing(campaign._id, mailing._id, selection._id, lines).then(({ task, query }) => {
			this.setState(s => Object.assign({}, s, {
				result: Object.assign({}, s.result, {
					saveQuery: query
				})
			}));
			return getData(() => api.pollTask(task), POLL_INTERVAL);
		}).then(() => {
			this.getMailing({ params: { campaign: campaign._id, mailing: mailing._id, selection: selection._id } });
			this.setState({ saved: true, mailingState: ButtonLoadingState.DONE });
		}).catch(err => {
			console.error(err);
			this.setState({
				mailingState: ButtonLoadingState.FAILED
			});
		});
	};

	render() {
		const { messages, campaigns, addLine, match, interestTypes, types, globalMappings, campaignDays, setCampaignDays } = this.props;
		const { query, campaign, mailing, preDefinedQueries, settings, line, selection, state, result, saved, mailingState } = this.state;
		const { type } = match.params;
		return <Fragment>
			{addLine && <LineEditor
				type={type}
				{...line}
				labels={types}
				attributes={globalMappings}
				interestTypes={interestTypes} />}
			<div hidden={addLine}>
				<QueryBuilder
					traverseQuery={traverseQuery}
					lastMessage={messages[QUERY_BUILDER_MESSAGE]}
					campaigns={campaigns}
					campaign={campaign}
					mailing={mailing}
					setCampaign={this.setCampaign}
					setMailing={this.setMailing}
					preDefinedQueries={preDefinedQueries}
					settings={settings}
					setSetting={this.setSetting}
					edit={this.editQuery}
					editLine={this.editLine}
					reset={this.reset}
					canSubmit={this.canSubmit()}
					executeQuery={this.executeQuery}
					result={result}
					selection={selection && selection._id}
					state={state}
					saved={saved}
					mailingState={mailingState}
					saveMailing={this.saveMailing}
					setResultLine={this.setResultLine}
					redistribute={this.redistribute}
					applyFactor={this.applyFactor}
					campaignDays={campaignDays}
					setCampaignDays={setCampaignDays}
					query={query} />
			</div>
		</Fragment>;
	}
}

class StateContainer extends Component {
	state = {
		campaigns: null,
		types: null,
		interestTypes: null,
		globalMappings: [],
		campaignDays: 30
	};
	interval: 0;

	componentDidMount() {
		this.fetchCampaigns();
	}

	componentWillUnmount() {
		clearTimeout(this.interval);
	}

	fetchCampaigns = () => {
		this.setState({ campaigns: null });
		api.fetchCampaigns(this.state.campaignDays).then(campaigns => {
			this.setState({ campaigns });
		}).catch(err => {
			this.props.setMessage(QUERY_BUILDER_MESSAGE, 'Unable to fetch campaigns', err.response.data.message, {
				color: 'danger',
			});
		});
	};

	setCampaignDays = campaignDays => {
		this.setState({ campaignDays });
		clearTimeout(this.interval);
		this.interval = setTimeout(this.fetchCampaigns, FETCH_TIMEOUT);
	};

	render() {
		return <QueryBuilderContainer
			setCampaignDays={this.setCampaignDays}
			setTypes={types => this.setState({ types })}
			setInterestTypes={interestTypes => this.setState({ interestTypes })}
			setGlobalMappings={globalMappings => this.setState({ globalMappings })}
			{...this.state} {...this.props} />;
	}
}

const ConnectedContainer = withMessages(StateContainer);

export default function QueryBuilderRouter() {
	return <Switch>
		<Route path="/selection-builder/add-line/:type?" render={props => <ConnectedContainer {...props} addLine />} />
		<Route path="/selection-builder/:campaign?/:mailing?/:selection?" component={ConnectedContainer} />
	</Switch>;
}
