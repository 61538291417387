import React, { Component } from 'react';
import Token from './Token';

export default class Range extends Component {
	remove = () => {
		this.props.edit(null);
	};

	edit = () => {
		this.props.editLine(this.props.edit, 'range', this.props.token);
	};

	render() {
		return <div>{Object.keys(this.props.token).map(key => {
			const [min, max] = this.props.token[key];
			return <Token key={key}
				edit={this.edit}
				remove={this.remove}
				value={`${key} range [${min} TO ${max}]`} />;
		})}</div>;
	}
}
