import React from 'react';
import {connect} from 'react-redux';

import Navigation from '../components/Navigation';
import {logout} from '../api';
import { setAccount } from '../redux/actions/auth';
import { setMiscMenu } from '../redux/actions/misc';

class NavigationContainer extends React.Component {
	logout = () => {
		logout()
			.then(() => this.props.dispatch(setAccount(null)))
			.catch(() => undefined);
	}

	toggleSidebar = () => {
		this.props.dispatch(setMiscMenu(this.props.menuState === 'open' ? 'closed' : 'open'));
	}

	render() {
		return <Navigation
			isLoggedIn={!!this.props.account}
			logout={this.logout}
			account={this.props.account}
			toggleSidebar={this.toggleSidebar}
		/>;
	}
}

const mapStateToProps = store => {
	return {
		menuState: store.misc.menu,
	};
};

export default connect(mapStateToProps)(NavigationContainer);
