import React from 'react';
import {Container} from 'reactstrap';

export default function Error404() {
	return <Container>
		<h1>404</h1>

		<p>De opgevraagde pagina kon niet worden gevonden.</p>

		<p>Bekijk of de link juist is of neem contact op met de ontwikkelaars.</p>
	</Container>;
}
