import React from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import {Container} from 'reactstrap';
import Message from '../../messages/Message';
import Alert from '../alert/Alert';
import PartnerContactForm from './PartnerContactForm';
import {validatePassword} from '../../validation/auth';

export default class UpdatePartnerContact extends React.Component {
	static propTypes = {
		partnerId: PropTypes.string.isRequired,
		contactId: PropTypes.string.isRequired,
		fetchPartnerContact: PropTypes.func.isRequired,
		onSubmit: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			contact: null,
			errorMessage: null,
			onlyShowError: false,
			doRedirect: false,
		};

		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		this.getContact();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.partnerId !== this.props.partnerId || prevProps.contactId !== this.props.contactId) {
			this.getContact();
		}
	}

	getContact() {
		this.setState({
			errorMessage: null,
			onlyShowError: false,
		});

		this.props.fetchPartnerContact(this.props.partnerId, this.props.contactId).then(contact => {
			this.setState({
				contact,
			});
		}).catch(err => {
			this.setState({
				errorMessage: new Message('An error occured while fetching contact', err.message, {
					color: 'danger',
				}),
				onlyShowError: true,
			});
		});
	}

	onSubmit(contact) {
		const send = () => {
			this.props.onSubmit(this.props.partnerId, this.props.contactId, contact).then(() => {
				this.setState({
					doRedirect: true,
				});
			}).catch(err => {
				this.setState({
					errorMessage: new Message('An error occured while saving contact', err.message, {
						color: 'danger',
					}),
				});
			});
		};

		this.setState({
			onlyShowError: false,
			errorMessage: null,
		});

		if (contact.password) {
			validatePassword.validate(contact).then(() => {
				send();
			}).catch(err => {
				this.setState({
					errorMessage: new Message('Foute invoer', err.message, {
						color: 'danger',
					}),
				});
			});
		} else {
			send();
		}
	}

	render() {
		if (this.state.doRedirect) {
			return <Redirect to={`/partners/${this.props.partnerId}/contacts`} />;
		}

		const alert = this.state.errorMessage && <Alert message={this.state.errorMessage} />;

		if (this.state.onlyShowError) {
			return alert;
		}

		if (!this.state.contact) {
			return 'Loading contact...';
		}

		const {firstName, preposition, lastName} = this.state.contact;
		const contactName = [ firstName, preposition, lastName ].join(' ');

		return <div id="updatePartner">
			<Container>
				<h1>Update contact {contactName}</h1>

				{alert}

				<PartnerContactForm
					onSubmit={this.onSubmit}
					data={this.state.contact}
				/>
			</Container>
		</div>;
	}
}
