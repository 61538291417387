import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import SolrQueryResultTable from './SolrQueryResultTable';
import MailingCounter from './MailingCounter';
import { QueryBuilderState } from '../../enum/QueryBuilderState';
import { ButtonLoadingState } from '../../enum/ButtonLoadingState';

const SAVE_WARN = 1e5;

function aggregateResults(results) {
	return results.reduce((totals, line) => {
		if(!line.dataset) {
			return totals;
		}
		let total = 0;
		['clicks', 'opens', 'new', 'notOpenedOrClicked'].forEach(key => {
			total += line.modified[key];
			totals.modified[key] += line.modified[key];
			totals.original[key] += line.original[key];
		});
		totals.modified.total += total;
		totals.original.total += line.original.total;
		return totals;
	}, {
		modified: { clicks: 0, new: 0, notOpenedOrClicked: 0, opens: 0, total: 0 },
		original: { clicks: 0, new: 0, notOpenedOrClicked: 0, opens: 0, total: 0 },
	});
}

function SaveButtonIcon({ state }) {
	switch (state) {
		case ButtonLoadingState.DONE:
			return <span className="d-inline-block ml-1"><FontAwesomeIcon icon="check" /> Done!</span>;
		case ButtonLoadingState.LOADING:
			return <span className="d-inline-block ml-1"><FontAwesomeIcon icon="sync" spin /> Loading...</span>;
		case ButtonLoadingState.SAVING:
			return <span className="d-inline-block ml-1"><FontAwesomeIcon icon="sync" spin /> Saving...</span>;
		case ButtonLoadingState.FAILED:
			return <span className="d-inline-block ml-1"><FontAwesomeIcon icon="times" /> Failed</span>;
		default:
			return null;
	}
}

export default class QueryResult extends Component {
	constructor(props) {
		super(props);
		this.state = {
			remaining: (props.mailing.addresses || 0) - this.getInSelection(props),
			selectedColumns: {
				new: false,
				notOpenedOrClicked: false,
				opens: true,
				clicks: true
			}
		};
	}

	getInSelection({ result } = this.props) {
		return result && result[0] && result[0].saved ? result[0].original.total : 0;
	}

	componentDidUpdate(props) {
		const { mailing, result } = this.props;
		if(mailing !== props.mailing || result !== props.result) {
			const now = (mailing.addresses || 0) - this.getInSelection();
			const prev = (props.mailing.addresses || 0) - this.getInSelection(props);
			if(now !== prev) {
				this.setState({
					remaining: Math.max(0, now)
				});
			}
		}
	}

	saveMailing = () => {
		const { prompt, saveMailing } = this.props;
		const total = 123;
		if (total >= SAVE_WARN) {
			return prompt({
				title: 'Wil je deze selectie opslaan?',
				text: `Wil je de selectie met ${total} emails opslaan?`,
				onConfirm: saveMailing
			});
		}
		saveMailing();
	};

	selectColumn = e => {
		const selectedColumns = Object.assign({}, this.state.selectedColumns);
		selectedColumns[e.target.value] = e.target.checked;
		this.setState({ selectedColumns });
	};

	redistribute = () => {
		this.props.redistribute(this.state.remaining, this.state.selectedColumns);
	};

	setRemaining = e => {
		const value = +e.target.value;
		if(Number.isInteger(value)) {
			this.setState({
				remaining: Math.max(0, value)
			});
		}
	};

	render() {
		const { result, campaign, mailing, selection, saved, mailingState, setResultLine, applyFactor, state } = this.props;
		if (!result) {
			if(state === QueryBuilderState.NOT_SAVED) {
				return null;
			}
			return <div className="mt-2">
				<FontAwesomeIcon icon="cog" size="2x" spin /> Query wordt uitgevoerd, een moment geduld...
			</div>;
		}
		const aggregate = aggregateResults(result);
		const inSelection = this.getInSelection();
		return <Fragment>
			<MailingCounter
				mailing={mailing.addresses || 0}
				selection={inSelection}
				remaining={this.state.remaining}
				setRemaining={this.setRemaining}
				applyFactor={applyFactor}
				redistribute={this.redistribute} />
			<SolrQueryResultTable
				result={result}
				aggregate={aggregate}
				setResultLine={setResultLine}
				selectedColumns={this.state.selectedColumns}
				selectColumn={this.selectColumn} />
			<MailingCounter
				mailing={mailing.addresses || 0}
				selection={inSelection}
				remaining={this.state.remaining}
				setRemaining={this.setRemaining} />

			<Button
				disabled={mailingState === ButtonLoadingState.SAVING || mailingState === ButtonLoadingState.LOADING}
				id="saveSelection"
				color="primary"
				onClick={this.saveMailing}>Bewaar selectie</Button> <SaveButtonIcon state={mailingState} />

			{saved && campaign && mailing &&
			<Fragment>
				<a className="ml-2" href={`/api/campaigns/${campaign._id}/mailings/${mailing._id}/selections/${selection}/csv`} rel="noopener noreferrer">
					<Button color="danger">
						<FontAwesomeIcon icon="file-csv" /> Download CSV
					</Button>
				</a>

				<Link className="ml-2" to={`/campaigns/${campaign._id}/mailings`}>
					<Button id="viewSelections" color="success">
						<FontAwesomeIcon icon="th-list" /> Ga naar verzending
					</Button>
				</Link>
			</Fragment>}
		</Fragment>;
	}
}
