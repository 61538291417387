import React from 'react';
import {Container, Row, Col, Input, FormGroup, Button, InputGroup, InputGroupText, Form} from 'reactstrap';
import InputAutocomplete from '../Autocomplete';
import DatePicker from 'react-datepicker';

import * as api from '../../api';

const DELAY = 500;
const OPERATORS = {
	match: 'Bevat gedeelte',
	in: 'Exacte waarde',
	range: 'Van - tot',
	dateRange: 'Van - tot datum'
};

export default class AttributeEditor extends React.Component {
	constructor(props) {
		super(props);

		const state = {
			attribute: '',
			operator: '',
			values: [],
			autocomplete: null
		};

		if (props.line) {
			const attribute = Object.keys(props.line)[0];

			state.attribute = attribute || '';
			state.operator = props.type || '';
			const values = props.line[attribute] || [];
			if(Array.isArray(values)) {
				state.values = values;
			} else {
				state.values = [values];
			}
		}

		this.state = state;
		this.timeout = 0;
	}

	componentDidUpdate(props, state) {
		if(this.state.attribute !== state.attribute) {
			clearTimeout(this.timeout);
			if(this.state.autocomplete) {
				this.setState({
					autocomplete: null
				});
			}
			if(this.state.operator === 'in') {
				this.timeout = setTimeout(this.getAutocompleteItems, DELAY);
			}
		} else if(this.state.operator !== state.operator) {
			clearTimeout(this.timeout);
			if(this.state.operator === 'in' && !this.state.autocomplete) {
				this.timeout = setTimeout(this.getAutocompleteItems, DELAY);
			}
		}
	}

	getAutocompleteItems = () => {
		api.fetchGlobalValueMappings(this.state.attribute).then(values => {
			this.setState({
				autocomplete: values.sort()
			});
		}).catch(err => {
			console.error('Failed to get value mappings', err);
		});
	};

	changeValue(i) {
		return e => {
			const values = this.state.values.slice();
			values[i] = e.target.value;
			this.setState({ values });
		};
	}

	renderValues() {
		if (['range', 'dateRange'].includes(this.state.operator)) {
			return this.renderRange(this.state.operator === 'dateRange');
		}

		let values = this.state.values;

		if (this.state.operator !== 'in') {
			return <FormGroup>
				<Input type="text" value={values[0]} onChange={this.changeValue(0)} />
			</FormGroup>;
		}

		if (values[values.length - 1] !== '') {
			values = values.concat('');
		}

		return values.map((val, i) => <FormGroup key={i}>
			<InputAutocomplete
				items={this.state.autocomplete || []}
				value={val}
				shouldItemRender={(item, value) => item.toLowerCase().includes(value.toLowerCase())}
				onChange={this.changeValue(i)} />
		</FormGroup>);
	}

	renderRange(date = false) {
		const [start, end] = this.state.values;
		const changeStart = this.changeValue(0);
		const changeEnd = this.changeValue(1);

		return <FormGroup>
			<InputGroup>
				{date ? <DatePicker
					className="form-control"
					dropdownMode="scroll"
					dateFormat="dd-MM-yyyy"
					selected={start || undefined}
					onChange={value => changeStart({ target: { value } })}
				/> :
					<Input type="text" value={start || ''} onChange={changeStart} />}
				<div className="input-group-prepend input-group-append">
					<InputGroupText>tot</InputGroupText>
				</div>
				{date ? <DatePicker
					className="form-control"
					dropdownMode="scroll"
					dateFormat="dd-MM-yyyy"
					selected={end || undefined}
					onChange={value => changeEnd({ target: { value } })}
				/> :
					<Input type="text" value={end || ''} onChange={changeEnd} />}
			</InputGroup>
		</FormGroup>;
	}

	canCompile() {
		const {attribute, operator, values} = this.state;
		let hasValues = true;
		if(operator === 'in') {
			hasValues = values.length > 0 && values.some(v => v.trim());
		} else if(operator === 'match') {
			hasValues = values.length > 0 && values[0];
		} else {
			hasValues = values.length > 0 && values[0] && values[1];
		}

		return attribute && operator && hasValues;
	}

	onSubmit = e => {
		e.preventDefault();
		if (this.canCompile()) {
			const { attribute, operator, values } = this.state;
			if(operator === 'in') {
				this.props.edit({
					in: {
						[attribute]: values.map(v => v.trim()).filter(v => v)
					}
				});
			} else if(operator === 'match') {
				this.props.edit({
					match: {
						[attribute]: values[0]
					}
				});
			} else {
				const [min, max] = values;
				this.props.edit({
					[operator]: {
						[attribute]: [min, max]
					}
				});
			}
		}
	};

	getOperatorText() {
		switch (this.state.operator) {
			case 'in':
				return 'Waardes';
			case 'range':
			case 'dateRange':
				return 'Van - Tot';
			default:
				return 'Waarde';
		}
	}

	render() {
		return <Container id="attributeEditor">
			<Form onSubmit={this.onSubmit}>
				<Row>
					<Col>
						<h2>Kenmerken</h2>
					</Col>

					<Col>
						<h2>Manier van selecteren</h2>
					</Col>

					<Col>
						<h2>{this.getOperatorText()}</h2>
					</Col>
				</Row>

				<Row>
					<Col>
						<Input type="select" value={this.state.attribute} onChange={e => this.setState({ attribute: e.target.value })}>
							<option value="" disabled>Maak een selectie</option>
							{this.props.attributes.map((attr, i) => {
								return <option key={i} id={attr} value={attr}>{attr}</option>;
							})}
						</Input>
					</Col>

					<Col>
						<Input type="select" value={this.state.operator} onChange={e => this.setState({ operator: e.target.value })}>
							<option value="" disabled>Maak een selectie</option>
							{Object.keys(OPERATORS).map(operator => {
								return <option key={operator} value={operator}>{OPERATORS[operator]}</option>;
							})}
						</Input>
					</Col>

					<Col>
						{this.renderValues()}
					</Col>
				</Row>

				<Row>
					<Col>
						<Button disabled={!this.canCompile()} color="primary">{this.line ? 'Sla query op' : 'Maak query'}</Button>
					</Col>
				</Row>
			</Form>
		</Container>;
	}
}
