import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'reactstrap';
import moment from 'moment';
import Message from '../../messages/Message';
import {withPrompts} from '../../hoc/with-prompts';
import ListItemsPaginated from '../crud/ListItemsPaginated';
import {withLoader} from '../../hoc/with-loader';

class ListCampaigns extends React.Component {
	static propTypes = {
		campaigns: PropTypes.array,
		fetchCampaigns: PropTypes.func.isRequired,
		deleteCampaign: PropTypes.func.isRequired,
		lastMessage: PropTypes.instanceOf(Message),
	};

	onDeleteClick = campaign => {
		this.props.prompt({
			title: `Verwijder campagne ${campaign.name}?`,
			text: `Weet je zeker dat je campagne ${campaign.name} wilt verwijderen?`,
			onConfirm: () => this.props.deleteCampaign(campaign._id),
		});
	};

	renderList = () => <ListItemsPaginated
		containerId="listCampaigns"
		headerText="Campagnes"
		headerTag="h2"
		fetchItems={this.props.fetchCampaigns}
		fetchItemsSearch={this.props.searchCampaigns}
		nothingFoundMessage="Geen campagnes gevonden"
		LoadingMessage="Campagnes ophalen..."
		maxDisplayItems={20}
		newLink={{
			text: 'Nieuwe campagne',
			url: '/campaigns/new',
		}}
		getItemDisplayText={campaign => <Row className="w-75">
			<Col>
				{campaign.name}
			</Col>
			<Col>
				{moment(campaign.start).format('DD-MM-YYYY')}
			</Col>
		</Row>}
		getPrependActions={campaign => {
			return [
				{
					link: `/campaigns/${campaign._id}/mailings`,
					icon: 'mail-bulk',
					tooltip: `Bekijk verzendingen van ${campaign.name}`,
				},
			];
		}}
		getEditAction={campaign => {
			return {
				icon: 'edit',
				link: `/campaigns/${campaign._id}/edit`,
				tooltip: `${campaign.name} bewerken`,
			};
		}}
		getDeleteAction={campaign => {
			return {
				icon: 'times',
				iconClass: 'text-danger',
				onClick: () => this.onDeleteClick(campaign),
				tooltip: `${campaign.name} verwijderen`,
			};
		}}
		lastMessage={this.props.lastMessage}
	/>

	render() {
		return this.renderList();
	}
}

export default withPrompts(withLoader(ListCampaigns));
