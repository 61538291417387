import React from 'react';
import {Button, Form, FormGroup, Input, Label} from 'reactstrap';
import {withFormUtils} from '../../hoc/with-form-utils';

class InterestTypeForm extends React.Component {
	componentDidMount() {
		const {setDefaultValue, addTrimmedFields} = this.props;

		setDefaultValue('name', '');
		addTrimmedFields('name');
	}

	render() {
		const {getdata, onInputChange, onFormSubmit} = this.props;

		return <Form onSubmit={onFormSubmit}>
			<FormGroup>
				<Label>Naam:</Label>
				<Input type="text" name="name" value={getdata('name')} onChange={onInputChange} />
			</FormGroup>

			<FormGroup>
				<Button type="submit" color="primary">Submit</Button>
			</FormGroup>
		</Form>;
	}
}

export default withFormUtils(InterestTypeForm);
