import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import {Button, Form, FormGroup, Input, Label} from 'reactstrap';
import Select from 'react-select';
import moment from 'moment';

import {withFormUtils} from '../../hoc/with-form-utils';

import '../../css-overrides/react-datepicker.css';
import {isort} from '../../util/array';
import {withLoader} from '../../hoc/with-loader';

class CampaignForm extends React.Component {
	static propTypes = {
		types: PropTypes.array,
	};

	componentDidMount() {
		this.props.setDefaultValue({
			start: new Date().toISOString(),
		});
	}

	getLabel() {
		const label = this.props.getdata('type');
		return { label, value: label };
	}

	render() {
		const {getdata, onInputChange, onFormSubmit, onNumberInputChange} = this.props;

		const start = getdata('start');
		const momentDate = start ? moment(getdata('start')) : moment();

		const labels = isort(this.props.types || []).map(label => {
			return {
				value: label,
				label
			};
		});

		return <Form onSubmit={onFormSubmit}>
			<FormGroup>
				<Label>Naam</Label>
				<Input name="name" value={getdata('name')} onChange={onInputChange} />
			</FormGroup>

			<FormGroup>
				<Label>Klant</Label>
				<Input name="customer" value={getdata('customer')} onChange={onInputChange} />
			</FormGroup>

			<FormGroup>
				<Label>Start datum campagne. Let op: dit heeft rechtstreeks gevolgen voor het MPR</Label>
				<DatePicker
					className="form-control"
					selected={momentDate.isValid() ? momentDate.toDate() : new Date()}
					onChange={date => {
						const formattedDate = moment(date);
						onInputChange({
							target: {
								name: 'start',
								value: formattedDate,
							},
						});
					}}
					dropdownMode="scroll"
					dateFormat="dd-MM-yyyy"
				/>
			</FormGroup>

			<FormGroup>
				<Label>Label</Label>
				{this.props.renderWithLoader(this.props.types, () =>
					<Select
						value={this.getLabel()}
						options={labels}
						onChange={selected => onInputChange({ target: { name: 'type', value: selected.value }})}
						isSearchable />
				)}
			</FormGroup>

			<FormGroup>
				<Label>Aantal adressen ingekocht</Label>
				<Input type="number" name="addresses" value={getdata('addresses')} onChange={onInputChange} />
			</FormGroup>

			<FormGroup>
				<Label>Budget campagne</Label>
				<Input type="text" name="budget" value={getdata('budget')} onChange={onNumberInputChange} />
			</FormGroup>

			<FormGroup>
				<Button type="submit" color="primary">Sla op</Button>
			</FormGroup>
		</Form>;
	}
}

export default withFormUtils(withLoader(CampaignForm));
