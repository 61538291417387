import React from 'react';

import Message from '../../messages/Message';
import {withPrompts} from '../../hoc/with-prompts';
import {withLoader} from '../../hoc/with-loader';
import ListItems from '../crud/ListItems';

class ListPartnerTestPersons extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			dataset: null,
		};
	}

	componentDidMount() {
		this.getPartner();
	}

	getPartner() {
		this.props.fetchDataset(this.props.partnerId, this.props.datasetId).then(dataset => {
			this.setState({ dataset });
		}).catch(err => console.error(err));
	}

	onDeleteClick(e, testPerson) {
		e.preventDefault();
		e.stopPropagation();

		const testPersonName = [ testPerson.firstName, testPerson.preposition, testPerson.lastName ].join(' ');
		this.props.prompt({
			title: `Delete partner test person ${testPersonName}?`,
			text: `Are you sure you want to delete partner test person ${testPersonName}?`,
			onConfirm: () => {
				this.setState({
					lastMessage: null,
				});

				this.props.onDelete(this.props.partnerId, this.props.datasetId, testPerson._id).then(() => {
					this.getPartner();
					this.setState({
						lastMessage: new Message('Success', 'Test person has been deleted', {
							color: 'success',
						}),
					});
				}).catch(err => {
					this.setState({
						lastMessage: new Message('An error occured while deleting test person', err.message, {
							color: 'danger',
						}),
					});
				});
			},
		});

		return false;
	}

	renderList = () => <ListItems
		containerId="listTestPersons"
		headerText={`Testpersonen van ${this.state.dataset.name}`}
		items={this.state.dataset.testPersons}
		newLink={{
			text: 'Nieuwe testpersoon',
			url: `/partners/${this.props.partnerId}/datasets/${this.props.datasetId}/testpersons/new`,
		}}
		getItemDisplayText={testPerson => [ testPerson.firstName, testPerson.preposition, testPerson.lastName ].join(' ')}
		getEditAction={testPerson => {
			return {
				icon: 'edit',
				link: `/partners/${this.props.partnerId}/datasets/${this.props.datasetId}/testpersons/${testPerson._id}/edit`,
				tooltip: 'Deze testpersoon bewerken',
			};
		}}
		getDeleteAction={testPerson => {
			return {
				icon: 'times',
				onClick: e => this.onDeleteClick(e, testPerson),
				tooltip: 'Deze testpersoon verwijderen',
			};
		}}
		lastMessage={this.state.lastMessage}
	/>;

	render() {
		const { dataset } = this.state;
		return this.props.renderWithLoader(dataset && dataset.testPersons || null, this.renderList);
	}
}

export default withPrompts(withLoader(ListPartnerTestPersons));
