import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Form, FormGroup, Col, Container, Button, Input, InputGroup, InputGroupAddon, InputGroupText} from 'reactstrap';
import InputAutocomplete from '../../Autocomplete';
import Alert from '../../alert/Alert';
import PaginatorContainer from '../../../paginator/PaginatorContainer';

import {withMessages} from '../../../hoc/with-messages';
import FilterContainer from '../../../containers/FilterContainer';
import {makeRegExp} from '../../../util/string';

import Message from '../../../messages/Message';

const EDITOR_MESSAGE = 'EDITOR_MESSAGE';

const MAX_DISPLAY_ITEMS = 10;

export default withMessages(class ValueMappingEditor extends React.Component {
	static propTypes = {
		onSubmit: PropTypes.func.isRequired,
		returnURL: PropTypes.string.isRequired,
		filters: PropTypes.array.isRequired,
		headers: PropTypes.array.isRequired,
		filterName: PropTypes.string.isRequired,
		globalMappings: PropTypes.array.isRequired,
		onMappingChange: PropTypes.func.isRequired,
		message: PropTypes.instanceOf(Message),
		onRemapButtonClick: PropTypes.func.isRequired,
		remapButtonText: PropTypes.string.isRequired,
		remapButtonEnabled: PropTypes.bool,
		paginatorName: PropTypes.string.isRequired,
		mapping: PropTypes.object
	};

	static defaultProps = {
		message: null,
		remapButtonEnabled: true,
	};

	constructor(props) {
		super(props);

		this.state = {
			headers: null,
			onlyShowError: false,
			values: {},
			fetchedValues: {},
		};

		this.renderHeader = this.renderHeader.bind(this);
	}

	getFilteredHeaders() {
		const {headers} = this.props;
		const filter = this.props.filters.find(f => f.name === this.props.filterName);

		if (filter && filter.value) {
			const pattern = makeRegExp(filter.value, 'i');
			return headers.filter(h => pattern.test(h));
		}

		return headers;
	}

	renderHeaderInput(header) {
		const input = <Input type="text" readOnly value={header.value} />;

		if (typeof header.amount === 'number') {
			return <InputGroup>
				{input}
				<InputGroupAddon addonType="append">
					<InputGroupText>x {header.amount}</InputGroupText>
				</InputGroupAddon>
			</InputGroup>;
		}

		return input;
	}

	getValue(header, key, def = '') {
		const {mapping} = this.props;

		if (mapping && Array.isArray(mapping.values)) {
			const value = mapping.values.find(v => v.original === header.value);

			if (value && 'mapped' in value) {
				return value.mapped;
			}
		}

		return def;
	}

	renderHeader(header) {
		return <FormGroup key={typeof header === 'string' ? header : header.value} row>
			<Col>
				{this.renderHeaderInput(header)}
			</Col>

			<Col>
				<InputAutocomplete
					items={this.props.globalMappings}
					disabled={!this.props.mapping || this.props.encrypted}
					value={this.getValue(header, 'mapped')}
					onChange={e => this.props.onMappingChange(header, { mapping: e.target.value })}
					shouldItemRender={(item, val) => item.toLowerCase().includes(val.toLowerCase())}
				/>
			</Col>
		</FormGroup>;
	}

	render() {
		const {message} = this.props;
		const editorMessage = this.props.messages[EDITOR_MESSAGE];
		const alert = editorMessage && <Alert message={editorMessage} />;
		const alertFromProps = message && <Alert message={message} />;

		if (this.state.onlyShowError) {
			return alert;
		}

		return <div id="dataset-header-editor">
			<Container>
				<h1>Map values from a dataset column</h1>

				{alert}
				{alertFromProps}
			</Container>

			<Container className="text-left">
				<FilterContainer name={this.props.filterName} />

				<Form onSubmit={this.props.onSubmit}>
					<PaginatorContainer
						name={this.props.paginatorName}
						items={this.getFilteredHeaders()}
						renderItem={this.renderHeader}
						seed={Date.now()}
						maxDisplayItems={MAX_DISPLAY_ITEMS}
					/>

					<FormGroup>
						<Button color="primary">Save</Button>
						<Link className="btn btn-info ml-2" to={this.props.returnURL}>Back to column mappings</Link>
						<Button className="ml-2" color="info" onClick={this.props.onRemapButtonClick} disabled={!this.props.remapButtonEnabled}>{this.props.remapButtonText}</Button>
					</FormGroup>
				</Form>
			</Container>
		</div>;
	}
});
