import React from 'react';
import PropTypes from 'prop-types';
import {Container, ListGroup, Form, FormGroup, Input} from 'reactstrap';
import {Link} from 'react-router-dom';

import Alert from '../alert/Alert';
import ListItem from '../list-group/ListItem';
import Message from '../../messages/Message';
import Paginator from '../../paginator/Paginator';

const WAIT = 500;
const maxInputLength = 100;

export default class ListItemsPaginated extends React.Component {
	state = {
		searchTerm: '',
		pages: 0,
		page: 1,
		totalItems: 0,
		currentItems: [],
	};
	timeout = 0;

	static propTypes = {
		containerId: PropTypes.string.isRequired,
		nothingFoundMessage: PropTypes.string.isRequired,
		LoadingMessage: PropTypes.string.isRequired,
		headerText: PropTypes.string,
		headerTag: PropTypes.string,
		preListText: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.node,
		]),
		fetchItems: PropTypes.func.isRequired,
		newLink: PropTypes.shape({
			text: PropTypes.string.isRequired,
			url: PropTypes.string.isRequired,
		}),
		itemIdProperty: PropTypes.any,
		itemDisplayTextProperty: PropTypes.any,
		lastMessage: PropTypes.instanceOf(Message),
		getItemDisplayText: PropTypes.func,
		getEditAction: PropTypes.func,
		getDeleteAction: PropTypes.func,
		getPrependActions: PropTypes.func,
		getAppendActions: PropTypes.func,
		maxDisplayItems: PropTypes.number,
	};

	static defaultProps = {
		headerText: '',
		headerTag: 'h1',
		preListText: null,
		itemDisplayTextProperty: null,
		getItemDisplayText: null,
		hasPaginator: false,
		maxDisplayItems: 10,
		getPrependActions: () => [],
		getAppendActions: () => [],
		getEditAction: () => [],
		getDeleteAction: () => [],
	};

	componentWillUnmount() {
		if(this.props.clearMessage){
			this.props.clearMessage();
		}
	}

	getDisplayText(item) {
		if (this.props.getItemDisplayText !== null) {
			return this.props.getItemDisplayText(item);
		} else if (this.props.itemDisplayTextProperty !== null) {
			return item[this.props.itemDisplayTextProperty];
		}

		throw new Error('No display text methods have been specified');
	}

	getActions = item => [].concat(
		this.props.getPrependActions(item),
		this.props.getEditAction(item),
		this.props.getDeleteAction(item),
		this.props.getAppendActions(item),
	);

	onSearch = e => {
		e.preventDefault();
		this.setState({ searchTerm: e.target.value, page: 1 });
	}

	componentDidMount() {
		this.fetchDisplayItems();
	}

	componentDidUpdate(props, state) {
		const { page, searchTerm } = this.state;
		if(state.page !== page) {
			clearTimeout(this.timeout);
			this.fetchDisplayItems();
		} else if(state.searchTerm !== searchTerm) {
			clearTimeout(this.timeout);
			this.timeout = setTimeout(this.fetchDisplayItems, WAIT);
		}
	}

	fetchDisplayItems = () => {
		const { page, searchTerm } = this.state;
		const { fetchItemsSearch, fetchItems, maxDisplayItems } = this.props;
		const fetch = searchTerm ? fetchItemsSearch(page, maxDisplayItems, searchTerm) : fetchItems(page, maxDisplayItems);
		fetch.then(itemsObject => {
			this.setState({pages: itemsObject.pages, currentItems: itemsObject.items, totalItems: itemsObject.total});
		}).catch(err => {
			console.log('Failed to get items', err);
		});
	}

	renderListGroup() {
		return this.state.currentItems.map((item, i) => {
			return <ListItem key={'item' + i} item={item} text={this.getDisplayText(item)} actions={this.getActions(item)} />;
		});
	}

	setPage = (e, page) => {
		this.setState({page});
	}

	render() {
		return <div className={this.props.containerId}>
			<Container className="mb-4">
				<Form className="float-right w-50">
					<FormGroup className="mt-4">
						<Input type="text" placeholder="Zoeken" maxLength={maxInputLength} onChange={this.onSearch} autoFocus={true}/>
					</FormGroup>
				</Form>
				{this.props.headerText && React.createElement(this.props.headerTag, null, this.props.headerText)}

				{this.props.description && <p>{this.props.description}</p>}

				{this.props.newLink && <p><Link id={this.props.newLink.text + 'link'} to={this.props.newLink.url}>{this.props.newLink.text}</Link></p>}

				{this.props.lastMessage && <Alert message={this.props.lastMessage} />}

				{this.props.preListText}

				<ListGroup className="mt-5">
					{this.renderListGroup()}
					{
						this.state.currentItems.length > 0
							?
							<Paginator
								itemCount={this.state.totalItems}
								maxDisplayItems={this.props.maxDisplayItems}
								currentPage={this.state.page}
								onPaginatorPageClick={this.setPage}
								maxPaginatorButtons={this.state.pages}
								centered={true}
							/>
							:
							''
					}
					{
						this.state.currentItems.length === 0
							?
							this.state.searchTerm ? this.props.nothingFoundMessage : this.props.LoadingMessage
							:
							''
					}

				</ListGroup>
			</Container>
		</div>;
	}
}
