import React from 'react';
import PropTypes from 'prop-types';

import {isort} from '../../util/array';
import Message from '../../messages/Message';
import {withPrompts} from '../../hoc/with-prompts';
import ListItems from '../crud/ListItems';
import {withLoader} from '../../hoc/with-loader';

class ListTypes extends React.Component {
	static propTypes = {
		types: PropTypes.array,
		lastMessage: PropTypes.instanceOf(Message),
		deleteType: PropTypes.func.isRequired,
	};

	onDeleteClick = type => this.props.prompt({
		title: `Verwijder ${type}?`,
		text: `Weet je zeker dat je type ${type} wilt verwijderen?`,
		onConfirm: () => this.props.deleteType(type),
	});

	renderList = () => <ListItems
		containerId="listTypes"
		headerText="Lijst van labels"
		items={isort(this.props.types)}
		newLink={{
			text: 'Nieuw label',
			url: '/types/new',
		}}
		getItemDisplayText={type => type}
		getEditAction={type => {
			return {
				icon: 'edit',
				link: `/types/${type}/edit`,
				tooltip: `Bewerk ${type}`,
			};
		}}
		getDeleteAction={type => {
			return {
				icon: 'times',
				iconClass: 'text-danger',
				onClick: () => this.onDeleteClick(type),
				tooltip: `${type} verwijderen`,
			};
		}}
		lastMessage={this.props.lastMessage}
	/>;

	render = () => this.props.renderWithLoader(this.props.types, this.renderList);
}

export default withPrompts(withLoader(ListTypes));
