import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';

const THEME = {
	container: 'dropdown',
	containerOpen: '',
	input: 'form-control',
	inputFocused: '',
	suggestionsContainer: 'dropdown-menu',
	suggestionsContainerOpen: 'show shadow-sm',
	suggestionsList: 'list-group',
	suggestion: 'dropdown-item',
	suggestionFirst: '',
	suggestionHighlighted: 'active',
	sectionContainer: '',
	sectionContainerFirst: '',
	sectionTitle: ''
};

function getValue(suggestion) {
	if(typeof suggestion === 'string') {
		return suggestion;
	}
	return suggestion.value;
}

function renderSuggestion(suggestion) {
	return <span>{typeof suggestion === 'string' ? suggestion : suggestion.label || suggestion.value}</span>;
}

export default class AutoComplete extends Component {
	state = { suggestions: null };

	onChange = (e, { newValue }) => {
		this.props.onChange({ target: { value: newValue } });
	};

	fetchSuggestions = ({ value }) => {
		this.setState({
			suggestions: this.props.items.filter(item => this.props.shouldItemRender(item, value))
		});
	};

	componentDidUpdate(props) {
		if(this.props.items !== props.items && this.state.suggestions) {
			this.fetchSuggestions(this.props);
		}
	}

	render() {
		const props = Object.assign({}, this.props);
		props.onChange = this.onChange;
		delete props.items;
		delete props.shouldItemRender;
		return <Autosuggest
			id={`autocomplete_${props.id || props.name || ''}`}
			theme={THEME}
			suggestions={this.state.suggestions || []}
			onSuggestionsFetchRequested={this.fetchSuggestions}
			onSuggestionsClearRequested={() => this.setState({ suggestions: null })}
			onSuggestionSelected={e => e.preventDefault()}
			getSuggestionValue={getValue}
			renderSuggestion={renderSuggestion}
			shouldRenderSuggestions={() => true}
			inputProps={props} />;
	}
}
