import React from 'react';
import HttpStatus from 'http-status-codes';
import DNSCheck from '../components/dns/DNSCheck';
import { Poller, getPollerData } from '../util/Poller';
import { checkDNS, pollTask, blacklistDomains } from '../api';

const DONE = 100;
const TIMEOUT = 1000;

export default class DNSCheckContainer extends React.Component {
	state = {
		checking: false,
		error: null,
		results: null,
		selected: {},
		adding: false,
		state: null
	};

	check = () => {
		this.setState({ checking: true, error: null, results: null });
		checkDNS().then(id => {
			const poller = new Poller(() => pollTask(id, TIMEOUT));
			poller.addWatcher(data => data.percentage < DONE, data => {
				this.setState({ state: { state: data.state, percentage: data.percentage } });
			});
			return getPollerData(poller);
		}).then(data => {
			const selected = {};
			data.result.forEach(result => {
				if(result.check === HttpStatus.NO_CONTENT || result.check === HttpStatus.NOT_FOUND || result.check === HttpStatus.BAD_REQUEST) {
					selected[result.domain] = true;
				} else {
					selected[result.domain] = false;
				}
			});
			this.setState({ checking: false, results: data.result, selected });
		}).catch(err => {
			this.setState({
				error: err.message,
				checking: false
			});
		});
	};

	select = change => {
		this.setState(state => {
			const clone = Object.assign({}, state);
			clone.selected = Object.assign({}, state.selected, change);
			return clone;
		});
	};

	blacklist = () => {
		const { selected } = this.state;
		const toBlacklist = Object.keys(selected).filter(d => selected[d]);
		if(toBlacklist.length) {
			this.setState({ adding: true, error: null });
			blacklistDomains(toBlacklist).then(() => {
				this.setState({ adding: false });
			}).catch(err => {
				this.setState({
					error: err.message,
					adding: false
				});
			});
		}
	};

	render() {
		return <DNSCheck {...this.state} check={this.check} select={this.select} blacklist={this.blacklist} />;
	}
}
