import React from 'react';
import PropTypes from 'prop-types';
import {Form, Button, FormGroup, Row, Col, Input, Label} from 'reactstrap';
import PartnerField from './PartnerField';
import {withFormUtils} from '../../hoc/with-form-utils';
import countries from './countries';

class PartnerForm extends React.Component {
	static propTypes = {
		idIsReadReadOnly: PropTypes.bool,
		data: PropTypes.object,
	};

	static defaultProps = {
		idIsReadReadOnly: false,
		data: {},
	};

	componentDidMount() {
		this.props.setDefaultValue('country', 'NL');
	}

	render() {
		const {onFormSubmit, onInputChange, getdata} = this.props;

		return <Form onSubmit={onFormSubmit}>
			<PartnerField label="ID" name="id" onChange={onInputChange} getdata={getdata} readOnly={this.props.idIsReadReadOnly} />
			<PartnerField label="Naam" name="name" onChange={onInputChange} getdata={getdata} />
			<PartnerField label="Crediteurnummer" name="debitorNumber" onChange={onInputChange} getdata={getdata} />
			<PartnerField label="Adres" name="address" onChange={onInputChange} getdata={getdata} />
			<PartnerField label="Postcode" name="postalCode" onChange={onInputChange} getdata={getdata} />
			<PartnerField label="Plaats" name="city" onChange={onInputChange} getdata={getdata} />
			<PartnerField label="IBAN nummer" name="iban" onChange={onInputChange} getdata={getdata} />
			<PartnerField label="BTW nummer" name="vatNumber" onChange={onInputChange} getdata={getdata} />
			<PartnerField label="E-mail address facturatie" name="invoiceEmail" onChange={onInputChange} getdata={getdata} />

			<Row>
				<Col>
					<Label>Land</Label>
					<Input
						name="country"
						type="select"
						onChange={onInputChange}
						defaultValue={getdata('country') === '' ? 'NL' : getdata('country')}
						children={
							countries.sort((countryA, countryB) => {
								const a = countryA.name.toLowerCase();
								const b = countryB.name.toLowerCase();
								return a.localeCompare(b);
							}).map((country, i) => {
								return <option key={i} value={country.code}>{country.name}</option>;
							})
						}
					/>
				</Col>
				<Col>
					<PartnerField label="Grootboekrekening" name="ledger" onChange={onInputChange} getdata={getdata} />
				</Col>
				<Col>
					<PartnerField label="BTW code" name="vatCode" onChange={onInputChange} getdata={getdata} />
				</Col>
				<Col>
					<PartnerField label="BTW percentage" name="vatPercentage" type="number" onChange={onInputChange} getdata={getdata} />
				</Col>
			</Row>
			<PartnerField label="Opmerkingen" type="textarea" name="comments" onChange={onInputChange} getdata={getdata} />


			<FormGroup>
				<Button color="primary" type="submit">Versturen</Button>
			</FormGroup>
		</Form>;
	}
}

export default withFormUtils(PartnerForm);
