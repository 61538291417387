import React from 'react';
import { Container, Table } from 'reactstrap';
import { fetchPartnerBlacklist } from '../api';

export default class PartnerBlacklistContainer extends React.Component {
	state = {
		blacklist: null
	}

	componentWillMount() {
		fetchPartnerBlacklist(this.props.match.params.partnerId).then(blacklist => {
			this.setState({ blacklist });
		});
	}

	render() {
		const { partnerId } = this.props.match.params;
		const { blacklist } = this.state;
		return <Container>
			<h1 className="mb-5">{partnerId}{partnerId[partnerId.length - 1] === 's' ? '\'' : '\'s'} blacklist</h1>

			{blacklist && <Table>
				<thead>
					<tr>
						<th>Bestand</th>
						<th>E-mailadressen in blacklist</th>
					</tr>
				</thead>
				<tbody>
					{blacklist.map((row, i) => <tr key={i}>
						<td>{row.dataset}</td>
						<td>{row.blacklisted}</td>
					</tr>)}
				</tbody>
			</Table>}
		</Container>;
	}
}
