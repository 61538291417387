import React from 'react';
import { Container} from 'reactstrap';
import {Link} from 'react-router-dom';

export default function EmailBlacklist({ emailAddresses }) {
	return <Container>
		<h1>E-mail blacklist</h1>
		<p>
			Momenteel zijn er {emailAddresses} e-mailadressen geblacklist.
		</p>
		<Link to='/blacklist/upload'>Upload CSV bestand</Link>
	</Container>;
}
