import React from 'react';
import {Redirect} from 'react-router-dom';
import {Container} from 'reactstrap';
import Message from '../../messages/Message';
import Alert from '../alert/Alert';
import PartnerTestPersonForm from './PartnerTestPersonForm';

export default class UpdatePartnerTestPerson extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			testPerson: null,
			errorMessage: null,
			onlyShowError: false,
			doRedirect: false,
		};

		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		this.getTestPerson();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.partnerId !== this.props.partnerId || prevProps.datasetId !== this.props.datasetId || prevProps.personId !== this.props.personId) {
			this.getTestPerson();
		}
	}

	getTestPerson() {
		this.setState({
			errorMessage: null,
			onlyShowError: false,
		});

		this.props.fetchPartnerTestPerson(this.props.partnerId, this.props.datasetId, this.props.personId).then(testPerson => {
			this.setState({ testPerson });
		}).catch(err => {
			this.setState({
				errorMessage: new Message('An error occured while fetching test person', err.message, {
					color: 'danger',
				}),
				onlyShowError: true,
			});
		});
	}

	onSubmit(testPerson) {
		this.setState({
			onlyShowError: false,
			errorMessage: null,
		});

		this.props.onSubmit(this.props.partnerId, this.props.datasetId, this.props.personId, testPerson).then(() => {
			this.setState({
				doRedirect: true,
			});
		}).catch(err => {
			this.setState({
				errorMessage: new Message('An error occured while saving test person', err.message, {
					color: 'danger',
				}),
			});
		});
	}

	render() {
		if (this.state.doRedirect) {
			return <Redirect to={`/partners/${this.props.partnerId}/datasets/${this.props.datasetId}/testpersons`} />;
		}

		const alert = this.state.errorMessage && <Alert message={this.state.errorMessage} />;

		if (this.state.onlyShowError) {
			return alert;
		}

		if (!this.state.testPerson) {
			return 'Loading...';
		}

		const {firstName, preposition, lastName} = this.state.testPerson;
		const name = [ firstName, preposition, lastName ].join(' ');

		return <div id="updateTestPerson">
			<Container>
				<h1>Update test person {name}</h1>

				{alert}

				<PartnerTestPersonForm
					onSubmit={this.onSubmit}
					data={this.state.testPerson}
				/>
			</Container>
		</div>;
	}
}
