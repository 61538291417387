import React from 'react';
import { Container, Form, FormGroup, Row, Col, Input, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import Alert from '../alert/Alert';

export default function SettingsForm({ message, save, minMailings, setMinMailings, solarId, setSolarId }) {
	return <Container>
		<h2>Instellingen</h2>
		<p>
			<Link to="/settings/testperson">Bewerk testpersoon</Link>
		</p>
		{message && <Alert message={message} />}
		<Form onSubmit={save}>
			<Row className="mb-2">
				<Col>
					Minimale verzendingen<br />
					<small>De hoeveelheid verzendingen waarin een e-mailadres moet zijn aangeboden voordat het als inactief wordt gemarkeerd.</small>
				</Col>
				<Col>
					<Input type="number" value={minMailings} onChange={e => {
						setMinMailings(isNaN(e.target.value) ? e.target.value : +e.target.value);
					}} />
				</Col>
			</Row>
			<Row className="mb-2">
				<Col>
					Standaard Solar ID<br />
					<small>Het solar ID voor test personen.</small>
				</Col>
				<Col>
					<Input value={solarId} onChange={e => {
						setSolarId(e.target.value);
					}} />
				</Col>
			</Row>
			<FormGroup>
				<Button color="primary">Sla op</Button>
			</FormGroup>
		</Form>
	</Container>;
}
