import React, { useState } from 'react';
import { useParams } from 'react-router';
import Alert from 'reactstrap/lib/Alert';
import Button from 'reactstrap/lib/Button';
import InputGroup from 'reactstrap/lib/InputGroup';
import Input from 'reactstrap/lib/Input';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import { enable2FA, disable2FA } from '../../api';
import Label from 'reactstrap/lib/Label';

function TwoFaForm({ enabled, username, reload }) {
	const [link, setLink] = useState(null);
	const [error, setError] = useState(null);
	const { userId } = useParams();

	const reset = () => {
		setError(null);
		setLink(null);
	};

	const enable = () => {
		reset();
		enable2FA(userId, username)
			.then(l => {
				setLink(l);
				reload();
			})
			.catch(setError);
	};
	
	const disable = () => {
		reset();
		disable2FA(userId)
			.then(reload)
			.catch(setError);
	};

	const copy = () => navigator.clipboard.writeText(link);

	return <div>
		<h3>Two Factor Authentication</h3>

		<Alert isOpen={error} color="danger">{error?.message || 'Something went wrong, please try again later.'}</Alert>

		<Button onClick={enabled? disable : enable} color={enabled? 'success':'danger'}>
			{enabled? 'Disable':'Enable'}
		</Button>

		{link && <div className="col-md-5 p-0 mt-2"> 
			<Label>Send the link below to the user</Label>
			<InputGroup>
				<Input value={link} readOnly/>
				<InputGroupAddon addonType="append">
					<Button color="primary" onClick={copy}>Copy</Button>
				</InputGroupAddon>
			</InputGroup>
		</div>}
	</div>;
}

export default TwoFaForm;
