import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getBreadCrumbs } from '../util/routes';

export default withRouter(function BreadcrumbContainer(props) {
	const { pathname } = props.location;
	return <div id="breadcrumb-bar">
		<Container>
			<Row>
				<Col>
					<Breadcrumb className="list-style-none pt-3" >
						{getBreadCrumbs(pathname).filter(breadcrumb => breadcrumb.text).map((breadcrumb, i, arr) => {
							const isLast = i + 1 === arr.length;
							return <BreadcrumbItem key={i} className="d-inline-block">
								{/* If the Link is the current page, make it text, not a link */
									isLast ?
										breadcrumb.text
										:
										<Link to={breadcrumb.path}>{breadcrumb.text}</Link>
								}
							</BreadcrumbItem>;
						})}
					</Breadcrumb>
				</Col>
			</Row>
		</Container>
	</div>;
});
