import React from 'react';
import PropTypes from 'prop-types';
import {Container} from 'reactstrap';

import TypeForm from './TypeForm';
import Alert from '../alert/Alert';

import {withMessages} from '../../hoc/with-messages';
import Message from '../../messages/Message';

class NewType extends React.Component {
	static propTypes = {
		onSubmit: PropTypes.func.isRequired,
		lastMessage: PropTypes.instanceOf(Message),
	};

	render() {
		return <div id="newType">
			<Container>
				<h1>New type</h1>

				{this.props.lastMessage && <Alert message={this.props.lastMessage} />}

				<TypeForm onSubmit={this.props.onSubmit} />
			</Container>
		</div>;
	}
}

export default withMessages(NewType);
