import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {Row, Col, Container, Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input} from 'reactstrap';
import {Link} from 'react-router-dom';

import ListItems from '../crud/ListItems';
import { withPrompts } from '../../hoc/with-prompts';
import Message from '../../messages/Message';
import { MailingState } from '../../enum/MailingState';
import {MailingStateText} from '../../enum/MailingStateText';
import Alert from '../alert/Alert';

const displayOrder = [
	{
		state: MailingState.FINAL,
		text: MailingStateText.FINAL,
		order: 2,
	},
	{
		state: MailingState.OFFERED,
		text: MailingStateText.OFFERED,
		order: 3,
	},
	{
		state: MailingState.DRAFT,
		text: MailingStateText.DRAFT,
		order: 1,
	},
	{
		state: MailingState.CANCELLED,
		text: MailingStateText.CANCELLED,
		order: 4,
	},
].sort((a, b) => {
	if (a.order < b.order) return -1;
	if (a.order > b.order) return 1;
	return 0;
});

class ListMailings extends React.Component {
	static propTypes = {
		selectedCampaign: PropTypes.object.isRequired,
		baseURL: PropTypes.string.isRequired,
		mailings: PropTypes.array.isRequired,
		lastMessage: PropTypes.instanceOf(Message),
		deleteMailing: PropTypes.func.isRequired,
		prompt: PropTypes.func.isRequired,
		onLimitSubmit: PropTypes.func.isRequired,
	};

	state = {
		isModalOpen: false,
		mailingId: null,
		mailingName: '',
		limit: '',
		pressure: null
	};

	groupMailings = () => {
		return this.props.mailings.reduce((mailings, mailing) => {
			const {state} = mailing;

			if (!mailings[state]) {
				mailings[state] = [];
			}

			mailings[state].push(mailing);

			return mailings;
		}, {});
	}

	openModal(mailing, modal) {
		this.setState({
			isModalOpen: modal,
			mailingId: mailing._id,
			mailingName: mailing.name,
			limit: mailing.addresses,
			pressure: null
		});
	}

	closeModal = () => {
		this.setState({
			isModalOpen: false,
			mailingId: null,
			mailingName: '',
			limit: '',
		});
	};

	onLimitChange = e => {
		const {value} = e.target;

		if (isNaN(value)) {
			return;
		}

		const limit = Math.max(0, +value);

		this.setState({
			limit,
		});
	};

	submitLimit = () => {
		const {mailingId, limit} = this.state;
		if (limit !== '') {
			this.props.onLimitSubmit(mailingId, limit);
			this.closeModal();
		}
	};

	submitPressure = () => {
		const { mailingId } = this.state;
		this.props.onPressureSubmit(mailingId);
		this.closeModal();
	};

	render() {
		const {selectedCampaign, baseURL, lastMessage, deleteMailing, prompt} = this.props;

		const mailingsGroup = this.groupMailings();

		const items = displayOrder.map(orderItem => {
			const mailings = mailingsGroup[orderItem.state];

			if (!mailings) {
				return null;
			}

			mailings.sort((mailingA, mailingB) => {
				const dateA = new Date(mailingA.plannedSend);
				const dateB = new Date(mailingB.plannedSend);
				return dateB - dateA;
			});

			return <ListItems
				key={orderItem.state}
				containerId="listMailings"
				headerText={orderItem.text}
				headerTag="h2"
				items={mailings}
				itemIdProperty="_id"
				getItemDisplayText={mailing => <Row>
					<Col>
						<span className="d-inline-block mr-2">{moment(mailing.plannedSend).format('DD-MM-YYYY')}</span> {mailing.name}
					</Col>

					<Col>
						Aantal e-mails: <strong>{mailing.recordCount}</strong><br />
						Nog toe te kennen: <strong>{Math.max(0, mailing.addresses - mailing.recordCount)}</strong>
					</Col>
				</Row>}
				getPrependActions={mailing => {
					const actions = [];
					if(mailing.state === MailingState.DRAFT) {
						actions.push({
							icon: 'history',
							tooltip: 'Controleer maildruk',
							onClick: () => {
								this.openModal(mailing, 'pressure');
								this.props.getPressure(mailing._id).then(pressure => {
									this.setState({ pressure });
								});
							},
						},
						{
							icon: 'user-minus',
							tooltip: 'Aantal adressen maximeren',
							onClick: () => this.openModal(mailing, 'limit'),
						},
						{
							link: `${baseURL}/${mailing._id}/copy`,
							icon: 'copy',
							tooltip: 'Kopieër adressen naar deze verzending',
						});
					} else if(mailing.state === MailingState.FINAL) {
						actions.push({
							link: `/api${baseURL}/${mailing._id}/testcsv`,
							linkIsRegular: true,
							icon: 'file-download',
							tooltip: 'Download testlijst',
						});
					}
					actions.push({
						link: `/api${baseURL}/${mailing._id}/csv`,
						linkIsRegular: true,
						icon: 'file-csv',
						tooltip: 'Download CSV',
					});
					if(mailing.state === MailingState.DRAFT) {
						actions.push({
							link: `/selection-builder/${selectedCampaign._id}/${mailing._id}`,
							icon: 'tools',
							tooltip: 'Ga naar selectie builder',
						});
					}
					actions.push({
						link: `${baseURL}/${mailing._id}/selections`,
						icon: 'th-list',
						tooltip: 'Bekijk selecties',
					});
					return actions;
				}}
				getEditAction={mailing => {
					return {
						link: `${baseURL}/${mailing._id}/edit`,
						icon: 'edit',
						tooltip: 'Bewerk verzending',
					};
				}}
				getDeleteAction={mailing => {
					if(mailing.state !== MailingState.DRAFT) {
						return [];
					}
					return {
						icon: 'times',
						iconClass: 'text-danger',
						tooltip: 'Verwijder mailing',
						onClick: () => prompt({
							title: `Delete mailing ${mailing.name}?`,
							text: `Are you sure you want to delete mailing ${mailing.name}?`,
							onConfirm: () => deleteMailing(selectedCampaign._id, mailing._id),
						}),
					};
				}}
				hasPaginator
			/>;
		}).filter(elements => elements);

		const alert = lastMessage && <Alert message={lastMessage} />;
		const { pressure } = this.state;
		return <React.Fragment>
			<Modal isOpen={this.state.isModalOpen === 'limit'} toggle={this.closeModal}>
				<ModalHeader toggle={this.closeModal}>Aantal adressen maximeren in <strong>{this.state.mailingName}</strong></ModalHeader>

				<ModalBody>
					<Label>Maximaal aantal adressen</Label>
					<Input type="text" value={this.state.limit} onChange={this.onLimitChange} />
				</ModalBody>

				<ModalFooter>
					<Button color="secondary" onClick={this.closeModal}>Annuleren</Button>
					<Button color="primary" onClick={this.submitLimit}>Maximeren</Button>
				</ModalFooter>
			</Modal>

			<Modal isOpen={this.state.isModalOpen === 'pressure'} toggle={this.closeModal}>
				<ModalHeader toggle={this.closeModal}>Adressen controleren op maildruk in <strong>{this.state.mailingName}</strong></ModalHeader>

				<ModalBody>
					{pressure ? <React.Fragment>
						<Row>
							<Col>Nieuw</Col>
							<Col>{pressure.new}</Col>
						</Row>
						<Row>
							<Col>Inactief</Col>
							<Col>{pressure.none}</Col>
						</Row>
						<Row>
							<Col>Opens</Col>
							<Col>{pressure.opened}</Col>
						</Row>
						<Row>
							<Col>Kliks</Col>
							<Col>{pressure.clicked}</Col>
						</Row>
					</React.Fragment> : <p>Laden...</p>}
				</ModalBody>

				<ModalFooter>
					<Button color="secondary" onClick={this.closeModal}>Annuleren</Button>
					<Button color="primary" onClick={this.submitPressure}>Verwijderen</Button>
				</ModalFooter>
			</Modal>

			<Container className="mb-4">
				<h1>Lijst van verzendingen</h1>
				<h5>Campagne: {this.props.selectedCampaign.name}</h5>

				<Link to={`${baseURL}/new`}>Nieuwe verzending</Link>

				{alert}
			</Container>

			{items}
		</React.Fragment>;
	}
}

export default withPrompts(ListMailings);
