import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import ListItems from '../crud/ListItems';

export default class EnrichmentList extends Component {
	renderFile(file) {
		return <Row className="w-75">
			<Col md={6} className="text-truncate">{file.name}</Col>
			<Col md={4}>{file.date.toLocaleString()}</Col>
			<Col md={2} className="text-right">{file.recordCount}</Col>
		</Row>;
	}

	render() {
		return <Container>
			<ListItems
				containerId="listEnrichmentFiles"
				headerText="Verrijkingsbestanden"
				getItemDisplayText={this.renderFile}
				getDeleteAction={file => {
					return {
						icon: 'times',
						iconClass: 'text-danger',
						onClick: () => this.props.deleteFile(file),
						tooltip: `${file.name} verwijderen`,
					};
				}}
				items={this.props.files} />
		</Container>;
	}
}
