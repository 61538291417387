import React, { Component } from 'react';
import { Form, Input, FormGroup, Row, Col, ButtonGroup, Button } from 'reactstrap';
import InputAutocomplete from '../Autocomplete';

export default class EnrichmentForm extends Component {
	setMapped(i) {
		return e => {
			const { mappings, setMappings } = this.props;
			const copy = mappings.slice();
			copy[i].mapped = e.target.value;
			setMappings(copy);
		};
	}

	setMatch(i, value) {
		return () => {
			const { mappings, setMappings } = this.props;
			const copy = mappings.slice();
			copy[i].match = value;
			setMappings(copy);
		};
	}

	render() {
		const { name, setName, mappings, attributes, submit, uploading } = this.props;
		let hasMapped = false;
		let hasMatched = false;
		return <Form onSubmit={submit}>
			<FormGroup className="mb-4">
				<h2>Upload bestand</h2>
				<Input type="text" value={name} onChange={e => setName(e.target.value)} required placeholder="Bestandsnaam" />
			</FormGroup>
			{mappings.map((mapping, i) => {
				if(mapping.match && mapping.mapped) {
					hasMatched = true;
				} else if(mapping.mapped) {
					hasMapped = true;
				}
				return <Row key={i} className="mb-2">
					<Col><Input type="text" value={mapping.field} readOnly /></Col>
					<Col>
						<InputAutocomplete
							items={attributes || []}
							value={mapping.mapped}
							onChange={this.setMapped(i)}
							shouldItemRender={(item, val) => item.toLowerCase().includes(val.toLowerCase())}
						/>
					</Col>
					<Col>
						<ButtonGroup>
							<Button disabled={!mapping.mapped} color={mapping.match ? 'primary' : 'light'} onClick={this.setMatch(i, true)}>Match</Button>
							<Button disabled={!mapping.mapped} color={mapping.match ? 'light' : 'primary'} onClick={this.setMatch(i, false)}>Verrijk</Button>
						</ButtonGroup>
					</Col>
				</Row>;
			})}
			<FormGroup className="mt-5">
				<Input disabled={!uploading && !hasMapped || !hasMatched} type="submit" value="Upload" className="btn btn-primary" />
			</FormGroup>
		</Form>;
	}
}
