import React, { Component } from 'react';
import ValueMappingsList from '../components/valuemappings/ValueMappingsList';
import { getValueMappedFields } from '../api';

export default class ValueMappingsContainer extends Component {
	state = {
		fields: null,
		error: null
	};

	componentDidMount() {
		getValueMappedFields().then(fields => {
			this.setState({ fields });
		}).catch(err => {
			this.setState({ error: err.message });
		});
	}

	render() {
		const { fields, error } = this.state;
		return <ValueMappingsList fields={fields} error={error} />;
	}
}
