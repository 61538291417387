import React from 'react';
import { Switch, Route } from 'react-router-dom';

import BlacklistUpload from '../components/blacklist/BlacklistUpload';
import DomainBlacklist from '../components/blacklist/DomainBlacklist';
import EmailBlacklist from '../components/blacklist/EmailBlacklist';

import { fetchEmailBlacklistLength, fetchDomainBlacklist, submitEmailToBlacklist, submitDomainToBlacklist, deleteDomainFromBlacklist, pollTask, submitPartnerBlacklist } from '../api';
import { withLoader } from '../hoc/with-loader';
import { withPrompts } from '../hoc/with-prompts';
import { withMessages } from '../hoc/with-messages';
import { getData } from '../util/Poller';

const LIST_MESSAGE = 'LIST_MESSAGE';
const CSV_MESSAGE = 'CSV_MESSAGE';

class BlacklistContainer extends React.Component {
	state = {
		domains: [],
		emails: 0
	}

	componentDidMount() {
		this.getEmailBlacklistLength();
		this.getDomainBlacklist();
	}

	getEmailBlacklistLength = () => fetchEmailBlacklistLength().then(countObject => {
		this.setState({emails: countObject.blacklistLength});
	})

	getDomainBlacklist = () => fetchDomainBlacklist().then(blacklist => {
		const domains = blacklist.map(domain => {
			return domain.blacklist.domain;
		});
		this.setState({domains});
	});

	submitEmailBlacklist = (file, emailColumn) => {
		this.props.clearMessage(LIST_MESSAGE);
		this.props.clearMessage(CSV_MESSAGE);

		return submitEmailToBlacklist(file, emailColumn).then(data => data.task).then(taskId => {
			this.props.setMessage(LIST_MESSAGE, 'Upload begonnen', 'De upload is begonnen', {
				color: 'info',
			});

			return getData(() => pollTask(taskId));
		}).then(() => {
			this.props.setMessage(LIST_MESSAGE, 'Upload geslaagd', 'De upload is geslaagd', {
				color: 'success',
			});
			this.getEmailBlacklistLength();
		}).catch(err => {
			this.props.setMessage(CSV_MESSAGE, 'Kon bestand niet opslaan', err.response.data.message, {
				color: 'danger',
			});
		});
	}

	submitDomainBlacklist = (file, domainColumn) => {
		this.props.clearMessage(LIST_MESSAGE);
		this.props.clearMessage(CSV_MESSAGE);

		return submitDomainToBlacklist(file, domainColumn).then(data => data.task).then(taskId => {
			this.props.setMessage(LIST_MESSAGE, 'Upload begonnen', 'De upload is begonnen', {
				color: 'info',
			});

			return getData(() => pollTask(taskId));
		}).then(() => {
			this.props.setMessage(LIST_MESSAGE, 'Upload geslaagd', 'De upload is geslaagd', {
				color: 'success',
			});
			this.getDomainBlacklist();
		}).catch(err => {
			this.props.setMessage(CSV_MESSAGE, 'Kon bestand niet opslaan', err.response.data.message, {
				color: 'danger',
			});
		});
	}

	submitPartnerBlacklistCSV = (file, email, partner, dataset) => {
		this.props.clearMessage(LIST_MESSAGE);
		this.props.clearMessage(CSV_MESSAGE);
		return submitPartnerBlacklist(file, email, partner, dataset).then(data => data.task).then(taskId => {
			this.props.setMessage(LIST_MESSAGE, 'Upload begonnen', 'De upload is begonnen', {
				color: 'info',
			});

			return getData(() => pollTask(taskId));
		}).then(() => {
			this.props.setMessage(LIST_MESSAGE, 'Upload geslaagd', 'De upload is geslaagd', {
				color: 'success',
			});
		}).catch(err => {
			this.props.setMessage(CSV_MESSAGE, 'Kon bestand niet opslaan', err.response.data.message, {
				color: 'danger',
			});
		});
	}

	render = () => <Switch>
		<Route path="/blacklist/upload" render={() => <BlacklistUpload
			submitEmailToBlacklist={this.submitEmailBlacklist}
			submitDomainToBlacklist={this.submitDomainBlacklist}
			submitPartnerBlacklistCSV={this.submitPartnerBlacklistCSV}
		/>} />
		<Route path="/blacklist/domain" render={() => <DomainBlacklist
			domains={this.state.domains}
			deleteDomain={deleteDomainFromBlacklist}
			renderWithLoader={this.props.renderWithLoader}
			messages={this.props.messages}
			prompt={this.props.prompt}
			setMessage={this.props.setMessage}
			getDomainBlacklist={this.getDomainBlacklist}
			lastMessage={this.props.messages[LIST_MESSAGE]}
			clearMessage={() => this.props.clearMessage(LIST_MESSAGE) }
		/>} />
		<Route render={() => <EmailBlacklist emailAddresses={this.state.emails}/>} />
	</Switch>;

}

export default withLoader(withPrompts(withMessages(BlacklistContainer)));
