import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {updateFilter} from '../redux/actions/filters';
import Filter from '../components/crud-partner/editor/Filter';

class FilterContainer extends React.Component {
	static propTypes = {
		name: PropTypes.string.isRequired,
	};

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.getFilter = this.getFilter.bind(this);
	}

	onChange(e) {
		this.props.dispatch(updateFilter(this.props.name, e.target.value));
	}

	getFilter() {
		const filter = this.props.filter.filters.find(f => f.name === this.props.name);

		if (filter) {
			return filter.value;
		}

		return '';
	}

	render() {
		return <Filter value={this.getFilter()} onChange={this.onChange}/>;
	}
}

const mapStateToProps = store => {
	const {filter} = store;

	return {
		filter,
	};
};

export default connect(mapStateToProps)(FilterContainer);
