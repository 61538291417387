import React from 'react';
import {Redirect} from 'react-router-dom';
import {Container} from 'reactstrap';
import PartnerTestPersonForm from './PartnerTestPersonForm';
import Alert from '../alert/Alert';
import Message from '../../messages/Message';

export default class NewPartnerTestPerson extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			lastMessage: null,
			doRedirect: false,
		};

		this.onSubmit = this.onSubmit.bind(this);
	}

	onSubmit(testPerson) {
		this.setState({
			lastMessage: null,
		});

		this.props.onSubmit(this.props.partnerId, this.props.datasetId, testPerson).then(() => {
			this.setState({
				doRedirect: true,
			});
		}).catch(err => {
			this.setState({
				lastMessage: new Message('Could not save dataset test person', err.message, {
					color: 'danger',
				}),
			});
		});
	}

	render() {
		if (this.state.doRedirect) {
			return <Redirect to={`/partners/${this.props.partnerId}/datasets/${this.props.datasetId}/testpersons`} />;
		}

		const alert = this.state.lastMessage && <Alert message={this.state.lastMessage} />;

		return <div id="newPartnerTestPerson">
			<Container>
				<h1>New dataset test person</h1>

				{alert}

				<PartnerTestPersonForm onSubmit={this.onSubmit} />
			</Container>
		</div>;
	}
}
