import React from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import {Container} from 'reactstrap';

import PartnerForm from './PartnerForm';
import Alert from '../alert/Alert';
import Message from '../../messages/Message';

import {validatePartner} from '../../validation/partner';

export default class NewPartner extends React.Component {
	static propTypes = {
		onSubmit: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			lastMessage: null,
			doRedirect: false,
		};

		this.onSubmit = this.onSubmit.bind(this);
	}

	onSubmit(partner) {
		this.setState({
			lastMessage: null,
		});

		if(partner.vatPercentage >= 1) {
			const hundredPercent = 100;
			partner.vatPercentage = partner.vatPercentage / hundredPercent;
		}

		validatePartner.validate(partner, {
			abortEarly: false,
		}).then(() => {
			this.props.onSubmit(partner).then(() => {
				this.setState({
					doRedirect: true,
				});
			}).catch(err => {
				this.setState({
					lastMessage: new Message('Could not save partner', err.message, {
						color: 'danger',
					}),
				});
			});
		}).catch(err => {
			let message;

			if (err.errors.length === 1) {
				message = <p>{err.errors[0]}</p>;
			} else {
				message = <ul>{err.errors.map((e, i) => <li key={i}>{e}</li>)}</ul>;
			}

			this.setState({
				lastMessage: new Message('Foute invoer', message, {
					color: 'danger',
				}),
			});
		});
	}

	render() {
		if (this.state.doRedirect) {
			return <Redirect to="/partners" />;
		}

		const alert = this.state.lastMessage && <Alert message={this.state.lastMessage} />;

		return <div id="newPartner">
			<Container>
				<h1>New partner</h1>

				{alert}

				<PartnerForm
					onSubmit={this.onSubmit}
				/>
			</Container>
		</div>;
	}
}
